import React, { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { put, get } from 'src/utils/apiManager';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormLabel,
  CImage,
  CRow,
} from '@coreui/react'
import { DefaultInput, DefaultMobileInput } from 'src/components/common/input'
import { validateEditDriver } from 'src/utils/validator'
import Select from 'react-select'
import { ImageUrl } from 'src/config/config';
import { ErrorText } from 'src/components/common/commonError';
import Info from '../info/info';

function editDriver() {
  const navigate = useNavigate()
  const { state } = useLocation()
  // console.log(state)
  // console.log("van no ", state.vanNo)
  // console.log("areaName  ",state.areaName)
  const formRef = useRef(null)
  const fileRef = useRef(null)
  const [invalidImage, setInvalidImage] = useState('')
  const [imageUrl, setimageUrl] = useState()
  const [selectedVanOption, setSelectedVanOption] = useState();
  const [selectedCityOption, setSelectedCityOption] = useState();
  // const [selectedAreaOption, setSelectedAreaOption] = useState(state?.areaName?.map((i) => {
  //   return { label: i.areaName_EN, value: i._id }
  // }));
  const [selectedAreaOption, setSelectedAreaOption] = useState(null);
  const [vanData, setvanData] = useState(null);
  const [cityData, setCityData] = useState(null);
  const [areaData, setAreaData] = useState(null);
  const [isDisable, setIsDisable] = useState(false)
  const [driverImagePreview, setDriverImagePreview] = useState();

  const [passVisibility, setPassVisibility] = useState(false)
  const [newPassVisibility, setNewPassVisibility] = useState(false)
  const [confrmNewPassVisibility, setConfrmNewPassVisibility] = useState(false)
  const [passwordType, setPasswordType] = useState({
    currentPassType: 'password',
    newPassType: 'password',
    confrmNewPassType: 'password'
  })

  const handlePasswordVisibility = (e, type) => {
    e.preventDefault();
    if (type == "currentPassword") {
      setPassVisibility(!passVisibility)
    } else if (type == "NewPassword") {
      setNewPassVisibility(!newPassVisibility)
    } else {
      setConfrmNewPassVisibility(!confrmNewPassVisibility)
    }
  }
  useEffect(() => {
    if (passVisibility == false) {
      setPasswordType({ ...passwordType, currentPassType: "password" })
    } else {
      setPasswordType({ ...passwordType, currentPassType: "text" })
    }
  }, [passVisibility])

  useEffect(() => {
    if (newPassVisibility == false) {
      setPasswordType({ ...passwordType, newPassType: "password" })
    } else {
      setPasswordType({ ...passwordType, newPassType: "text" })
    }
  }, [newPassVisibility])

  useEffect(() => {
    if (confrmNewPassVisibility == false) {
      setPasswordType({ ...passwordType, confrmNewPassType: "password" })
    } else {
      setPasswordType({ ...passwordType, confrmNewPassType: "text" })
    }
  }, [confrmNewPassVisibility])

  const getVanData = () => {
    get(`admin/Driver/van-no`).then((data) => {
      if (data?.data !== null) {
        const tempData = data?.data?.map((item, key) => {
          return { label: item?.vanNo, value: item?._id }
        })
        setSelectedVanOption({ label: state?.vanNo?.vanNo, value: state?.vanNo?._id })
        setvanData(tempData)
      }
    })
  }
  const getCityData = () => {
    get(`admin/Driver/city`).then((data) => {
      if (data.data !== null) {
        const tempData = data.data.map((item, key) => {
          console.log(data.data, state)
          if (item._id === state.city_id._id) {
            setSelectedCityOption({ label: item.cityName_EN, value: item._id })
          }
          return { label: item?.cityName_EN, value: item?._id }
        })
        setCityData(tempData)
      }
    })
  }
  // useEffect(() => {
  //   if (selectedCityOption) {
  //     getAreaData();
  //   }
  // }, [selectedCityOption])
  useEffect(() => {
    getAreaData();
  }, [])

  const getAreaData = () => {
    // get(`admin/Driver/area-name?city_id=${selectedCityOption.value}`).then((data) => {
    get(`admin/Driver/area-name`).then((data) => {
      // console.log(data, "data")
      // if (data.data !== null) {
      //   const AreaData = data.data.map((item, key) => {
      //     if (item._id === state.areaName._id) {
      //       setSelectedAreaOption({ label: item.areaName_EN, value: item._id })
      //     }
      //     return { label: item.areaName_EN, value: item._id }
      //   })
      //   setAreaData(AreaData)
      // }
      if (data?.data !== null) {
        const tempData = data?.data?.map((item, key) => {
          return { label: item?.areaName_EN, value: item?._id }
        })
        setSelectedAreaOption({ label: state?.areaName?.areaName_EN, value: state?.areaName?._id })
        setAreaData(tempData)
      }
    })
  }
  const submit = async (values) => {
    //this is use for multiple area selction
    // let existArea = selectedAreaOption.map((item, key) => {
    //   return item.value
    // })
    // // console.log("existssssArea", existArea)
    // const AREA = []
    // if (values.areaName != '') {
    //   values.areaName.forEach((item, key) => {
    //     AREA.push(item.value)
    //   })
    // }

    let formData = new FormData()
    formData.append('id', state?._id)
    formData.append('email', values?.email)
    formData.append('mobile', values?.mobile)
    formData.append('image', values?.image)
    formData.append('username', values?.username)
    formData.append('vanNo', selectedVanOption.value)
    formData.append('newPassword', values.newPassword)
    // formData.append('city_id', selectedCityOption.value)
    // formData.append('areaName', values?.areaName == '' ? existArea : AREA)
    formData.append('areaName', selectedAreaOption.value)
    // console.log(driverData)
    // console.log(formData.get('areaName'))

    put(`admin/Driver/update-driver?_id=${state?._id}`, formData, 1).then((data) => {
      // console.log(data)
      handleResponse(data)
    })
  }
  const handleResponse = (data) => {
    // console.log("data data",data)
    if (data?.status == true) {
      toast.success(data?.message)
      setTimeout(() => {
        navigate('/admin/driver')
      }, 800)
    }
    // formRef.current.resetForm()
    setSelectedVanOption('')
    setSelectedAreaOption('')
  }

  // console.log("state bvalue for city", state.city_id._id)
  useEffect(() => {
    getVanData();
    getCityData();
    if (state != null) {

      formRef.current.setFieldValue('username', state?.username)
      formRef.current.setFieldValue('email', state?.email)
      formRef.current.setFieldValue('mobile', state?.mobile)
      // formRef.current.setFieldValue('city_id', state?.city_id?._id)
      formRef.current.setFieldValue('vanNo', state?.vanNo?._id)
      formRef.current.setFieldValue('areaName', state?.areaName?.areaName_EN)
      // formRef.current.setFieldValue('mobile', state?.mobile)
      if (state?.image) { setimageUrl(`${state?.ImageUrl}/${state?.image}`) }
    }
  }, [state])
  return (
    <>
      <CContainer >
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>Edit Driver</h1>
                        </CCol>
                        <CCol md="1">
                          <Link className={'btn btn-success mt-2'} style={{ background: '#d3dc40', border: '#d3dc40' }} to={{ pathname: `/admin/driver` }}>
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          username: '',
                          email: '',
                          mobile: '',
                          image: '',
                          vanNo: '',
                          // city_id: '',
                          areaName: '',
                          newPassword: "",
                          confirmNewPassword: "",
                        }}
                        validationSchema={validateEditDriver}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)
                          //  console.log(values)

                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form form onSubmit={props?.handleSubmit}>
                            <CRow xs={12}>
                              <CCol md={6}>
                                <CFormLabel>Name</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Name'}
                                  // name={'Name'}
                                  id={'username'}
                                  maxLength="70"
                                  value={props?.values?.username}
                                  onKeyUp={() => {
                                    if (state?.username == props?.values?.username) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                  onChange={props?.handleChange('username')}
                                  onBlur={() => props?.setFieldTouched('username', true)}
                                  error={props?.touched?.username && props?.errors?.username}
                                />
                                <CFormLabel>New Password</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <div className="input-group">
                                  <input
                                    type={passwordType?.newPassType}
                                    autoComplete="off"
                                    className="form-control"
                                    placeholder={"New Password"}
                                    id={"NewPassword"}
                                    value={props?.values?.newPassword}
                                    onKeyUp={(e) => {
                                      setIsDisable(e.target.value ? true : false)
                                    }}
                                    onChange={props?.handleChange("newPassword")}
                                    onBlur={() => props?.setFieldTouched("newPassword", true)}
                                  />
                                  <button
                                    className="btn btn-outline-secondary"
                                    type="button"
                                    onClick={(e) => handlePasswordVisibility(e, 'NewPassword')}
                                    style={{ backgroundColor: '#d8dbe0' }}
                                  >
                                    {passwordType?.newPassType == "password" ? (
                                      <i style={{ color: '#2C384A' }} className="fa-solid fa-eye-slash"></i>
                                    ) : <i style={{ color: '#2C384A' }} className="fa-solid fa-eye"></i>}
                                  </button>
                                </div>
                                {props?.touched?.newPassword && props?.errors?.newPassword ? <ErrorText title={props?.errors?.newPassword} /> : null}
                                <DefaultMobileInput
                                  type={'text'}
                                  placeholder={'Mobile'}
                                  name={'Mobile'}
                                  id={'mobile'}
                                  minLength={7}
                                  maxLength={15}
                                  value={props?.values?.mobile}
                                  onChange={(val) => props?.setFieldValue('mobile', val || '')}
                                  onKeyUp={(value) => {
                                    if (state?.mobile == props?.values?.mobile) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                  onBlur={() => props?.setFieldTouched('mobile', true)}
                                  error={props?.touched?.mobile && props?.errors?.mobile}
                                />

                                <CFormLabel>Image</CFormLabel>
                                {/* <span className='text-danger' style={{ marginLeft: '5px' }}>*</span> */}
                                <span> </span>
                                <Info message="100x120"/>
                                <DefaultInput
                                  type={'file'}
                                  placeholder={'Image'}
                                  // name={'Image'}
                                  // accept="image/*"
                                  accept="image/png, image/gif, image/jpeg, image/jpg"
                                  refr={fileRef}
                                  onChange={(e) => {
                                    const imageFile = e?.target?.files[0];
                                    if (imageUrl == imageFile.name) setIsDisable(false)
                                    else setIsDisable(true)
                                    if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                      setInvalidImage("Please select valid image")
                                    }
                                    else {
                                      setInvalidImage('')
                                    }
                                    //for image preview
                                    const reader = new FileReader();
                                    reader?.addEventListener("load", () => setDriverImagePreview(reader?.result));
                                    reader?.readAsDataURL(e?.target?.files[0]);
                                    //for image preview
                                    props?.setFieldValue('image', e?.target?.files[0])
                                  }}
                                  onBlur={() => props?.setFieldTouched('image', true)}
                                  error={(props?.touched?.image && props?.errors?.image) || invalidImage}
                                />
                                <div className="mt-2">
                                  {/* for image preview */}
                                  {driverImagePreview?.length > 0 ?
                                    <>
                                      <CCol>
                                        <CImage
                                          rounded
                                          thumbnail
                                          // className="mt-2"
                                          src={`${driverImagePreview}`}
                                          style={{
                                            maxHeight: '80px',
                                            maxWidth: '80px',
                                            alignItems: 'center',
                                          }}
                                        />
                                      </CCol>
                                    </>
                                    : ''}
                                  {/* for image preview */}
                                  {imageUrl && (
                                    <CImage
                                      rounded
                                      thumbnail
                                      src={ImageUrl + state?.image}
                                      style={{
                                        maxHeight: '80px',
                                        maxWidth: '80px',
                                        alignItems: 'center',
                                      }}
                                    />
                                  )}
                                </div>
                              </CCol>
                              <CCol md={6}>
                                <CFormLabel>Email</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  disabled={true}
                                  type={'email'}
                                  placeholder={'Email'}
                                  // name={'Email'}
                                  id={'email'}
                                  value={props?.values?.email}
                                  onChange={props?.handleChange('email')}
                                  onBlur={() => props?.setFieldTouched('email', true)}
                                  error={props?.touched?.email && props?.errors?.email}
                                />
                                {/* <CFormLabel>City </CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <Select
                                  // className='mt-2'
                                  defaultValue={selectedCityOption}
                                  onChange={(val) => {
                                    setSelectedCityOption(val)
                                    if (selectedCityOption.value != val.value) {
                                      // console.log("area name change kr do ")
                                      setSelectedAreaOption([])
                                    }
                                    props?.setFieldValue("city_id", val.value)
                                  }}
                                  options={cityData}
                                  placeholder={"Select cites"}
                                  value={selectedCityOption}
                                  name="City" />
                                {selectedCityOption == undefined ? <span className='text-danger mt-2'>{props?.errors?.city_id}</span> : ''}<br /> */}
                                <CFormLabel>Confirm New Password</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <div className="input-group">
                                  <input
                                    type={passwordType?.confrmNewPassType}
                                    autoComplete="off"
                                    className="form-control"
                                    placeholder={"Confirm New Password"}
                                    id={"confirmNewPassword"}
                                    value={props?.values?.confirmNewPassword}
                                    onChange={props?.handleChange("confirmNewPassword")}
                                    onBlur={() =>
                                      props?.setFieldTouched("confirmNewPassword", true)
                                    }
                                  />
                                  <button
                                    className="btn btn-outline-secondary"
                                    type="button"
                                    onClick={(e) => handlePasswordVisibility(e, 'confirmNewPassword')}
                                    style={{ backgroundColor: '#d8dbe0' }}
                                  // onClick={() => setConfrmNewPassVisibility(!confrmNewPassVisibility)}
                                  >
                                    {passwordType?.confrmNewPassType == "password" ? (
                                      <i style={{ color: '#2C384A' }} className="fa-solid fa-eye-slash"></i>
                                    ) : <i style={{ color: '#2C384A' }} className="fa-solid fa-eye"></i>}
                                  </button>
                                </div>
                                {props?.touched?.confirmNewPassword && props?.errors?.confirmNewPassword ? <ErrorText title={props?.errors?.confirmNewPassword} /> : null}
                                <CFormLabel>Area Name</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <Select
                                  // isMulti
                                  // className='mt-2'
                                  defaultValue={selectedAreaOption}
                                  onChange={(val) => {
                                    setSelectedAreaOption(val)
                                    if (val.value == selectedAreaOption.value) setIsDisable(false)
                                    else setIsDisable(true)
                                    props?.setFieldValue("areaName", val.value)
                                    // props?.setFieldValue("areaName", val)
                                  }}
                                  options={areaData}
                                  placeholder={"Select Area Name"}
                                  value={selectedAreaOption}
                                  name="Area Name" />
                                {selectedAreaOption == undefined ? <span className='text-danger'>{props?.errors?.areaName}</span> : ''}

                                <CFormLabel>Van No</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <Select
                                  className='mt-2'
                                  defaultValue={selectedVanOption}
                                  onChange={(val) => {
                                    setSelectedVanOption(val)
                                    if (val?.value == selectedVanOption?.value) setIsDisable(false)
                                    else setIsDisable(true)
                                    props?.setFieldValue("vanNo", val?.value)
                                  }}
                                  options={vanData}
                                  placeholder={"Select van no"}
                                  value={selectedVanOption}
                                  name="Van Number" />
                                {selectedVanOption == undefined ? <span className='text-danger'>{props?.errors?.vanNo}</span> : ''}<br />
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"
                                  className={'btn btn-success mt-2'}
                                  disabled={!isDisable || props?.isSubmitting}
                                // disabled={props.isSubmitting}
                                >
                                  Submit
                                </button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={() => navigate('/admin/driver')}
                                >
                                  Cancel
                                </button>
                              </CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default editDriver