import React, { useRef, useState, useEffect } from 'react'
import { Formik } from 'formik'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import { get, put } from 'src/utils/apiManager'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Select from 'react-select'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormLabel,
  CImage,
  CRow,
  CFormSwitch
} from '@coreui/react'
import { DefaultInput } from 'src/components/common/input'
import { validateEditAddOnService } from 'src/utils/validator'
import { Ckeditor } from '../Template/Ckeditor'
import { ImageUrl } from 'src/config/config'
import moment from 'moment';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Info from '../info/info'

function edit() {
  const navigate = useNavigate()
  const { state } = useLocation()
  const formRef = useRef(null)
  const fileRef = useRef(null)
  const [isDisable, setIsDisable] = useState(false)
  const [invalidImage, setInvalidImage] = useState('')
  const [imageUrl, setimageUrl] = useState()
  const [description_EN, setDescription_EN] = useState([])
  const [description_TH, setDescription_TH] = useState([])
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [toMinimum, setToMinimum] = useState(null);
  const [fromMax, setFromMax] = useState(null);
  const [defaultToDate, setDefaultToDate] = useState();
  const [defaultFromDate, setDefaultFromDate] = useState();
  const [selectedOffer, setSelectedOffer] = useState([])
  const [offerList, setOfferList] = useState(null)
  const [serviceImagePreview, setServiceImagePreview] = useState();
  const [offerApplyToggle, setOfferApplyToggle] = useState(false);
  // const offerOptions = []

  const getOffers = () => {
    get(`admin/add-on-service/offer-list`).then((data) => {

      if (data?.data !== null) {
        const tempData = data?.data?.map((item, key) => {
          if (state?.offer[0]?._id == item?._id) {
            selectedOffer?.push({ label: item?.title_EN, value: item?._id })
          }
          return { label: item?.title_EN, value: item?._id }
        })
        setOfferList(tempData)
      }

    })
  }

  useEffect(() => {
    getOffers();
  }, []);



  const submit = async (values) => {
    if (values?.addOnServiceQuantity < 0 || values?.price <= 0) {
      // toast.error("Enter correct values")
      // values?.price <= 0 ? formRef.current.setFieldValue('price', '') : formRef.current.setFieldValue('addOnServiceQuantity', '')
      if (values?.price <= 0) {
        formRef.current.setFieldValue('price', '')
      } else {
        formRef.current.setFieldValue('addOnServiceQuantity', '')
      }
    } else {
      if (invalidImage?.length === 0) {
        if (values?.offer == undefined) {
          var existOffer = selectedOffer?.map((item, key) => {
            return item?.value
          })
        }
        let temp = {
          serviceName_EN: values?.serviceName_EN,
          serviceName_TH: values?.serviceName_TH,
          image: values?.image,
          description_EN: values?.description_EN,
          description_TH: values?.description_TH,
          price: values?.price,
          to_date: toDate ? toDate : state?.to_date,
          from_date: fromDate ? fromDate : state?.from_date,
          offer: values?.offer == undefined ? existOffer : values?.offer,
          offerStatus: offerApplyToggle == true ? "1" : "0",
          addOnServiceQuantity: values?.addOnServiceQuantity ? values?.addOnServiceQuantity : state?.addOnServiceQuantity
        }
        put(`admin/add-on-service/update?_id=${state?._id}`, temp, 1).then((data) => {
          handleResponse(data)
        })
      } else {
        return
      }
    }
  }
  const handleResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setTimeout(() => {
        navigate('/admin/add-on-service')
      }, 800)
    }
    formRef.current.resetForm()
    fileRef.current.value = null
  }

  const handleDateChange = (event, type) => {
    if (type == 'fromDate') {
      setFromDate(event?.$d)
      const minTodate = toMinDate(event?.$d)
      setToMinimum(minTodate)
    } else {
      setToDate(event?.$d)
      const maxfrom = fromMaxDate(event?.$d)
      setFromMax(maxfrom)
    }
  }

  const fromMaxDate = (date) => {
    const prevDate = moment(date).add(-1, 'days');
    const d = prevDate?._d
    return d
  }

  const toMinDate = (date) => {
    const tomorrow = moment(date).add(+1, 'days');
    const d = tomorrow?._d
    return d
  }

  const handleApplyOffer = () => {
    if (!offerApplyToggle) {
      setOfferApplyToggle(true)
    }
  }

  const ChangeOfferStatus = () => {
    setOfferApplyToggle(!offerApplyToggle)
    setIsDisable(true)
  }

  useEffect(() => {
    if (state != null) {
      setDefaultToDate(state?.to_date)
      setDefaultFromDate(state?.from_date)
      formRef.current.setFieldValue('serviceName_EN', state?.serviceName_EN)
      formRef.current.setFieldValue('serviceName_TH', state?.serviceName_TH)
      formRef.current.setFieldValue('description_EN', state?.description_EN)
      formRef.current.setFieldValue('description_TH', state?.description_TH)
      formRef.current.setFieldValue('price', state?.price)
      formRef.current.setFieldValue('addOnServiceQuantity', state?.addOnServiceQuantity)
      if (state?.image) setimageUrl(`${ImageUrl}${state?.image}`)
      if (state?.offerStatus == "1") setOfferApplyToggle(true)
    }
  }, [state])
  return (
    <>
      <CContainer>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>Edit Add On Service</h1>
                        </CCol>
                        <CCol md="1">
                          <Link
                            className={'btn btn-success mt-2'}
                            style={{ background: '#d3dc40', border: '#d3dc40' }}
                            to={{ pathname: `/admin/add-on-service` }}
                          >
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          serviceName_EN: '',
                          serviceName_TH: '',
                          description_EN: '',
                          description_TH: '',
                          image: '',
                          price: '',
                          fromdate: '',
                          todate: '',
                          addOnServiceQuantity: ''
                        }}
                        validationSchema={validateEditAddOnService}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)
                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props?.handleSubmit}>
                            <CRow>
                              <CCol md={6}>
                                <CFormLabel>Service Name (EN)</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Service Name (EN)'}
                                  value={props?.values?.serviceName_EN}
                                  onChange={props?.handleChange('serviceName_EN')}
                                  onBlur={() => props?.setFieldTouched('serviceName_EN', true)}
                                  error={
                                    props?.touched?.serviceName_EN && props?.errors?.serviceName_EN
                                  }
                                  onKeyUp={() => {
                                    if (state.serviceName_EN == props?.values?.serviceName_EN)
                                      setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                                <CFormLabel>Description (EN)</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <Ckeditor
                                  setDescription_EN={setDescription_EN}
                                  description_EN={description_EN}
                                  value={props?.values?.description_EN}
                                  onChange={(val) => {
                                    setDescription_EN(val)
                                    if (description_EN.length <= 0) setIsDisable(false)
                                    else setIsDisable(true)
                                    props?.setFieldValue('description_EN', val)
                                  }}
                                  onBlur={() => props?.setFieldTouched('description_EN', true)}
                                  error={
                                    props?.touched?.description_EN && props?.errors?.description_EN
                                  }
                                />


                                <CFormLabel>Offers</CFormLabel>
                                {/* <span className="text-danger" style={{ marginLeft: '5px' }}>*</span> */}
                                <Select
                                  defaultValue={selectedOffer}
                                  onChange={(val) => {
                                    setSelectedOffer(val)
                                    handleApplyOffer()
                                    if (val?.value == selectedOffer?.value) setIsDisable(false)
                                    else setIsDisable(true)
                                    props?.setFieldValue('offer', val?.value)
                                  }}
                                  options={offerList}
                                  placeholder={'Select offer'}
                                  value={selectedOffer}
                                />
                                {/* {selectedOffer == undefined ? (
                                  <span className="text-danger">{props?.errors?.offer}</span>
                                ) : (
                                  ''
                                )} */}

                                <div style={{ marginTop: '10px' }}>
                                  <CFormLabel>Offer Status</CFormLabel>
                                  <CFormSwitch label="Apply Offer" id="formSwitchCheckDefault" onChange={() => ChangeOfferStatus()} checked={offerApplyToggle} />
                                </div>

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <div><CFormLabel>From Date</CFormLabel>
                                    <span className='text-danger' style={{ marginLeft: '5px' }}>*</span></div>
                                  <DesktopDatePicker
                                    inputFormat="DD/MM/YYYY"
                                    name="fromdate"
                                    style={{ height: '68px', height: '595px' }}
                                    value={fromDate == null ? defaultFromDate : fromDate}
                                    minDate={moment()}
                                    maxDate={fromMax}
                                    onChange={(e) => {
                                      if (e?.$d) setIsDisable(true)
                                      else setIsDisable(false)
                                      handleDateChange(e, 'fromDate')
                                      props?.setFieldValue('fromdate', e?.$d)
                                    }}
                                    renderInput={(params) => <TextField style={{ marginTop: '5px' }} name="fromdate" {...params} />}
                                  />
                                </LocalizationProvider>

                                {fromDate == null && props?.touched?.fromdate == true ? <p className='text-danger mt-2'>{props?.errors?.fromdate}</p> : ''}

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <div><CFormLabel>To Date</CFormLabel>
                                    <span className='text-danger' style={{ marginLeft: '5px' }}>*</span></div>
                                  <DesktopDatePicker

                                    inputFormat="DD/MM/YYYY"
                                    name="todate"
                                    style={{ height: '68px', height: '595px' }}
                                    value={toDate == null ? defaultToDate : toDate}
                                    minDate={toMinimum}
                                    onChange={(e) => {
                                      if (e?.$d) setIsDisable(true)
                                      else setIsDisable(false)

                                      handleDateChange(e, 'toDate')
                                      props?.setFieldValue('todate', e?.$d)
                                    }}
                                    renderInput={(params) => <TextField defaultValue={new Date()} style={{ marginTop: '5px' }} name="todate" {...params} />}
                                  />
                                </LocalizationProvider>

                                {toDate == null && props?.touched?.todate == true ? <p className='text-danger mt-2'>{props?.errors?.todate}</p> : ''}


                              </CCol>
                              <CCol md="6">
                                <CFormLabel>Service Name (TH)</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Service Name (TH)'}
                                  value={props?.values?.serviceName_TH}
                                  onChange={props?.handleChange('serviceName_TH')}
                                  onBlur={() => props?.setFieldTouched('serviceName_TH', true)}
                                  error={
                                    props?.touched?.serviceName_TH && props?.errors?.serviceName_TH
                                  }
                                  onKeyUp={() => {
                                    if (state.serviceName_TH == props?.values?.serviceName_TH)
                                      setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                                <CFormLabel>Description (TH)</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <Ckeditor
                                  setDescription_TH={setDescription_TH}
                                  description_TH={description_TH}
                                  value={props?.values?.description_TH}
                                  onChange={(val) => {
                                    setDescription_TH(val)
                                    if (description_TH?.length <= 0) setIsDisable(false)
                                    else setIsDisable(true)
                                    props?.setFieldValue('description_TH', val)
                                  }}
                                  onBlur={() => props?.setFieldTouched('description_TH', true)}
                                  error={
                                    props?.touched?.description_TH && props?.errors?.description_TH
                                  }
                                />


                                <CFormLabel>Price</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={"text"}
                                  placeholder={"Price"}
                                  value={props?.values?.price}
                                  onChange={props?.handleChange("price")}
                                  onBlur={() => props?.setFieldTouched("price", true)}
                                  error={props?.touched?.price && props?.errors?.price}
                                  onKeyUp={() => {
                                    if (state.price == props?.values?.price)
                                      setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                                <CFormLabel>Add on Service Quantity</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={"text"}
                                  placeholder={"Add on Service Quantity"}
                                  value={props?.values?.addOnServiceQuantity}
                                  onChange={props?.handleChange("addOnServiceQuantity")}
                                  onBlur={() => props?.setFieldTouched("addOnServiceQuantity", true)}
                                  error={props?.touched?.addOnServiceQuantity && props?.errors?.addOnServiceQuantity}
                                  onKeyUp={() => {
                                    if (state?.addOnServiceQuantity == props?.values?.addOnServiceQuantity)
                                      setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                                <CFormLabel>Image</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>*</span>
                            <Info message="100x120"/>

                                <DefaultInput
                                  type={'file'}
                                  placeholder={'Image'}
                                  // name={'Image'}
                                  accept="image/*"
                                  refr={fileRef}
                                  onChange={(e) => {
                                    const imageFile = e?.target?.files[0]
                                    if (imageUrl == imageFile?.name) setIsDisable(false)
                                    else setIsDisable(true)
                                    if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                      setInvalidImage('Please select valid image')
                                    } else {
                                      setInvalidImage('')
                                    }
                                    //for image preview
                                    const reader = new FileReader();
                                    reader?.addEventListener("load", () => setServiceImagePreview(reader?.result));
                                    reader?.readAsDataURL(e?.target?.files[0]);
                                    //for image preview
                                    props?.setFieldValue('image', e?.target?.files[0])
                                  }}
                                  onBlur={() => props?.setFieldTouched('image', true)}
                                  error={
                                    (props?.touched?.image && props?.errors?.image) || invalidImage
                                  }
                                />
                                {/* for image preview */}
                                {serviceImagePreview?.length > 0 ?
                                  <>
                                    <CCol>
                                      <CImage
                                        rounded
                                        thumbnail
                                        className="mt-2"
                                        src={`${serviceImagePreview}`}
                                        style={{
                                          maxHeight: '80px',
                                          maxWidth: '80px',
                                          alignItems: 'center',
                                        }}
                                      />
                                    </CCol>
                                  </>
                                  : ''}
                                {/* for image preview */}
                                {imageUrl && (
                                  <CImage
                                    rounded
                                    thumbnail
                                    className="mt-2"
                                    src={`${imageUrl}`}
                                    style={{
                                      maxHeight: '80px',
                                      maxWidth: '80px',
                                      alignItems: 'center',
                                    }}
                                  />
                                )}
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"
                                  className={'btn btn-success mt-2'}
                                  disabled={!isDisable || props?.isSubmitting}
                                >
                                  Submit
                                </button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={() => navigate('/admin/add-on-service')}
                                >
                                  Cancel
                                </button>
                              </CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default edit
