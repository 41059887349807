import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormLabel,
  CRow,
  CFormCheck,
  CFormTextarea,
} from '@coreui/react'
import React, { useEffect, useRef, useState } from 'react'
import { FaTrash } from 'react-icons/fa'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { DefaultInput } from 'src/components/common/input'
import { get, post } from 'src/utils/apiManager'
import CustomKeyboard from './KeyBoard'
import Webcam from 'react-webcam'
import { ImageUrl, ImageUrl2 } from 'src/config/config'
import { useDispatch, useSelector } from 'react-redux'
import { baseURL } from 'src/config/config'
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CFormInput,
  CButton
} from '@coreui/react'
import {
  clearGarmentData,
  garmentState,
  setBrand,
  setColors,
  setDescription,
  setItem,
  setItemStatus,
  setPicture,
  setStain,
  setStainImage,
  setWashType,
  setRejectedItemSummary
} from 'src/reduxToolkit/slice/garmentShorting'
import { toast } from 'react-toastify'
import Select from 'react-select';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { uploadAdapterPlugin } from 'src/utils/imageUpload'
import Swal from 'sweetalert2'


const WebcamComponent = () => <Webcam />
const videoConstraints = {
  width: 400,
  height: 400,
  facingMode: 'user',
}
// import Keyboard from 'react-simple-keyboard'
// import 'react-simple-keyboard/build/css/index.css'
const AddItem = () => {
  const dispatch = useDispatch()
  const [currentTab, setCurrentTab] = useState('itemType')
  const { item, itemStatus, brand, colors, washType, description, picture, stain, service } =
    useSelector(garmentState)
  const [selectedReason, setSelectedReason] = useState('')
  const [selectedOption, setSelectedOption] = useState(null)
  const fileRef = useRef(null)
  const [itemData, setItemData] = useState([])
  const itemsData = useParams()
  const [brandData, setBrandData] = useState([])
  const [colorData, setColorData] = useState([])
  const [imageURI, setImageURI] = useState([])
  const [invalidImage, setInvalidImage] = useState('')
  const [editData, setEditData] = useState({})
  const [files, setFiles] = useState([])
  const [filesCat, setFilesCat] = useState([]);
  console.log("🚀 ~ AddItem ~ filesCat:", filesCat)
  const [selectedStain, setSelectedStain] = useState();
  const [modal, setModal] = useState(false)
  const [picUrl, setPicUrl] = useState('')
  const webcamRef = React.useRef(null)
  const webcamRefImages = React.useRef(null)
  const capture = React.useCallback(() => {
    const pictureSrc = webcamRef.current.getScreenshot()
    dispatch(setPicture(pictureSrc))
  })
  const [imgUrl, setImgUrl] = useState('')
  const [input, setInput] = useState('')
  const [layoutName, setLayoutName] = useState('default')
  const [editing, setEditing] = useState(false)
  const [orderData, setOrderData] = useState({})
  // const keyboard = useRef(null)
  const navigate = useNavigate()
  const stainCapture = React.useCallback(() => {
    const pictureSrc = webcamRefImages.current.getScreenshot()
    try {
      let formData = new FormData()
      const base64String = pictureSrc?.split('base64,')[1]
      const byteCharacters = atob(base64String)
      const byteNumbers = new Array(byteCharacters.length)
      console.log({ byteNumbers });
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      let convertedImage = new Blob([byteArray], { type: 'image/png' }) // Change 'image/jpeg' to 'image/png'
      console.log({ convertedImage });
      formData.append('image', convertedImage)
      post(`admin/offline-order/image-url`, formData, 1).then((data) => {
        if (data?.status) {
          addOrUpdateStain(selectedStain, undefined, data?.data)
          // setFilesCat(prevState => {
          //   const stainIndex = prevState.findIndex(stain => stain.stainName === selectedStain);
          //   if (stainIndex !== -1) {
          //     // Update existing stain
          //     const updatedStain = {
          //       ...prevState[stainIndex] ,
          //       images:   [...prevState[stainIndex].images, data?.data]
          //     };
          //     const updatedFiles = [...prevState];
          //     updatedFiles[stainIndex] = updatedStain;
          //     return updatedFiles
          //   } else {
          //     // Add new stain if it doesn't exist
          //     return [...prevState, {
          //       stainName,
          //       safeMessage:  '',
          //       images: data?.data
          //     }];
          //   }
          // });

          // setFilesCat(prevState => {
          //   const updatedFiles = prevState.map(stain => {
          //     if (stain.stainName === selectedStain) {
          //       if (stain.images.length >= 5) {
          //         alert('You can only select up to 5 images per stain type.');
          //         return stain;
          //       }
          //       return {
          //         ...stain,
          //         images: [...stain.images, data?.data]
          //       };
          //     }
          //     return stain;
          //   });
          //   return updatedFiles;
          // });
        }
      })
    } catch (error) {
      console.error('Error decoding image base64 string:', error)
    }

    if (files?.length > 4) {
      return false
    } else {
      setFiles((prevState) => [...prevState, pictureSrc])
    }
  })
  const [washingType, setWashingType] = useState([])
  const [stainType, setStainType] = useState([
    { name: 'Coffee' },
    { name: 'Dirt' },
    { name: 'Blood' },
    { name: 'Damaged' },
    { name: 'Other' },
  ])

  const [data, setData] = useState([]);
  const [value, setValue] = useState('');
  const [description_en, setDescription_en] = useState()
  const [rejectedItemSummaryState, setRejectedItemSummaryState] = useState()


  // Function to add or update a stain
  const addOrUpdateStain = (stainName, message, imageSrc) => {
    console.log("🚀 ~ addOrUpdateStain ~ stainName:", stainName);
    setFilesCat(prevState => {
      const stainIndex = prevState.findIndex(stain => stain.stainName === stainName);

      if (stainIndex !== -1) {
        // Update existing stain
        const existingStain = prevState[stainIndex];
        const updatedImages = imageSrc
          ? existingStain.images.length < 5
            ? [...existingStain.images, imageSrc]
            : existingStain.images
          : existingStain.images;

        if (imageSrc && existingStain.images.length >= 5) {
          alert("You can only add up to 5 images per stain.");
        }

        const updatedStain = {
          ...existingStain,
          safeMessage: message !== undefined ? message : existingStain.safeMessage,
          images: updatedImages
        };

        const updatedFiles = [...prevState];
        updatedFiles[stainIndex] = updatedStain;
        return updatedFiles.filter(stain => stain.safeMessage || stain.images.length > 0);
      } else {
        // Add new stain if it doesn't exist
        return [...prevState, {
          stainName,
          safeMessage: message || '',
          images: imageSrc ? [imageSrc] : []
        }];
      }
    });
  };


  useEffect(() => {
    if (value) {
      setDescription_en(value?.description)
    }
  }, [value])


  useEffect(() => {
    const loadData = async () => {
      get(`admin/safe-checking-message?page=${1}&perPage=${100}`,
      ).then((response) => {
        if (!response?.status) toast.success(response?.message, response?.status)
        else {
          setData(response.data);
        }
      })
    };
    loadData();
  }, []);

  const { bagId, itemCode } = useParams()
  const setTextData = (text) => {
    setInput(text)
    dispatch(setDescription(text))
  }
  useEffect(() => {
    get(`/admin/services/get-items/${window.localStorage?.getItem('categoryId')}`).then((data) => {
      setItemData(data?.data)
    })
    get(`/admin/washing-process`).then((data) => {
      setWashingType(data?.data)
    })
    get(`/admin/placed-order/get-option-list`).then((data) => {
      const allBrands = []
      if (data.data && data.data.length > 0) {
        for (const _brand of data.data) {
          allBrands.push(..._brand.option)
        }
        setBrandData(data.data)
      }
    })
    get(`/admin/items/color/list`).then((data) => {
      if (!data?.status) toast.success(data?.message, data?.status)
      setColorData([...data.data])
    })
    get(`/admin/offline-order/get-order-bag/${bagId}`).then((data) => {
      setOrderData(data)
      dispatch(clearGarmentData())
      let itemDataTemp = data?.data[0]?.item?.filter((item) => item.itemCode == itemCode)
      console.log("🚀 ~ get ~ itemDataTemp:", itemDataTemp)
      if (itemDataTemp.length) {
        setEditData(data)
        dispatch(setItem(itemDataTemp[0].itemId))
        dispatch(setBrand(itemDataTemp[0].brand))
        itemDataTemp[0].colorIds.filter((item) => {
          dispatch(setColors(item))
        })
        dispatch(setWashType(itemDataTemp[0].process))
        {
          Array.isArray(itemDataTemp) && typeof itemDataTemp[0]?.stain === "string" && itemDataTemp[0]?.stain?.split(',').map((v) => {
            dispatch(setStain(v))
          })
        }
        dispatch(setDescription(itemDataTemp[0].description))
        setInput(itemDataTemp[0].description)
        dispatch(setStainImage(itemDataTemp[0].image))
        setImgUrl(itemDataTemp[0].image)
        dispatch(setPicture(ImageUrl2 + itemDataTemp[0].picture))
        // setPicture(ImageUrl2 + itemDataTemp[0].picture)

        setPicUrl(itemDataTemp[0].picture)
        if (itemDataTemp && itemDataTemp.image?.length > 0) {
          const img = data.data[0]?.item?.image.map((file) => {
            return ImageUrl2 + file
          })
          setFiles(img)
        }
      }
    })
  }, [])

  const saveToLocalStorage = (data, key) => {
    localStorage.setItem(key, JSON.stringify(data));
  };

  // Function to retrieve filesCat from local storage
  const loadFromApi = () => {
    get(`/admin/placed-order/item-details?itemCode=${itemCode}`).then((data) => {
      if (data.status) {
        setFilesCat(data.data.stain)
        addOrUpdateStain(selectedStain, description_en)
      }
    })
  };

  // Function to delete an image
  const deleteParticularImagee = (stainName, imageIndex) => {
    console.log("🚀 ~ deleteParticularImagee ~ stainName:", stainName, imageIndex)
    setFilesCat(prevState => {
      const stainIndex = prevState.findIndex(stain => stain.stainName === stainName);
      const updatedStain = {
        ...prevState[stainIndex],
        images: prevState[stainIndex].images.filter((_, index) => index !== imageIndex)
      };
      const updatedFiles = [...prevState];
      updatedFiles[stainIndex] = updatedStain;
      return updatedFiles;
    });
  };

  useEffect(() => {
    if (itemCode) {
      loadFromApi()
    }
  }, [itemCode]);

  const submitData = async () => {
    let formData = new FormData()
    let pictureUrl
    // formData.append('stain', JSON.stringify(filesCat))
    // formData.append('itemId', item == undefined ? window.localStorage.getItem("itemId") : item)
    // formData.append('rejectedItemSummary', rejectedItemSummaryState == undefined ? window.localStorage.getItem("rejectedItemSummary") : rejectedItemSummaryState)
    // formData.append('brand', brand)
    // formData.append('process', washType == undefined ? window.localStorage.getItem("washType") : washType)
    // formData.append('stain', stain)
    // formData.append('color', colors)
    // formData.append('itemCode', itemCode)
    // formData.append('imgUrl', JSON.stringify(imgUrl))
    // formData.append('picUrl', picUrl)
    // formData.append('itemStatus', itemStatus)
    // formData.append('description', description)
    // formData.append('reason', selectedReason)
    // Handle 'picture' base64 data
    if (picture) {
      try {
        const base64String = picture?.split('base64,')[1]
        const byteCharacters = atob(base64String)
        const byteNumbers = new Array(byteCharacters.length)
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        let mimeType = 'image/jpeg' // Change this to 'image/png' for PNG files
        if (base64String.startsWith('/9j/')) {
          // '/9j/' is a signature for JPEG images, change to '/9j/' for PNG images
          mimeType = 'image/jpeg' // Change this to 'image/png' for PNG files
        } else if (base64String.startsWith('iVBORw')) {
          // 'iVBORw' is a signature for PNG images
          mimeType = 'image/png'
        }
        let convertedImage = new Blob([byteArray], { type: mimeType })
      
        console.log({ convertedImage })
        formData.append('image', convertedImage)
   
        const data = await post(`admin/offline-order/image-url`, formData, 1)
        if (data?.status) {
          pictureUrl = data?.data
          console.log("🚀 ~ post ~ data?.data:", data?.data, pictureUrl)
        }
      } catch (error) {
        console.error('Error decoding picture base64 string:', error)
      }
    }

    // Handle 'files' base64 data
    // for (const _file of files) {
    //   try {
    //     const base64String = _file?.split('base64,')[1]
    //     const byteCharacters = atob(base64String)
    //     const byteNumbers = new Array(byteCharacters.length)
    //     console.log({ byteNumbers });
    //     for (let i = 0; i < byteCharacters.length; i++) {
    //       byteNumbers[i] = byteCharacters.charCodeAt(i)
    //     }
    //     const byteArray = new Uint8Array(byteNumbers)
    //     let convertedImage = new Blob([byteArray], { type: 'image/png' }) // Change 'image/jpeg' to 'image/png'
    //     console.log({ convertedImage });
    //     formData.append('image', convertedImage)
    //   } catch (error) {
    //     console.error('Error decoding image base64 string:', error)
    //   }
    // }



    console.log("🚀 ~ submitData ~ pictureUrlpictureUrlpictureUrlpictureUrl:", pictureUrl)


    //   {
    //     "Coffee": {
    //         "stainName": "Coffee",
    //         "safeMessage": "<p>dfgdfgdfgbbcvcvb</p>",
    //         "images": [
    //             "1723036149201-807273055-blob.jpeg"
    //         ]
    //     },
    //     "Dirt": {
    //         "stainName": "Dirt",
    //         "safeMessage": "",
    //         "images": []
    //     },
    //     "Blood": {
    //         "stainName": "Blood",
    //         "safeMessage": "",
    //         "images": []
    //     },
    //     "Damaged": {
    //         "stainName": "Damaged",
    //         "safeMessage": "",
    //         "images": []
    //     },
    //     "Other": {
    //         "stainName": "Other",
    //         "safeMessage": "",
    //         "images": []
    //     }
    // }

    saveToLocalStorage(filesCat, "filesCat");

    let bodyNew = {
      'stain': filesCat,
      'itemId': item == undefined ? window.localStorage.getItem("itemId") : item,
      'rejectedItemSummary': rejectedItemSummaryState == undefined ? window.localStorage.getItem("rejectedItemSummary") : rejectedItemSummaryState,
      'brand': brand,
      'process': washType == undefined ? window.localStorage.getItem("washType") : washType,
      'color': colors,
      'itemCode': itemCode,
      'imgUrl': JSON.stringify(imgUrl),
      'picUrl': picUrl,
      'itemStatus': itemStatus,
      'description': description,
      'reason': selectedReason,
      "picture": pictureUrl
    };
    console.log("🚀 ~ submitData ~ bodyNew:", bodyNew);
    console.log({ bagId });
    post(`admin/offline-order/bag/add-item/${bagId}`, bodyNew).then((data) => {
      if (data?.status) {
        dispatch(clearGarmentData())
        navigate(`/admin/offline-order/bag-item/${bagId}/${data?.newItem?._id}?itemcode=${itemCode}`, {
          state: { orderData },
        })
      }
    })
  }

  const uploadSingleFile = (e) => {
    readURI(e)
  }

  const readURI = (e) => {
    if (e?.target?.files) {
      /* Get files in array form */
      const selectedFiles = Array.from(e.target.files)
      selectedFiles?.map((item, index) => {
        if (!item?.name?.match(/\.(jpg|jpeg|png)$/)) {
          setInvalidImage('Please select valid image')
        } else {
          setInvalidImage('')
        }
      })
      setFiles([...files, ...selectedFiles])
      /* Map each file to a promise that resolves to an array of image URI's */
      Promise.all(
        selectedFiles?.map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader?.addEventListener('load', (ev) => {
              resolve(ev?.target?.result)
            })
            reader?.addEventListener('error', reject)
            reader?.readAsDataURL(file)
          })
        }),
      ).then(
        (images) => {
          setImageURI([...imageURI, ...images])
        },
        (error) => {
          console.error(error)
        },
      )
    }
  }

  const handleOptionChange = (event) => {
    const option = event.target.value
    setSelectedOption((prevState) => (prevState === option ? null : option))
  }

  const deleteParticularFile = (id) => {
    // const s = imageURI?.filter((item, index) => index !== id)
    const extractFile = files?.filter((item, index) => index !== id)
    setFiles(extractFile)
    // setImageURI(s)
  }
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    console.log("🚀 ~ useEffect ~ urlParams:", urlParams.get('edit'))
    setEditing(urlParams.get('edit'))
    const currentPrams = urlParams.get('step')
    if (currentPrams && currentPrams != 'null') {
      redirectFunction(currentPrams)
    }
    //  else {
    //   redirectFunction("itemType")
    // }
    getQRData()
  }, [])

  const handleChangeSelect = (val) => {
    if (val?.value === "rejected" || val?.value === "pending") {
      dispatch(setItemStatus(val?.value))
      setModal(true)
    } else {
      dispatch(setItemStatus(val?.value))
    }
  }

  const redirectFunction = (data) => {
    if (data == "brand") {
      dispatch(setRejectedItemSummary(rejectedItemSummaryState))
      window.localStorage.setItem("rejectedItemSummary", rejectedItemSummaryState)
    }
    const urlParams = new URLSearchParams(window.location.search)
    const currentPrams = urlParams.get('step')
    const params = {
      step: data,
    }
    const options = {
      pathname: window.location.pathname,
      search: `?step=` + data,
    }
    var query = window.location.search
    navigate(options, { replace: true })
    const urlCurrentParams = new URLSearchParams(window.location.search)
    setCurrentTab(urlCurrentParams.get('step'))
  }

  const getQRData = async () => {
    const { bagId, itemCode } = itemsData
    const { data } = await get(`admin/offline-order/get-items/${bagId}/${itemCode}`)
    if (data?.status) {
      setEditData(data)
      dispatch(setItem(data?.item?.itemId))
      dispatch(setBrand(data?.item?.brand))
      dispatch(setColors(data?.item?.colorIds))
      dispatch(setWashType(data?.item?.process))
      data?.item?.stain?.split(',').map((v) => {
        dispatch(setStain(v))
      })
      dispatch(setDescription(data?.item?.description))
      setInput(data?.item?.description)
      dispatch(setStainImage(data?.item?.image))
      dispatch(setPicture(ImageUrl2 + data?.item?.picture))
      setImgUrl(data?.item?.image)
      // setPicture(ImageUrl2 + data?.item?.picture)
      setPicUrl(data?.item?.picture)
      if (data?.item && data?.item?.image.length > 0) {
        const img = data?.item?.image.map((file) => {
          return ImageUrl2 + file
        })
        setFiles(img)
      }
    }
  }


  const [modalStain, setModalStain] = useState(false)

  const handleStain = (data) => {
    dispatch(setStain(data.name))
    setSelectedStain(data.name)
    setModalStain(true)

  }

  const toggleStain = (result) => {
    if (result === true) {
      setModalStain(false)
      addOrUpdateStain(selectedStain, description_en)
      setDescription_en()
      setValue("")
    }
    else if (result === "cancel") {
      setFilesCat(prevState => {
        const newArr = prevState.filter(stain => stain.stainName !== selectedStain);
        return newArr
      });
      setModalStain(false)
      setDescription_en()
      setValue("")
    }
    else {
      setModalStain(false)
      setDescription_en()
      setValue("")
    }
  }

  useEffect(() => {
    if (modalStain) {
      const obj = filesCat.find(stain => stain.stainName === selectedStain)
      if (obj && obj.safeMessage) {
        const des = obj.safeMessage
        setTimeout(() => {
          setDescription_en(des)
        }, 500);
      }
    }
  }, [modalStain])

  // if (currentTab === 'service') {
  //   return (
  //     <CContainer>
  //       <CCard>
  //         <CCardGroup>
  //           <CCardBody>
  //             <CRow className="p-2 align-items-center">
  //               <CCol>
  //                 <h5 style={{ textTransform: 'uppercase' }}>Service </h5>
  //               </CCol>
  //             </CRow>
  //             <hr />
  //             <CRow className="p-2 align-items-center">
  //               {serviceData.length > 0 &&
  //                 serviceData.map((data, index) => (
  //                   <CCol xs={3} key={index}>
  //                     <div
  //                       onClick={() => {
  //                         console.log("running")
  //                         setSelectedService(data._id);
  //                         setCurrentTab('itemType')
  //                         }}
  //                       style={{
  //                         padding: '30px',
  //                         margin: '20px 10px',
  //                         border: '1px solid black',
  //                         display: 'flex',
  //                         justifyContent: 'center',
  //                         alignItems: 'center',
  //                         boxShadow: '1px 1px 3px black',
  //                         border: 'none',
  //                         cursor: 'pointer',
  //                         // backgroundColor:
  //                         //   selectedService.toString() === data._id.toString() ? '#2eb85c' : null,
  //                       }}
  //                     >
  //                       {data.serviceName_EN}
  //                     </div>
  //                   </CCol>
  //                 ))}
  //             </CRow>
  //             <CRow className="justify-content-end">
  //               <CCol xs={2} className="justify-content-end">
  //                 {selectedService !== '' && (
  //                   <button
  //                     style={{
  //                       marginTop: '20px',
  //                       width: '100%',
  //                       background: '#d3dc40',
  //                       border: '#d3dc40',
  //                       padding: '10px 0',
  //                     }}
  //                     onClick={() => setCurrentTab('itemType')}
  //                   >
  //                     Next
  //                   </button>
  //                 )}
  //               </CCol>
  //             </CRow>
  //           </CCardBody>
  //         </CCardGroup>
  //       </CCard>
  //     </CContainer>
  //   )
  // }
  console.log("itemStatus ------>>>", currentTab)


  const toggleModal = () => {
    setModal(false)
    setRejectedItemSummaryState(description_en)
    setDescription_en()
    setValue("")
  }

  const handleButtonClick = () => {

  }

  if (currentTab === 'itemType') {
    return (
      <CContainer>
        <CModal size="lg" visible={modal} onClose={toggleModal} alignment="center" scrollable aria-labelledby="VerticallyCenteredExample">
          <CModalHeader onClose={toggleModal}>
            <CModalTitle>Enter Details</CModalTitle>
          </CModalHeader>
          <CModalBody style={{ "minHeight": "450px" }}>
            <div style={{ "marginBottom": "25px" }}>
              <Select
                options={data.map((item) => { return ({ value: item?._id, label: item?.title_en, description: item?.description_en }) })}
                value={value}
                onChange={(selected) => setValue(selected)}
                placeholder="Select a title"
              />
            </div>
            {description_en !== undefined && <CKEditor
              editor={ClassicEditor}
              data={description_en}
              onChange={(event, editor) => {
                const data = editor.getData();
                setDescription_en(data);
              }}
            />}
          </CModalBody>
          <CModalFooter>
            <CButton type='button' onClick={toggleModal}>Submit</CButton>
          </CModalFooter>
        </CModal>
        <CCard>
          <CCardGroup>
            <CCardBody>
              <CRow className="p-2 align-items-center">
                <CCol>
                  <h5 style={{ textTransform: 'uppercase' }}>item Type</h5>
                </CCol>
                {/* <CCol xs={2}>
                  <button
                    style={{
                      marginTop: '20px',
                      width: '100%',
                      background: '#d3dc40',
                      border: '#d3dc40',
                      padding: '10px 0',
                    }}
                    onClick={() => setCurrentTab('brand')}
                  >
                    Next
                  </button>
                </CCol> */}
              </CRow>
              <hr />
              <CRow className="p-2 align-items-center">
                <CCol xs={3}>
                  <CFormLabel style={{ marginRight: '5px' }}>Item Status </CFormLabel>
                  <Select
                    defaultValue={itemStatus?.value}
                    onChange={(val) => {
                      handleChangeSelect(val)
                    }}
                    options={[
                      { value: '', label: 'None' },
                      { value: 'pending', label: 'Pending' },
                      { value: 'rejected', label: 'Rejected' },
                    ]}
                    value={itemStatus?.value}
                    placeholder={'Select Item Status'}
                    name="Item status"
                  />

                  {/* <CFormCheck inline
                    id="inlineCheckbox1"
                    value="pending"
                    // checked={itemStatus == "pending" ? true : ""}
                    onChange={(e) => {
                      dispatch(setItemStatus(e.target.value))
                      // setItemStatus(e.target.value)
                    }}
                    label="Pending"

                    />
                  <CFormCheck inline
                    id="inlineCheckbox2"
                    value="rejected"
                    onChange={(e) => {
                      dispatch(setItemStatus(e.target.value))
                      // setItemStatus(e.target.value)
                    }}
                    // checked={itemStatus == "rejected" ? true : ""}
                    label="Rejected"
                     /> */}
                </CCol>
              </CRow>
              <hr />
              <CRow className="p-2 align-items-center">
                {itemData?.length > 0 &&
                  itemData.map((data, index) => (
                    <CCol xs={3} key={index}>
                      <div style={{ position: 'relative' }}>
                        <div
                          onClick={() => {
                            dispatch(setItem(data._id))
                            window.localStorage.setItem("itemId", data._id)
                          }}
                          className="card p-4 w-100 d-flex align-items-center justify-content-center text-center my-2"
                          style={{ wordBreak: 'break-all', cursor: 'pointer' }}
                        >
                          {data.itemName_EN}
                        </div>
                        {item === data?._id && (
                          <div
                            style={{ position: 'absolute', top: 0, right: '10%', fontSize: '24px' }}
                          >
                            <i class="fa-solid fa-check"></i>
                          </div>
                        )}
                      </div>
                    </CCol>
                  ))}
                <CCol xs={3}>
                  <div style={{ position: 'relative' }}>
                    <div
                      onClick={() => {
                        navigate('/admin/items?sorting=true')
                      }}
                      className="card p-4 w-100 d-flex align-items-center justify-content-center text-center my-2"
                      style={{ wordBreak: 'break-all', cursor: 'pointer' }}
                    >
                      Add & Delete
                    </div>
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
          </CCardGroup>
          <CRow className="justify-content-between p-3">
            <CCol xs={2} className="justify-content-end">
              <button
                style={{
                  marginTop: '20px',
                  width: '100%',
                  background: '#d3dc40',
                  border: '#d3dc40',
                  padding: '10px 0',
                }}
                onClick={() => navigate(-1)}
              >
                prev
              </button>
            </CCol>
            <CCol xs={2} className="justify-content-end">
              {item !== '' && (
                <>
                  <button
                    style={{
                      marginTop: '20px',
                      width: '100%',
                      background: '#d3dc40',
                      border: '#d3dc40',
                      padding: '10px 0',
                    }}
                    onClick={() => redirectFunction('brand')}
                  >
                    Next
                  </button>
                  {editing && (
                    <button
                      style={{
                        marginTop: '20px',
                        width: '100%',
                        background: '#d3dc40',
                        border: '#d3dc40',
                        padding: '10px 0',
                      }}
                      onClick={() => submitData()}
                    >
                      Edit Confirm
                    </button>
                  )}
                </>
              )}
            </CCol>
          </CRow>
        </CCard>
      </CContainer>
    )
  }

  if (currentTab === 'brand') {
    return (
      <CContainer>
        <CCard>
          <CCardGroup>
            <CCardBody>
              <CRow className="p-2 align-items-center">
                <CCol>
                  <h5 style={{ textTransform: 'uppercase' }}>Brand</h5>
                </CCol>
                {/* <CCol xs={2}>
                  <button
                    style={{
                      marginTop: '20px',
                      width: '100%',
                      background: '#d3dc40',
                      border: '#d3dc40',
                      padding: '10px 0',
                    }}
                    onClick={() => setCurrentTab('itemType')}
                  >
                    Back
                  </button>
                </CCol>
                <CCol xs={2}>
                  <button
                    style={{
                      marginTop: '20px',
                      width: '100%',
                      background: '#d3dc40',
                      border: '#d3dc40',
                      padding: '10px 0',
                    }}
                    onClick={() => setCurrentTab('color')}
                  >
                    Next
                  </button>
                </CCol> */}
              </CRow>
              <hr />
              <CRow className="p-2 align-items-center">
                {brandData.length > 0 &&
                  brandData.map((data, index) => (
                    <CCol xs={2} key={index}>
                      <div style={{ position: 'relative' }}>
                        <div
                          onClick={() => {
                            dispatch(setBrand(data.attributeName_EN))
                          }}
                          className="card p-4 w-100 d-flex align-items-center justify-content-center text-center my-2"
                          style={{ wordBreak: 'break-all', cursor: 'pointer' }}
                        >
                          {data.attributeName_EN}
                        </div>
                        {brand === data?.attributeName_EN && (
                          <div
                            style={{ position: 'absolute', top: 0, right: '10%', fontSize: '24px' }}
                          >
                            <i class="fa-solid fa-check"></i>
                          </div>
                        )}
                      </div>
                    </CCol>
                  ))}
                <CCol xs={2}>
                  <div style={{ position: 'relative' }}>
                    <div
                      onClick={() => {
                        navigate('/admin/option')
                      }}
                      className="card p-4 w-100 d-flex align-items-center justify-content-center text-center my-2"
                      style={{ wordBreak: 'break-all', cursor: 'pointer' }}
                    >
                      Add More & Delete
                    </div>
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
          </CCardGroup>
          <CRow className="justify-content-between p-3">
            <CCol xs={2} className="justify-content-end">
              {brand !== '' && (
                <button
                  style={{
                    marginTop: '20px',
                    width: '100%',
                    background: '#d3dc40',
                    border: '#d3dc40',
                    padding: '10px 0',
                  }}
                  onClick={() => redirectFunction('itemType')}
                >
                  prev
                </button>
              )}
            </CCol>
            <CCol xs={2} className="justify-content-end">
              {brand !== '' && (
                <>
                  {editing && (
                    <button
                      style={{
                        marginTop: '20px',
                        width: '100%',
                        background: '#d3dc40',
                        border: '#d3dc40',
                        padding: '10px 0',
                      }}
                      onClick={() => submitData()}
                    >
                      Edit Confirm
                    </button>
                  )}
                  <button
                    style={{
                      marginTop: '20px',
                      width: '100%',
                      background: '#d3dc40',
                      border: '#d3dc40',
                      padding: '10px 0',
                    }}
                    onClick={() => redirectFunction('color')}
                  >
                    Next
                  </button>
                </>
              )}
            </CCol>
          </CRow>
        </CCard>
      </CContainer>
    )
  }

  if (currentTab === 'color') {
    return (
      <CContainer>
        <CCard>
          <CCardGroup>
            <CCardBody>
              <CRow className="p-2 align-items-center">
                <CCol>
                  <h5 style={{ textTransform: 'uppercase' }}>Color</h5>
                </CCol>
              </CRow>
              <hr />
              <CRow className="p-2 align-items-center">
                {colorData.length > 0 &&
                  colorData.map((data, index) => (
                    <CCol xs={1} key={index}>
                      <div
                        // onClick={() => {
                        //   setSelectedBrand(data.optionName_EN)
                        //   setCurrentTab('process')
                        // }}
                        onClick={() => dispatch(setColors(data._id))}
                        style={{
                          // padding: '5px',
                          height: '66px',
                          width: '66px',
                          borderRadius: '100%',
                          // margin: '20px 10px',
                          border: colors.find((_item) => _item === data._id)
                            ? '3px solid #3499ff'
                            : null,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',

                          // backgroundColor: data.colorCode,
                          // border: 'none',
                          // cursor: 'pointer',
                          // backgroundColor:
                          //   selectedBrand.toString() === data.optionName_EN.toString()
                          //     ? '#2eb85c'
                          //     : null,
                        }}
                      >
                        <div
                          style={{
                            // padding: '30px',
                            height: '50px',
                            width: '50px',
                            borderRadius: '100%',
                            // margin: '20px 10px',
                            // border: '1px solid black',
                            boxShadow: '0 1px 3px rgb(0 0 0 / 36%)',
                            // display: 'flex',
                            // justifyContent: 'center',
                            // alignItems: 'center',
                            // boxShadow: '1px 1px 3px black',
                            backgroundColor: data.colorCode,
                            // border: 'none',
                            cursor: 'pointer',
                            // backgroundColor:
                            //   selectedBrand.toString() === data.optionName_EN.toString()
                            //     ? '#2eb85c'
                            //     : null,
                          }}
                        ></div>
                      </div>
                    </CCol>
                  ))}
                <CCol xs={1}>
                  <div
                    onClick={() => {
                      navigate('/admin/colors')
                    }}
                    style={{
                      // padding: '30px',
                      height: '50px',
                      width: '50px',
                      borderRadius: '100%',
                      textAlign: 'center',
                      margin: 'auto',
                      fontSize: 'x-large',
                      // margin: '20px 10px',
                      // border: '1px solid black',
                      boxShadow: '0 1px 3px rgb(0 0 0 / 36%)',
                      // display: 'flex',
                      // justifyContent: 'center',
                      // alignItems: 'center',
                      // boxShadow: '1px 1px 3px black',
                      backgroundColor: 'white',
                      // border: 'none',
                      cursor: 'pointer',
                    }}
                  >
                    +
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
          </CCardGroup>
          <CRow className="justify-content-between p-3">
            <CCol xs={2} className="justify-content-end">
              <button
                style={{
                  marginTop: '20px',
                  width: '100%',
                  background: '#d3dc40',
                  border: '#d3dc40',
                  padding: '10px 0',
                }}
                onClick={() => redirectFunction('brand')}
              >
                Back
              </button>
            </CCol>
            <CCol xs={2} className="justify-content-end">
              {editing && (
                <button
                  style={{
                    marginTop: '20px',
                    width: '100%',
                    background: '#d3dc40',
                    border: '#d3dc40',
                    padding: '10px 0',
                  }}
                  onClick={() => submitData()}
                >
                  Edit Confirm
                </button>
              )}
              <button
                style={{
                  marginTop: '20px',
                  width: '100%',
                  background: '#d3dc40',
                  border: '#d3dc40',
                  padding: '10px 0',
                }}
                onClick={() => redirectFunction('process')}
              >
                Next
              </button>
            </CCol>
          </CRow>
        </CCard>
      </CContainer>
    )
  }

  if (currentTab === 'process') {
    return (
      <CContainer>
        <CCard>
          <CCardGroup>
            <CCardBody>
              <CRow className="p-2 align-items-center">
                <CCol>
                  <h5 style={{ textTransform: 'uppercase' }}>Process & description</h5>
                </CCol>
              </CRow>
              <hr />
              <CRow className="p-2 justify-space-between">
                {washingType.length > 0 &&
                  washingType.map((data, index) => (
                    <CCol xs={2} key={index}>
                      <div style={{ position: 'relative' }}>
                        <div
                          onClick={() => {
                            dispatch(setWashType(data._id))
                            window.localStorage.setItem("washType", data._id)
                          }}
                          className="card p-4 w-100 d-flex align-items-center justify-content-center text-center my-2"
                          style={{ wordBreak: 'break-all', cursor: 'pointer' }}
                        >
                          {data?.processName_EN}
                        </div>
                        {washType === data?._id && (
                          <div
                            style={{ position: 'absolute', top: 0, right: '10%', fontSize: '24px' }}
                          >
                            <i class="fa-solid fa-check"></i>
                          </div>
                        )}
                      </div>
                    </CCol>
                  ))}
                <CCol xs={2}>
                  <div style={{ position: 'relative' }}>
                    <div
                      onClick={() => navigate('/admin/wash-process')}
                      className="card p-4 w-100 d-flex align-items-center justify-content-center text-center my-2"
                      style={{ wordBreak: 'break-all', cursor: 'pointer' }}
                    >
                      Add More & Delete
                    </div>
                  </div>
                </CCol>
                <CCol xs={4}></CCol>
              </CRow>
              <CRow>
                <CFormLabel>Instructions</CFormLabel>
                <CustomKeyboard
                  input={input}
                  setInput={setTextData}
                  layoutName={layoutName}
                  setLayoutName={setLayoutName}
                />
                {/* <DefaultInput
                  type="text"
                  value={input}
                  onChange={onChangeInput}
                  name="description"
                  id="description"
                  style={{ marginTop: '30px' }}
                /> */}
              </CRow>
            </CCardBody>
          </CCardGroup>
          <CRow className="justify-content-between p-3">
            <CCol xs={2} className="justify-content-end">
              <button
                style={{
                  marginTop: '20px',
                  width: '100%',
                  background: '#d3dc40',
                  border: '#d3dc40',
                  padding: '10px 0',
                }}
                onClick={() => redirectFunction('color')}
              >
                Back
              </button>
            </CCol>
            <CCol xs={2} className="justify-content-end">
              {editing && (
                <button
                  style={{
                    marginTop: '20px',
                    width: '100%',
                    background: '#d3dc40',
                    border: '#d3dc40',
                    padding: '10px 0',
                  }}
                  onClick={() => submitData()}
                >
                  Edit Confirm
                </button>
              )}
              <button
                style={{
                  marginTop: '20px',
                  width: '100%',
                  background: '#d3dc40',
                  border: '#d3dc40',
                  padding: '10px 0',
                }}
                onClick={() => redirectFunction('itemImage')}
              >
                Next
              </button>
            </CCol>
          </CRow>
        </CCard>
      </CContainer>
    )
  }

  if (currentTab === 'itemImage') {
    return (
      <CContainer>
        <CCard>
          <CCardGroup>
            <CCardBody>
              <h2 className="mb-5 text-center">Capture</h2>
              {console.log(ImageUrl2 + picture, 'Ddf')}

              <CRow className="justify-content-center">
                <div style={{ margin: 'auto', width: 'auto' }}>
                  <CCol>
                    {picture == '' ? (
                      <Webcam
                        audio={false}
                        height={400}
                        ref={webcamRef}
                        width={400}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                      />
                    ) : (
                      // <img src={ImageUrl2 + picture} />
                      <img src={picture} />
                    )}
                  </CCol>
                </div>
              </CRow>
              <CRow className="justify-content-center">
                <div style={{ margin: 'auto', width: 'auto' }}>
                  <CCol>
                    {picture != '' ? (
                      <button
                        onClick={(e) => {
                          e.preventDefault()
                          dispatch(setPicture(''))
                          console.log({ picture });
                        }}
                        className="btn btn-primary"
                      >
                        Retake
                      </button>
                    ) : (
                      <button
                        onClick={(e) => {
                          e.preventDefault()
                          capture()
                        }}
                        className="btn btn-danger"
                        style={{ padding: '15px 40px' }}
                      >
                        Capture
                      </button>
                    )}
                  </CCol>
                </div>
              </CRow>
              <CRow>
                <CCol xs={4}></CCol>
                <CCol xs={2}>
                  <button
                    style={{
                      marginTop: '20px',
                      width: '100%',
                      background: '#d3dc40',
                      border: '#d3dc40',
                      padding: '10px 0',
                    }}
                    onClick={() => redirectFunction('process')}
                  >
                    Back
                  </button>
                </CCol>
                <CCol xs={2}>
                  {editing && (
                    <button
                      style={{
                        marginTop: '20px',
                        width: '100%',
                        background: '#d3dc40',
                        border: '#d3dc40',
                        padding: '10px 0',
                      }}
                      onClick={() => submitData()}
                    >
                      Edit Confirm
                    </button>
                  )}
                  <button
                    style={{
                      marginTop: '20px',
                      width: '100%',
                      background: '#d3dc40',
                      border: '#d3dc40',
                      padding: '10px 0',
                    }}
                    onClick={() => redirectFunction('stain')}
                  >
                    Next
                  </button>
                </CCol>
              </CRow>
            </CCardBody>
          </CCardGroup>
        </CCard>
      </CContainer>
    )
  }


  if (currentTab === 'stain') {
    return (
      <CContainer>
        <CModal size="xl" visible={modalStain} onClose={() => toggleStain(false)} alignment="center" scrollable aria-labelledby="VerticallyCenteredExample">
          <CModalHeader >
            <CModalTitle>Enter Details</CModalTitle>
          </CModalHeader>
          <CModalBody style={{ "minHeight": "450px" }}>
            <div style={{ "marginBottom": "25px" }}>
              <h5>Select Description :</h5>
              <Select
                options={data.map((item) => { return ({ value: item?._id, label: item?.title_en, description: item?.description_en }) })}
                value={value}
                onChange={(selected) => setValue(selected)}
                placeholder="Select a title"
              />
            </div>
            {description_en !== undefined && <div style={{ "marginBottom": "25px" }}>
              <h5> Description : </h5>
              <CKEditor
                editor={ClassicEditor}
                data={description_en}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setDescription_en(data);
                }}
              />
            </div>}

            <h5>Images :</h5>

            <hr />
            <CRow className="p-2 align-items-center">
              <CFormLabel>Image</CFormLabel>
              <span className="text-danger" style={{ marginLeft: '5px' }}>
                *
              </span>
              <Webcam
                audio={false}
                height={400}
                ref={webcamRefImages}
                width={400}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
              />
              <CRow className="p-2 justify-content-center">
                <div style={{ margin: 'auto', width: 'auto' }}>
                  <CCol>
                    <button
                      onClick={(e) => {
                        e.preventDefault()
                        stainCapture()
                      }}
                      className="btn btn-danger"
                      style={{ padding: '15px 40px' }}
                    >
                      Capture
                    </button>
                  </CCol>
                </div>
              </CRow>
              <div className="myImges row">
                {selectedStain &&
                  filesCat.map((item, index) => {
                    if (item.stainName === selectedStain) {
                      return item.images.map((imgBlob, indexOfImg) => {
                        return (
                          <>
                            <div className="col-md-3">
                              <figure>
                                <img src={`${ImageUrl}${imgBlob}`} />
                                <figcaption>
                                  <button type="button">
                                    <FaTrash
                                      onClick={() => deleteParticularImagee(item.stainName, indexOfImg)}
                                      style={{ color: 'red' }}
                                    />
                                  </button>
                                </figcaption>
                              </figure>
                            </div>
                          </>
                        )
                      })
                    }
                  })}
              </div>
            </CRow>


            {/* <CRow className="justify-content-between p-3">
                <CCol xs={2} className="justify-content-end">
                  <button
                    style={{
                      marginTop: '20px',
                      width: '100%',
                      background: '#d3dc40',
                      border: '#d3dc40',
                      padding: '10px 0',
                    }}
                    onClick={() => redirectFunction('stain')}
                  >
                    Back
                  </button>
                </CCol>
                <CCol xs={2} className="justify-content-end">
                  <button
                    style={{
                      marginTop: '20px',
                      width: '100%',
                      background: '#d3dc40',
                      border: '#d3dc40',
                      padding: '10px 0',
                    }}
                    onClick={() =>
                      itemStatus.value == 'pending' || itemStatus.value == 'rejected'
                        ? redirectFunction('reason')
                        : submitData()
                    }
                  >
                    Next
                  </button>
                </CCol>
              </CRow> */}


          </CModalBody>
          <CModalFooter>
            <div style={{ "textAlign": "end" }}>
              <button className='btn' onClick={() => toggleStain("cancel")}>Remove</button>
              <button className='btn' style={{ "background": "rgb(211, 220, 64)", margin: "0 10px" }} onClick={() => toggleStain(true)}>Submit</button>
            </div>
          </CModalFooter>
        </CModal>
        <CCard>
          <CCardGroup>
            <CCardBody>
              <CRow className="p-2 align-items-center">
                <CCol>
                  <h5 style={{ textTransform: 'uppercase' }}>Stain</h5>
                </CCol>
              </CRow>
              <hr />
              <CRow className="p-2 align-items-center">
                {stainType.length > 0 &&
                  stainType.map((data, index) => (
                    <CCol xs={2} key={index}>
                      <div style={{ position: 'relative' }}>
                        <div
                          onClick={() => handleStain(data)}
                          className="card p-4 w-100 d-flex align-items-center justify-content-center text-center my-2"
                          style={{ wordBreak: 'break-all', cursor: 'pointer' }}
                        >
                          {data.name}
                        </div>
                        {/* {console.log(stain.find((_item) => console.log(_item, data.name)))}
                        {console.log("stain stain", stain)} */}
                        {filesCat.find((_item) => _item.stainName == data.name) && (
                          <div
                            style={{ position: 'absolute', top: 0, right: '10%', fontSize: '24px' }}
                          >
                            <i class="fa-solid fa-check"></i>
                          </div>
                        )}
                      </div>
                    </CCol>
                  ))}
              </CRow>
            </CCardBody>
          </CCardGroup>
          <CRow className="justify-content-between p-3">
            <CCol xs={2} className="justify-content-end">
              <button
                style={{
                  marginTop: '20px',
                  width: '100%',
                  background: '#d3dc40',
                  border: '#d3dc40',
                  padding: '10px 0',
                }}
                onClick={() => redirectFunction('itemImage')}
              >
                Back
              </button>
            </CCol>
            <CCol xs={2} className="justify-content-end">
              {editing && (
                <button
                  style={{
                    marginTop: '20px',
                    width: '100%',
                    background: '#d3dc40',
                    border: '#d3dc40',
                    padding: '10px 0',
                  }}
                  onClick={() => submitData()}
                >
                  Edit Confirm
                </button>
              )}
              <button
                style={{
                  marginTop: '20px',
                  width: '100%',
                  background: '#d3dc40',
                  border: '#d3dc40',
                  padding: '10px 0',
                }}
                // onClick={() => (stain.length > 0 ? redirectFunction('images') : submitData())}
                onClick={() => {
                  console.log(itemStatus.value);
                  itemStatus.value == 'pending' || itemStatus.value == 'rejected'
                    ? redirectFunction('reason')
                    : submitData()
                }}
              >
                Next
              </button>
            </CCol>
          </CRow>
        </CCard>
      </CContainer>
    )
  }

  if (currentTab === 'images') {
    return (
      <CContainer>
        <CCard>
          <CCardGroup>
            <CCardBody>
              <CRow className="p-2 align-items-center">
                <CCol>
                  <h5 style={{ textTransform: 'uppercase' }}>images</h5>
                </CCol>
              </CRow>
              <hr />
              <CRow className="p-2 align-items-center">
                <CFormLabel>Image</CFormLabel>
                <span className="text-danger" style={{ marginLeft: '5px' }}>
                  *
                </span>
                <Webcam
                  audio={false}
                  height={400}
                  ref={webcamRefImages}
                  width={400}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                />
                <CRow className="p-2 justify-content-center">
                  <div style={{ margin: 'auto', width: 'auto' }}>
                    <CCol>
                      <button
                        onClick={(e) => {
                          e.preventDefault()
                          stainCapture()
                        }}
                        className="btn btn-danger"
                        style={{ padding: '15px 40px' }}
                      >
                        Capture
                      </button>
                    </CCol>
                  </div>
                </CRow>
                <div className="myImges row">
                  {files?.length > 0 &&
                    files?.map((item, index) => {
                      return (
                        <>
                          <div className="col-md-3">
                            <figure>
                              <img src={`${item}`} />
                              <figcaption>
                                <button type="button">
                                  <FaTrash
                                    onClick={() => deleteParticularFile(index)}
                                    style={{ color: 'red' }}
                                  />
                                </button>
                              </figcaption>
                            </figure>
                          </div>
                        </>
                      )
                    })}
                </div>
              </CRow>
            </CCardBody>
          </CCardGroup>
          <CRow className="justify-content-between p-3">
            <CCol xs={2} className="justify-content-end">
              <button
                style={{
                  marginTop: '20px',
                  width: '100%',
                  background: '#d3dc40',
                  border: '#d3dc40',
                  padding: '10px 0',
                }}
                onClick={() => redirectFunction('stain')}
              >
                Back
              </button>
            </CCol>
            <CCol xs={2} className="justify-content-end">
              <button
                style={{
                  marginTop: '20px',
                  width: '100%',
                  background: '#d3dc40',
                  border: '#d3dc40',
                  padding: '10px 0',
                }}
                onClick={() =>
                  itemStatus.value == 'pending' || itemStatus.value == 'rejected'
                    ? redirectFunction('reason')
                    : submitData()
                }
              >
                Next
              </button>
            </CCol>
          </CRow>
        </CCard>
      </CContainer>
    )
  }
  if (currentTab === 'reason') {
    return (
      <CContainer>
        <CCard>
          <CCardGroup>
            <CCardBody>
              <CRow className="p-2 align-items-center">
                <CCol>
                  <h5 style={{ textTransform: 'uppercase' }}>Reason</h5>
                </CCol>
              </CRow>
              <hr />
              <CRow>
                <CustomKeyboard
                  input={selectedReason}
                  setInput={setSelectedReason}
                  layoutName={layoutName}
                  setLayoutName={setLayoutName}
                />
                {/* <DefaultInput
                  type="text"
                  value={input}
                  onChange={onChangeInput}
                  name="description"
                  id="description"
                  style={{ marginTop: '30px' }}
                /> */}
              </CRow>
            </CCardBody>
          </CCardGroup>
          <CRow className="justify-content-between p-3">
            <CCol xs={2} className="justify-content-end">
              <button
                style={{
                  marginTop: '20px',
                  width: '100%',
                  background: '#d3dc40',
                  border: '#d3dc40',
                  padding: '10px 0',
                }}
                onClick={() => redirectFunction('images')}
              >
                Back
              </button>
            </CCol>
            <CCol xs={2} className="justify-content-end">
              <button
                style={{
                  marginTop: '20px',
                  width: '100%',
                  background: '#d3dc40',
                  border: '#d3dc40',
                  padding: '10px 0',
                }}
                onClick={submitData}
              >
                Next
              </button>
            </CCol>
          </CRow>
        </CCard>
      </CContainer>
    )
  }
}

export default AddItem
