import React, { useRef, useState } from 'react';
import { Formik } from 'formik';
import { useNavigate, Link } from 'react-router-dom'
import { post } from 'src/utils/apiManager';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormLabel,
  CImage,
  CRow,
} from '@coreui/react'
import { DefaultInput } from 'src/components/common/input'
import { intro } from 'src/utils/validator'
import { Ckeditor } from '../Template/Ckeditor';
import Info from '../info/info';

function addIntro() {
  const [description, setDescription] = useState([])

  const navigate = useNavigate()
  const formRef = useRef(null)
  const fileRef = useRef(null)
  const [introImagePreview, setIntroImagePreview] = useState();
  const submit = async (values) => {
    let introData = {
      title_en: values?.title_en,
      title_th: values?.title_th,
      image: values?.image,
      description_en: values?.description_en,
      description_th: values?.description_th,

    }
    post(`admin/Intro/add-intro`, introData, 1).then(data => {
      handleResponse(data)
    }
    )
  }
  const handleResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setTimeout(() => {
        navigate('/admin/intro')
      }, 800)
    }
    formRef.current.resetForm()
    fileRef.current.value = null
  }
  const onClickReset = () => {
    // Reset Button Function
    formRef.current.setFieldValue('title', '')
    formRef.current.setFieldValue('description', '')
    fileRef.current.value = null

  };
  return (
    <>
      <CContainer >
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>New Intro</h1>
                        </CCol>
                        <CCol md="1">
                          <Link className={'btn btn-success mt-2'} style={{ background: '#d3dc40', border: '#d3dc40' }} to={{ pathname: `/admin/intro` }}>
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          title_en: '',
                          title_th: '',
                          image: '',
                          description_en: '',
                          description_th: '',
                        }}
                        validationSchema={intro}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)

                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props?.handleSubmit}>
                            <CFormLabel>Title (English)</CFormLabel>
                            <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <DefaultInput
                              type={'text'}
                              placeholder={'Title English'}
                              // name={'Title (English)'}
                              id={'title_en'}
                              value={props?.values?.title_en}
                              onChange={props?.handleChange('title_en')}
                              onBlur={() => props?.setFieldTouched('title_en', true)}
                              error={props?.touched?.title_en && props?.errors?.title_en}
                            />
                            <CFormLabel>Title (Thai)</CFormLabel>
                            <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <DefaultInput
                              type={'text'}
                              placeholder={'Title Thai'}
                              // name={'title_th'}
                              id={'title_th'}
                              value={props?.values?.title_th}
                              onChange={props?.handleChange('title_th')}
                              onBlur={() => props?.setFieldTouched('title_th', true)}
                              error={props?.touched?.title_th && props?.errors?.title_th}
                            />
                            {/* {console.log(props.values)} */}
                            <CFormLabel>Image</CFormLabel>
                            <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <Info message="100x120"/>

                            <DefaultInput
                              type={'file'}
                              placeholder={'Image'}
                              // name={'Image'}
                              accept="image/*"
                              refr={fileRef}
                              onChange={(e) => {
                                //for image preview
                                const reader = new FileReader();
                                reader?.addEventListener("load", () => setIntroImagePreview(reader?.result));
                                reader?.readAsDataURL(e?.target?.files[0]);
                                //for image preview
                                props?.setFieldValue('image', e?.target?.files[0])
                              }}
                              onBlur={() => props?.setFieldTouched('image', true)}
                              error={props?.touched?.image && props?.errors?.image}
                            />
                            {/* for image preview */}
                            {introImagePreview?.length > 0 ?
                              <>
                                <CCol>
                                  <CImage
                                    rounded
                                    thumbnail
                                    className="mt-2"
                                    src={`${introImagePreview}`}
                                    style={{
                                      maxHeight: '80px',
                                      maxWidth: '80px',
                                      alignItems: 'center',
                                    }}
                                  />
                                </CCol>
                              </>
                              : ''}
                            {/* for image preview */}
                            <CFormLabel>Description (English)</CFormLabel>
                            <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <Ckeditor
                              setDescription={setDescription}
                              description={props?.values?.description_en}
                              value={props?.values?.description_en}
                              onChange={(val) => {
                                // setDescription(val)
                                // console.log(val,"DESCRIPT");
                                props?.setFieldValue('description_en', val)
                              }}
                              onBlur={() => props?.setFieldTouched('description_en', true)}
                              error={props?.touched?.description_en && props?.errors?.description_en}
                            />

                            <CFormLabel>Description (Thai)</CFormLabel>
                            <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <Ckeditor
                              setDescription={setDescription}
                              description={props.values?.description_th}
                              value={props.values?.description_th}
                              onChange={(val) => {
                                // setDescription(val)
                                // console.log(val,"DESCRIPT");
                                props?.setFieldValue('description_th', val)
                              }}
                              onBlur={() => props?.setFieldTouched('description_th', true)}
                              error={props?.touched?.description_th && props?.errors?.description_th}
                            />
                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"

                                  className={'btn btn-success mt-2'}
                                  disabled={props?.isSubmitting}
                                >
                                  Submit
                                </button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={onClickReset}
                                >
                                  Reset
                                </button>
                              </CCol>
                              <CCol md="3"></CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>


    </>
  )
}

export default addIntro