import React, { useRef, useState, useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { put } from 'src/utils/apiManager';
import { ToastContainer, toast } from 'react-toastify';
import { ImageUrl } from 'src/config/config';
import 'react-toastify/dist/ReactToastify.css'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormLabel,
  CRow,
  CImage,
  CFormInput,
  CForm,
  CFormSelect,
  CFormCheck,
  CButton
} from '@coreui/react'
import { DefaultInput } from 'src/components/common/input'
import { validateEditGlobalSetting } from 'src/utils/validator'
import { Container } from '@mui/system';
import Info from '../info/info';

function edit() {
  const navigate = useNavigate()
  const { state } = useLocation()
  // console.log(state)
  const formRef = useRef(null)
  const fileRef = useRef(null)
  const [imageUrl, setimageUrl] = useState()
  const [invalidImage, setInvalidImage] = useState('')
  const [isDisable, setIsDisable] = useState(false);
  const [socialImagePreview, setSocialImagePreview] = useState('');
  useEffect(() => {
    if (state != null) {
      formRef.current.setFieldValue('socialLink', state?.socialLink)
      if (state?.image) setimageUrl(`${ImageUrl}${state?.image}`)
    }
  }, [state])

  const submit = async (values) => {
    if (invalidImage?.length === 0) {
      let socialSettingData = {
        id: state?._id,
        socialLink: values?.socialLink,
        image: values?.image,
      }
      put(`admin/social/update?_id=${state?._id}`, socialSettingData, 1).then((data) => { handleResponse(data) })
    } else {
      return
    }
  }
  const handleResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setTimeout(() => {
        navigate('/admin/social-setting')
      }, 800)
    }
    formRef.current.resetForm()
    fileRef.current.value = null
  }

  return (
    <>
      <CContainer >
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>Edit Social Setting</h1>
                        </CCol>
                        <CCol md="1">
                          <Link className={'btn btn-success mt-2'} style={{ background: '#d3dc40', border: '#d3dc40' }} to={{ pathname: `/admin/social-setting` }}>
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          socialLink: '',
                          image: '',
                        }}
                        validationSchema={validateEditGlobalSetting}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)

                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props?.handleSubmit}>
                            <CFormLabel>Social Link</CFormLabel>
                            <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <DefaultInput
                              type={"text"}
                              placeholder={"Social Link"}
                              value={props?.values?.socialLink}
                              onChange={props?.handleChange("socialLink")}
                              onKeyUp={() => {
                                if (state?.socialLink == props?.values?.socialLink) setIsDisable(false)
                                else setIsDisable(true)
                              }}
                              onBlur={() => props?.setFieldTouched("socialLink", true)}
                              error={props?.touched?.socialLink && props?.errors?.socialLink}
                            />

                            <CFormLabel>Image</CFormLabel>
                            <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <Info message="100x120"/>

                            <DefaultInput
                              type={'file'}
                              placeholder={'Image'}
                              accept="image/*"
                              refr={fileRef}
                              onChange={(e) => {
                                const imageFile = e?.target?.files[0];
                                if (state?.image == imageFile?.name) setIsDisable(false)
                                else setIsDisable(true)
                                if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                  setInvalidImage("Please select valid image")
                                }
                                else {
                                  setInvalidImage('')
                                }
                                //for image preview 
                                const reader = new FileReader();
                                reader?.addEventListener("load", () => setSocialImagePreview(reader?.result));
                                reader?.readAsDataURL(e?.target?.files[0]);
                                //for image preview
                                props?.setFieldValue('image', e?.target?.files[0])
                              }}
                              onBlur={() => props?.setFieldTouched('image', true)}
                              error={(props?.touched?.image && props?.errors?.image) || invalidImage}
                            />
                            <div
                              style={{
                                marginTop: '10px',
                                justifyContent: 'center',
                              }}
                            >
                              {/* for image preview */}
                              {socialImagePreview?.length > 0 ?
                                <>
                                  <CCol>
                                    <CImage
                                      rounded
                                      thumbnail
                                      className="mt-2"
                                      src={`${socialImagePreview}`}
                                      style={{
                                        maxHeight: '80px',
                                        maxWidth: '80px',
                                        alignItems: 'center',
                                      }}
                                    />
                                  </CCol>
                                </>
                                : ''}
                              {/* for image preview */}
                              {imageUrl && (
                                <CImage
                                  rounded
                                  thumbnail
                                  src={imageUrl}
                                  style={{
                                    maxHeight: '80px',
                                    maxWidth: '80px',
                                    alignItems: 'center',
                                  }}
                                />
                              )}
                            </div>
                            <CRow>
                              <CCol md="2">
                                <button type="submit" className={'btn btn-success mt-2'} disabled={!isDisable || props?.isSubmitting}>Submit</button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={() => navigate('/admin/social-setting')}
                                >
                                  Cancel
                                </button>
                              </CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default edit