import React, { useState, useRef, useEffect } from 'react'
import { Formik } from 'formik'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { post, put } from 'src/utils/apiManager'
import { ImageUrl } from 'src/config/config'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'


import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormLabel,
  CRow,
  CImage,
} from '@coreui/react'
import { DefaultInput } from 'src/components/common/input'
import { appHomeAbout, appHomeAboutUpdate, slider } from 'src/utils/validator'
import { Ckeditor } from '../Template/Ckeditor'
import Info from '../info/info'

function addHelp() {
  const navigate = useNavigate()
  const { state } = useLocation()
  const formRef = useRef(null)
  const fileRef = useRef(null)
  const [description_EN, setDescription_EN] = useState('')
  const [title, setTitle] = useState('')
  const [description_TH, setDescription_TH] = useState('')
  const [imgArr, setImgArr] = useState([])
  const [userImagePreview, setUserImagePreview] = useState([]);

  useEffect(() => {
    if (state) {
      formRef.current.setFieldValue('title_en', state?.title_en)
      formRef.current.setFieldValue('title_th', state?.title_th)
      formRef.current.setFieldValue('description_en', state?.description_en)
      formRef.current.setFieldValue('description_th', state?.description_th)
      if (state?.image) setImgArr(state?.image)
    }

  }, [state])
  console.log(state)
  const submit = async (values) => {

    console.log(state?.image, "state?.image")
    console.log(values?.image, "vallllllllllllll", typeof (values?.image))
    let helpData = {
      title_en: values?.title_en,
      title_th: values?.title_th,
      description_en: values?.description_en,
      description_th: values?.description_th,
      image: values?.image == '' ? state?.image : values?.image

    }
    // if (values?.image) {
    //   helpData.image = values?.image
    // }
    // else {
    //   helpData.image = state?.image
    // }
    if (state) {
      helpData.id = state?._id
    }
    // console.log('sliderData',helpData)
    if (state) {
      put(`admin/homeAboutUs/update`, helpData, 1).then((data, err) => {
        // console.log(data)
        handleResponse(data)
      })
    }
    else {
      post(`admin/homeAboutUs/add`, helpData, 1).then((data, err) => {
        handleResponse(data)
      })
    }
  }
  const handleResponse = (data) => {
    // console.log(data)
    if (data?.status == true) {
      toast.success(data?.message)
      setTimeout(() => {
        navigate('/admin/appHomeAboutUs')
      }, 1000)
    }
  }

  const onClickReset = () => {
    formRef.current.setFieldValue('title_en', '')
    formRef.current.setFieldValue('title_th', '')
    formRef.current.setFieldValue('description_en', '')
    formRef.current.setFieldValue('description_th', '')
    formRef.current.setFieldValue('image', '')
  }
  // console.log(state,"state@@@@@@@@")
  return (
    <>
      <CContainer >
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          {!state ? <h1>Add Home About Us</h1> : <h1>Edit Home About Us</h1>}
                        </CCol>
                        <CCol md="1">
                          <Link className={'btn btn-success mt-2'} style={{ background: '#d3dc40', border: '#d3dc40' }} to={{ pathname: `/admin/appHomeAboutUs` }}>
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          title_en: state?.title_en || '',
                          title_th: state?.title_en || '',
                          description_en: state?.description_en || '',
                          description_th: state?.description_th || '',
                          image: '',
                        }}
                        validationSchema={state ? appHomeAboutUpdate : appHomeAbout}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)

                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props?.handleSubmit}>
                            <CRow>
                              <CCol md="6">
                              <CFormLabel>Title (EN)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Title'}
                                  // name={'Title (EN)'}
                                  id={'title_en'}
                                  value={props?.values?.title_en}
                                  onChange={props?.handleChange('title_en')}
                                  onBlur={() => props?.setFieldTouched('title_en', true)}
                                  error={props?.touched?.title_en && props?.errors?.title_en}
                                />
                              </CCol>
                              <CCol className="mb-4">
                              <CFormLabel>Title (TH)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Title'}
                                  // name={'Title (TH)'}
                                  id={'title_th'}
                                  value={props?.values?.title_th}
                                  onChange={props?.handleChange('title_th')}
                                  onBlur={() => props?.setFieldTouched('title_th', true)}
                                  error={props?.touched?.title_th && props?.errors?.title_th}
                                />
                              </CCol>

                              <CCol md="6">
                                <CFormLabel>Description (EN)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <Ckeditor
                                  setDescription_EN={setDescription_EN}
                                  description_EN={description_EN}
                                  value={props?.values?.description_en}
                                  onChange={(val) => {
                                    setDescription_EN(val)
                                    props?.setFieldValue('description_en', val)
                                  }}
                                  onBlur={() => props?.setFieldTouched('description_en', true)}
                                  error={props?.touched?.description_en && props?.errors?.description_en}

                                />
                              </CCol>
                              <CCol md="6">
                                <CFormLabel>Description (TH)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <Ckeditor
                                  setDescription_TH={setDescription_TH}
                                  description_TH={description_TH}
                                  value={props?.values?.description_th}
                                  onChange={(val) => {
                                    setDescription_TH(val)
                                    props?.setFieldValue('description_th', val)
                                  }}
                                  onBlur={() => props?.setFieldTouched('description_th', true)}
                                  error={props?.touched?.description_th && props?.errors?.description_th}
                                />
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol md={6}>
                              <CFormLabel>Image</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <Info message="100x120"/>

                                <DefaultInput
                                  multiple={true}
                                  type={'file'}
                                  placeholder={'Image'}
                                  // name={'Image'}
                                  accept="image/*"
                                  refr={fileRef}
                                  onChange={(e) => {
                                    // console.log(e?.target?.files)
                                    const imageFile = e?.target?.files;
                                    const imgArr = Object.values(imageFile);
                                    // console.log(imgArr, "imgArrr@@@@@@@")
                                    // imgArr.map((item, key) => {
                                    //   // if (imgArr == item.name) setIsDisable(false)
                                    //   // else setIsDisable(true)
                                    //   console.log(item)
                                    //   if (!item.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                    //     setInvalidImage("Please select valid image")
                                    //   }
                                    //   else {
                                    //     setInvalidImage('')
                                    //   }
                                    // })

                                    // console.log(imgArr, "imgArrr@@@@@@@!!!!!!!!!!!!!!!!!!!!")
                                    //for image preview
                                    /* Get files in array form */
                                    const files = Array.from(e?.target?.files);
                                    /* Map each file to a promise that resolves to an array of image URI's */
                                    Promise.all(files?.map(file => {
                                      return (new Promise((resolve, reject) => {
                                        const reader = new FileReader();
                                        reader?.addEventListener('load', (ev) => {
                                          resolve(ev.target.result);
                                        });
                                        reader?.addEventListener('error', reject);
                                        reader?.readAsDataURL(file);
                                      }));
                                    }))
                                      .then(images => {
                                        setUserImagePreview([...userImagePreview, ...images])
                                      }, error => {
                                        console.error(error);
                                      });
                                    // //for image preview
                                    // const reader = new FileReader();
                                    // reader.addEventListener("load", () => setUserImagePreview(reader.result));
                                    // console.log("reader@@@@@@@@@@@", reader)
                                    // reader.readAsDataURL(e?.target?.files[0]);
                                    // 

                                    props?.setFieldValue('image', imgArr)
                                  }}
                                  onBlur={() => props?.setFieldTouched('image', true)}
                                  error={props?.touched?.image && props?.errors?.image}
                                />
                              </CCol>
                            </CRow>
                            <div
                              style={{
                                marginTop: '10px',
                                justifyContent: 'center',
                              }}
                            >
                              {/* for image preview */}
                              {/* {console.log(userImagePreview, "user ki imagessss")} */}
                              <CCol>
                                {userImagePreview?.length > 0 &&
                                  userImagePreview?.map((item, index) => {
                                    return (
                                      <>
                                        <CImage
                                          rounded
                                          thumbnail
                                          className='mt-2 mx-1'
                                          src={`${item}`}
                                          style={{
                                            maxHeight: "80px",
                                            maxWidth: "80px",
                                            alignItems: "center",
                                          }}
                                        />
                                        {/* <div className="col-md-3">
                                        <figure>
                                          <img src={`${item}`} />
                                          <figcaption>
                                          </figcaption>
                                        </figure>
                                      </div> */}
                                      </>
                                    )
                                  })}
                              </CCol>
                              {/* for image preview */}
                              {state && imgArr && imgArr.map((item, key) => {
                                // console.log(`${ImageUrl}${item}`)
                                return (
                                  <div key={key} style={{
                                    maxHeight: "80px",
                                    maxWidth: "80px",
                                    alignItems: "center",
                                    display: "inline-flex",
                                    marginRight: "5px",
                                  }}>
                                    <CImage
                                      rounded
                                      thumbnail
                                      className='mt-2 mx-1'
                                      src={`${ImageUrl}${item}`}
                                      style={{
                                        maxHeight: "80px",
                                        maxWidth: "80px",
                                        alignItems: "center",
                                      }}
                                    />
                                  </div>
                                )

                              })}
                            </div>
                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"

                                  className={'btn btn-success mt-2'}
                                  disabled={props?.isSubmitting}
                                >
                                  {state ? 'Update' : 'Submit'}
                                </button>
                              </CCol>
                              {!state && <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={onClickReset}
                                >
                                  Reset
                                </button>
                              </CCol>}
                              <CCol md="3"></CCol>
                            </CRow>


                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default addHelp