import React, { useState, useRef, useEffect } from 'react'
import { Formik } from 'formik'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { post, put } from 'src/utils/apiManager'
import { ImageUrl } from 'src/config/config'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'


import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormLabel,
  CRow,
  CImage,
} from '@coreui/react'
import { DefaultInput } from 'src/components/common/input'
import { appHomeSlider, appHomeSliderUpdate } from 'src/utils/validator'
import Info from '../info/info'

function addAppHomeSlider() {
  const navigate = useNavigate()
  const { state } = useLocation()
  const formRef = useRef(null)
  const fileRef = useRef(null)
  const [enImageUrl, setEnImageUrl] = useState(null)
  const [thImageUrl, setthImageUrl] = useState(null)
  const [eNImagePreview, setENImagePreview] = useState();
  const [tHImagePreview, setTHImagePreview] = useState();


  useEffect(() => {

    if (state) {
      formRef.current.setFieldValue('link', state?.link)
      setEnImageUrl(`${ImageUrl}${state?.en_image}`)
      setthImageUrl(`${ImageUrl}${state?.th_image}`)
    }

  }, [state])
  const submit = async (values) => {
    let sliderDataWithId = {
      id: state?._id,
      en_Image: values?.enImage == '' ? state.en_image : values?.enImage,
      th_Image: values?.thImage == '' ? state.th_image : values?.thImage
    }
    let sliderData = {
      en_Image: values?.enImage == '' ? state.en_image : values?.enImage,
      th_Image: values?.thImage == '' ? state.th_image : values?.thImage
    }
    // if (values?.enImage) {
    //   sliderData.en_Image = values?.enImage
    // }
    // if (values?.thImage) {
    //   sliderData.th_Image = values?.thImage
    // }
    // if (state) {
    //   sliderData.id = state?._id
    // }
    // // console.log('sliderData', sliderData)

    if (state?._id) {
      // if (sliderData?.en_Image || sliderData?.th_Image) {
      put(`admin/homeSlider/update`, sliderDataWithId, 1).then((data, err) => {
        // console.log(data)
        handleResponse(data)
      })
      // }
      // else{
      //   toast.error('Please change atlease one image')
      // }

    }
    else {

      post(`admin/homeSlider/add`, sliderData, 1).then((data, err) => {
        handleResponse(data)
      })
    }
  }
  const handleResponse = (data) => {
    // console.log(data)
    if (data?.status == true) {
      toast.success(data?.message)
      setTimeout(() => {
        navigate('/admin/appHomeSlider')
      }, 1000)
    }
  }

  const onClickReset = () => {
    formRef.current.setFieldValue('enImage', '')
    formRef.current.setFieldValue('thImage', '')
  }

  return (
    <>
      <CContainer >
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>Slider</h1>
                        </CCol>
                        <CCol md="1">
                          <Link className={'btn btn-success mt-2'} style={{ background: '#d3dc40', border: '#d3dc40' }} to={{ pathname: `/admin/appHomeSlider` }}>
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          enImage: '',
                          thImage: '',
                        }}
                        validationSchema={state ? appHomeSliderUpdate : appHomeSlider}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)

                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props.handleSubmit}>

                            <CRow>
                              <CCol md={6}>
                                <CFormLabel>Image (EN)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <Info message="100x120"/>

                                <DefaultInput
                                  type={'file'}
                                  placeholder={'enImage'}
                                  // name={'enImage'}
                                  accept="image/*"
                                  refr={fileRef}
                                  onChange={(e) => {
                                    //for image preview
                                    const reader = new FileReader();
                                    reader?.addEventListener("load", () => setENImagePreview(reader?.result));
                                    reader?.readAsDataURL(e?.target?.files[0]);
                                    //for image preview
                                    props?.setFieldValue('enImage', e?.target?.files[0])
                                  }}
                                  onBlur={() => props?.setFieldTouched('enImage', true)}
                                  error={props?.touched?.enImage && props?.errors?.enImage}
                                />
                                <div
                                  style={{
                                    marginTop: '10px',
                                    justifyContent: 'center',
                                  }}
                                >
                                  {/* for image preview */}
                                  {eNImagePreview?.length > 0 ?
                                    <>
                                      <CCol>
                                        <CImage
                                          rounded
                                          thumbnail
                                          // className="mt-2"
                                          src={`${eNImagePreview}`}
                                          style={{
                                            maxHeight: '80px',
                                            maxWidth: '80px',
                                            alignItems: 'center',
                                          }}
                                        />
                                      </CCol>
                                    </>
                                    : ''}
                                  {/* for image preview */}
                                  {state && enImageUrl && (
                                    <CImage
                                      rounded
                                      thumbnail
                                      src={enImageUrl}
                                      style={{
                                        maxHeight: '80px',
                                        maxWidth: '80px',
                                        alignItems: 'center',
                                      }}
                                    />
                                  )}
                                </div>
                              </CCol>
                              <CCol md={6}>
                                <CFormLabel>Image (TH)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                            <Info message="100x120"/>

                                <DefaultInput
                                  type={'file'}
                                  placeholder={'thImage'}
                                  // name={'thImage'}
                                  accept="image/*"
                                  refr={fileRef}
                                  onChange={(e) => {
                                    //for image preview
                                    const reader = new FileReader();
                                    reader?.addEventListener("load", () => setTHImagePreview(reader?.result));
                                    reader?.readAsDataURL(e?.target?.files[0]);
                                    //for image preview
                                    props?.setFieldValue('thImage', e?.target?.files[0])
                                  }}
                                  onBlur={() => props?.setFieldTouched('thImage', true)}
                                  error={props?.touched?.thImage && props?.errors?.thImage}
                                />
                                <div
                                  style={{
                                    marginTop: '10px',
                                    justifyContent: 'center',
                                  }}
                                >
                                  {/* for image preview */}
                                  {tHImagePreview?.length > 0 ?
                                    <>
                                      <CCol>
                                        <CImage
                                          rounded
                                          thumbnail
                                          // className="mt-2"
                                          src={`${tHImagePreview}`}
                                          style={{
                                            maxHeight: '80px',
                                            maxWidth: '80px',
                                            alignItems: 'center',
                                          }}
                                        />
                                      </CCol>
                                    </>
                                    : ''}
                                  {/* for image preview */}
                                  {state && thImageUrl && (
                                    <CImage
                                      rounded
                                      thumbnail
                                      src={thImageUrl}
                                      style={{
                                        maxHeight: '80px',
                                        maxWidth: '80px',
                                        alignItems: 'center',
                                      }}
                                    />
                                  )}
                                </div>
                              </CCol>
                            </CRow>

                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"

                                  className={'btn btn-success mt-2'}
                                  disabled={props?.isSubmitting}
                                >
                                  {state ? 'Update' : 'Submit'}
                                </button>
                              </CCol>
                              {!state && <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={onClickReset}
                                >
                                  Reset
                                </button>
                              </CCol>}
                              <CCol md="3"></CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default addAppHomeSlider