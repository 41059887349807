import React, { useRef, useState, useEffect } from 'react'
import { Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { post, get, put } from 'src/utils/apiManager'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { CCard, CCardBody, CCardGroup, CCol, CContainer, CRow, CFormLabel, CImage } from '@coreui/react'
import { DefaultInput, DefaultTextarea } from 'src/components/common/input'
import { validatePartners } from '../../utils/validator'
import Info from '../info/info'
function index() {
  const navigate = useNavigate()
  const formRef = useRef(null)
  const fileRef = useRef(null)
  const [iD, setID] = useState('')
  const [invalidImage, setInvalidImage] = useState('')
  const [imageUrl, setImageUrl] = useState('')
  const [imgArr, setImgArr] = useState([])
  const [isDisable, setIsDisable] = useState(false);
  const [partnersImagePreview, setPartnersImagePreview] = useState([]);
  const state = {}
  useEffect(() => {
    getPartners();
  }, [])
  // after getting id for edit section
  const getPartners = () => {
    get(`/admin/partners/partners`).then((response) => {
      if (response.status == true) {
        handleSingleData(response)
      }
    });
  };
  const handleSingleData = (response) => {

    if (!response.status) toast.success(response.message, response.status);
    formRef.current.setFieldValue("title_EN", response?.data?.title_EN);
    formRef.current.setFieldValue("title_TH", response?.data?.title_TH);
    formRef.current.setFieldValue("description_EN", response?.data?.description_EN);
    formRef.current.setFieldValue("description_TH", response?.data?.description_TH);
    setImageUrl(response.imageUrl)
    setImgArr(response?.data?.partnerImage)

    if (response?.data?._id) setID(`${response?.data?._id}`)
  }
  const submit = async (values) => {
    let data = {
      id: iD,
      title_EN: values?.title_EN,
      title_TH: values?.title_TH,
      description_EN: values?.description_EN,
      description_TH: values?.description_TH,
      partnerImage: values?.partnerImage,
    }
    console.log(data)
    if (iD) {
      put(`admin/partners/update`, data, 1).then((response) => {
        handleAnswer(response)
      })
    } else {
      post(`admin/partners/app-partner`, data, 1).then((response) => handleAnswer(response))
    }
  }
  const handleAnswer = (data) => {
    if (data.status == true) {
      toast.success(data.message)
      getPartners();
    } else {
      toast.error(data.message)
    }
  };
  return (
    <>
      <CContainer >
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <h1>Our Partners</h1>
                      <Formik
                        initialValues={{
                          title_EN: '',
                          title_TH: '',
                          description_EN: '',
                          description_TH: '',
                          partnerImage: '',

                        }}
                        validationSchema={validatePartners}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values);
                          setSubmitting(false);
                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props?.handleSubmit}>

                            <CRow xs={12}>
                              <CCol md={6}>
                                <CFormLabel>Title (EN)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Title (EN)'}
                                  id={'title_EN'}
                                  maxLength="50"
                                  value={props?.values?.title_EN}
                                  onChange={props?.handleChange('title_EN')}
                                  onKeyUp={() => {
                                    if (state?.title_EN == props?.values?.title_EN) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                  onBlur={() => props?.setFieldTouched('title_EN', true)}
                                  error={props?.touched?.title_EN && props?.errors?.title_EN}
                                />
                              </CCol>
                              <CCol md={6}>
                                <CFormLabel>Title (TH)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Title (TH)'}
                                  id={'title_TH'}
                                  maxLength="50"
                                  value={props?.values?.title_TH}
                                  onChange={props?.handleChange('title_TH')}
                                  onKeyUp={() => {
                                    if (state?.title_TH == props?.values?.title_TH) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                  onBlur={() => props?.setFieldTouched('title_TH', true)}
                                  error={props?.touched?.title_TH && props?.errors?.title_TH}
                                />
                              </CCol>
                              <CCol md={6}>
                                <DefaultTextarea
                                  placeholder={"Some Text"}
                                  name={"Description (EN)"}
                                  id={"description_EN"}
                                  maxLength={200}
                                  value={props?.values?.description_EN}
                                  onChange={props?.handleChange("description_EN")}
                                  onKeyUp={() => {
                                    if (state?.description_EN == props?.values?.description_EN) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                  onBlur={() =>
                                    props?.setFieldTouched("description_EN", true)
                                  }
                                  error={props?.touched?.description_EN && props?.errors?.description_EN}
                                />
                              </CCol>
                              <CCol md={6}>
                                <DefaultTextarea
                                  placeholder={"Some Text"}
                                  name={"Description (TH)"}
                                  id={"description_TH"}
                                  maxLength={200}
                                  value={props?.values?.description_TH}
                                  onChange={props?.handleChange("description_TH")}
                                  onKeyUp={() => {
                                    if (state?.description_TH == props?.values?.description_TH) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                  onBlur={() =>
                                    props?.setFieldTouched("description_TH", true)
                                  }
                                  error={props?.touched?.description_TH && props?.errors?.description_TH}
                                />
                              </CCol>
                              <CCol md={6}>
                                <CFormLabel>Our Partners Images</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <Info message="100x120"/>

                                <DefaultInput
                                  type={'file'}
                                  placeholder={'Image'}
                                  accept="image/*"
                                  multiple={true}
                                  // refr={fileRef}
                                  onChange={(e) => {
                                    const imageFile = e?.target?.files;
                                    const imgArr = Object.values(imageFile);
                                    imgArr?.map((item, key) => {
                                      if (imgArr == item?.name) setIsDisable(false)
                                      else setIsDisable(true)
                                      if (!item?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                        setInvalidImage("Please select valid image")
                                      }
                                      else {
                                        setInvalidImage('')
                                      }
                                    })
                                    //for image preview
                                    /* Get files in array form */
                                    const files = Array.from(e?.target?.files);
                                    /* Map each file to a promise that resolves to an array of image URI's */
                                    Promise.all(files.map(file => {
                                      return (new Promise((resolve, reject) => {
                                        const reader = new FileReader();
                                        reader?.addEventListener('load', (ev) => {
                                          resolve(ev.target.result);
                                        });
                                        reader?.addEventListener('error', reject);
                                        reader?.readAsDataURL(file);
                                      }));
                                    }))
                                      .then(images => {
                                        setPartnersImagePreview([...partnersImagePreview, ...images])
                                      }, error => {
                                        console.error(error);
                                      });
                                    // //for image preview
                                    props?.setFieldValue('partnerImage', imgArr)
                                  }}
                                  onBlur={() => props?.setFieldTouched('partnerImage', true)}
                                  error={(props?.touched?.partnerImage && props?.errors?.partnerImage) || invalidImage}
                                />
                                {/* for image preview */}
                                <CCol>
                                  {partnersImagePreview?.length > 0 &&
                                    partnersImagePreview?.map((item, index) => {
                                      return (
                                        <>
                                        <a target='_blank' href={`${item}`}>
                                          <CImage
                                            rounded
                                            thumbnail
                                            className='mt-2 mx-1'
                                            src={`${item}`}
                                            style={{
                                              maxHeight: "80px",
                                              maxWidth: "80px",
                                              alignItems: "center",
                                            }}
                                          />
                                          </a>
                                        </>
                                      )
                                    })}
                                </CCol>
                                {/* for image preview */}
                                {imgArr && imgArr.map((item, key) => {
                                  return (
                                    <div style={{
                                      maxHeight: "80px",
                                      maxWidth: "80px",
                                      alignItems: "center",
                                      display: "inline-flex",
                                      marginRight: "5px",
                                    }}>
                                      <a target='_blank' href={`${imageUrl}${item}`}>
                                      <CImage
                                        rounded
                                        thumbnail
                                        className='mt-2 mx-1'
                                        src={`${imageUrl}${item}`}
                                        style={{
                                          maxHeight: "80px",
                                          maxWidth: "80px",
                                          alignItems: "center",
                                        }}
                                      />
                                      </a>
                                    </div>
                                  )

                                })}
                              </CCol>
                              {/* <span className='text-danger'>{invalidImage}</span> */}

                              {/* <div >
                                    <Dropzone onDrop={onDrop}>
                                    {({getRootProps, getInputProps}) => (
                                        <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        Click me to upload a file!
                                        </div>
                                    )}
                                    </Dropzone>
                                    <span className='text-danger'>{invalidImage}</span>
                                </div> */}
                            </CRow>
                            <CRow>
                              <CCol md="2">
                                <button type="submit" className={'btn btn-success mt-2'} disabled={!isDisable || props.isSubmitting}>Submit</button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={() => navigate('/admin/dashboard')}
                                >
                                  Cancel
                                </button>
                              </CCol>
                              <CCol md="3"></CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>






    </>
  )
}

export default index
