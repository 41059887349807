import React, { useRef, useState, useEffect } from 'react'
import { Formik } from 'formik'
import { useNavigate, Link } from 'react-router-dom'
import { post, get } from 'src/utils/apiManager'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Select from 'react-select'
import { CCard, CCardBody, CCardGroup, CCol, CContainer, CFormLabel, CImage, CRow } from '@coreui/react'
import { DefaultInput } from 'src/components/common/input'
import { validateItem } from 'src/utils/validator'
import Info from '../info/info'
function add() {
  const navigate = useNavigate()
  const formRef = useRef(null)
  const fileRef = useRef(null)
  const [invalidImage, setInvalidImage] = useState('')
  const [isReset, setIsReset] = useState(true)
  const [selecteSizeOption, setSelecteSizeOption] = useState(null)
  const [itemSizeData, setItemSizeData] = useState(null)
  const options = []
  const [selectedItemCategoryOption, setSelectedItemCategoryOption] = useState(null)
  const [itemCategoryData, setItemCategoryData] = useState(null)
  const [disable, setDisable] = useState(false)
  const ItemCategoryoptions = []
  const [itemImagePreview, setItemImagePreview] = useState();

  const getItemSize = () => {
    get(`admin/items/item-size`).then((data) => {
      setItemSizeData(data?.data)
    })
  }
  if (itemSizeData !== null) {
    itemSizeData?.map((item, key) => {
      options?.push({ label: item?.itemSize_EN, value: item?._id })
    })
  }
  const getItemCategory = () => {
    get(`admin/items/item-category`).then((data) => {
      setItemCategoryData(data?.data)
    })
  }
  if (itemCategoryData !== null) {
    itemCategoryData?.map((item, key) => {
      ItemCategoryoptions?.push({ label: item?.itemName_EN, value: item?._id })
    })
  }
  useEffect(() => {
    getItemSize()
    getItemCategory()
  }, [])

  const submit = async (values) => {
    if (values?.price <= 0 || values?.itemSize?.length == 0) {
      if (values?.price <= 0) {
        formRef.current.setFieldValue('price', '')
      } else {
        toast.error("Item size required")
      }
    } else {
      setDisable(true)
      var SIZE = []
      values.itemSize.forEach((item, key) => {
        SIZE.push(item?.value)
      })

      let formData = new FormData()
      formData.append('itemName_EN', values?.itemName_EN)
      formData.append('itemName_TH', values?.itemName_TH)
      formData.append('price', values?.price)
      formData.append('image', values?.image)
      formData.append('itemCategory', values?.itemCategory)
      formData.append('itemSize', SIZE)
      formData.append('itemQuota', values?.itemQuota)
      formData.append('offer', values?.offer)
      post(`admin/items/add-item`, formData, 1).then((data) => handleResponse(data))
    }
  }
  const handleResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setTimeout(() => {
        navigate('/admin/items')
        formRef.current.resetForm()
        fileRef.current.value = null
        setSelecteSizeOption('')
        setSelectedItemCategoryOption('')
      }, 800)
    } else {
      setDisable(false)
      toast.error(data?.message)
    }

  }
  const onClickReset = () => {
    console.log('this')
    console.log(this)
    // Reset Button Function
    // formRef.current.setFieldValue('vanNo', '')
  }

  return (
    <>
      <CContainer>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>New Item</h1>
                        </CCol>
                        <CCol md="1">
                          <Link
                            className={'btn btn-success mt-2'}
                            style={{ background: '#d3dc40', border: '#d3dc40' }}
                            to={{ pathname: `/admin/items` }}
                          >
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          itemName_EN: '',
                          itemName_TH: '',
                          price: '',
                          image: '',
                          itemCategory: '',
                          itemQuota: '',
                          offer: '',
                        }}
                        validationSchema={validateItem}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)
                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props.handleSubmit}>
                            <CRow>
                              <CCol md={6}>
                                <CFormLabel>Item Name (EN)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Item Name (EN)'}
                                  value={props?.values?.itemName_EN}
                                  onChange={props?.handleChange('itemName_EN')}
                                  onBlur={() => props?.setFieldTouched('itemName_EN', true)}
                                  error={props?.touched?.itemName_EN && props?.errors?.itemName_EN}
                                />
                                <CFormLabel>Price</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Price'}
                                  value={props?.values?.price}
                                  onChange={props?.handleChange('price')}
                                  onBlur={() => props?.setFieldTouched('price', true)}
                                  error={props?.touched?.price && props?.errors?.price}
                                />
                                <CFormLabel>Item Size</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <Select
                                  isMulti={true}
                                  defaultValue={selecteSizeOption}
                                  onChange={(val) => {
                                    setSelecteSizeOption(val)
                                    props?.setFieldValue('itemSize', val)
                                  }}
                                  options={options}
                                  value={selecteSizeOption}
                                  placeholder={'Select size'}
                                  name="Item Size"
                                />
                                {selecteSizeOption == undefined ? (
                                  <span className="text-danger">{props?.errors?.itemSize}</span>
                                ) : (
                                  ''
                                )}
                                <br />
                                <CFormLabel>Image</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <Info message="100x120"/>
                                <DefaultInput
                                  type={'file'}
                                  placeholder={'Image'}
                                  accept="image/*"
                                  refr={fileRef}
                                  onChange={(e) => {
                                    const imageFile = e?.target?.files[0]
                                    if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                      setInvalidImage('Please select valid image')
                                    } else {
                                      setInvalidImage('')
                                    }
                                    //for image preview
                                    const reader = new FileReader();
                                    reader?.addEventListener("load", () => setItemImagePreview(reader?.result));
                                    reader?.readAsDataURL(e?.target?.files[0]);
                                    //for image preview
                                    props?.setFieldValue('image', e?.target?.files[0])
                                  }}
                                  onBlur={() => props?.setFieldTouched('image', true)}
                                  error={
                                    (props?.touched?.image && props?.errors?.image) || invalidImage
                                  }
                                />
                                {/* for image preview */}
                                {itemImagePreview?.length > 0 ?
                                  <>
                                    <CCol>
                                      <CImage
                                        rounded
                                        thumbnail
                                        className="mt-2"
                                        src={`${itemImagePreview}`}
                                        style={{
                                          maxHeight: '80px',
                                          maxWidth: '80px',
                                          alignItems: 'center',
                                        }}
                                      />
                                    </CCol>
                                  </>
                                  : ''}
                                {/* for image preview */}
                              </CCol>
                              <CCol md="6">
                                <CFormLabel>Item Name (TH)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Item Name (TH)'}
                                  value={props?.values?.itemName_TH}
                                  onChange={props?.handleChange('itemName_TH')}
                                  onBlur={() => props?.setFieldTouched('itemName_TH', true)}
                                  error={props?.touched?.itemName_TH && props?.errors?.itemName_TH}
                                />

                                <CFormLabel>Offer (In %)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Offer'}
                                  value={props?.values?.offer}
                                  onChange={props?.handleChange('offer')}
                                  onBlur={() => props?.setFieldTouched('offer', true)}
                                  error={props?.touched?.offer && props?.errors?.offer}
                                />
                                <CFormLabel>Item Quota</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Item Quota'}
                                  value={props?.values?.itemQuota}
                                  onChange={props?.handleChange('itemQuota')}
                                  onBlur={() => props?.setFieldTouched('itemQuota', true)}
                                  error={props?.touched?.itemQuota && props?.errors?.itemQuota}
                                />
                                <CFormLabel>Item Category</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <Select
                                  defaultValue={selectedItemCategoryOption}
                                  onChange={(val) => {
                                    setSelectedItemCategoryOption(val)
                                    props?.setFieldValue('itemCategory', val.value)
                                  }}
                                  options={ItemCategoryoptions}
                                  value={selectedItemCategoryOption}
                                  placeholder={'Select Item category'}
                                  name="Item Category"
                                />
                                {selectedItemCategoryOption == undefined ? (
                                  <span className="text-danger">{props?.errors?.itemCategory}</span>
                                ) : (
                                  ''
                                )}
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"
                                  className={'btn btn-success mt-2'}
                                  disabled={disable || props?.isSubmitting}
                                >
                                  Submit
                                </button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={() => onClickReset(props.isSubmitting)}
                                >
                                  Reset
                                </button>
                              </CCol>
                              <CCol md="3"></CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default add
