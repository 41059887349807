import React, { useState, useRef, useEffect } from 'react'
import { Formik } from 'formik'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { put, get } from 'src/utils/apiManager'
import { ImageUrl } from 'src/config/config'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormLabel,
  CRow,
  CImage,
  CFormSelect,
  CFormFeedback,
} from '@coreui/react'
import { DefaultInput } from 'src/components/common/input'
// Change karna hai ye validation for saprate karni hai
import { serivceProcessEdit } from 'src/utils/validator'
import { Ckeditor } from '../Template/Ckeditor'
import Info from '../info/info'

function editSerivceProcess() {
  const navigate = useNavigate()
  const { state } = useLocation()
  // console.log(state)
  const formRef = useRef(null)
  const fileRef = useRef(null)
  const [description, setDescription] = useState('')
  const [services, setServices] = useState([]);
  const [imageUrl, setimageUrl] = useState()
  const [isDisable, setIsDisable] = useState(false)
  const [serivceProcessImagePreview, setSerivceProcessImagePreview] = useState()

  const submit = async (values) => {
    let formData = new FormData();

    // Append each field manually to the FormData object
    formData.append('id', state?._id);  // Assuming id is a simple field
    formData.append('title_en', values?.title_en ? values?.title_en : state?.title_en);  // Default to empty string if undefined
    formData.append('title_th', values?.title_th ? values?.title_th : state?.title_th,);  // Default to empty string if undefined
    formData.append('image', values?.image ? values?.image : state?.image,);  // If image is a File, append it as a File object
    formData.append('serviceId', values?.serviceId ? values?.serviceId : state?.serviceId,);  // If image is a File, append it as a File object
    formData.append('description_en', values?.description_en ? values?.description_en : state?.description_en);  // Default to empty string if undefined
    formData.append('description_th', values?.description_th ? values?.description_th : state?.description_th);
    put(`admin/SerivceProcess/edit-serivce-process`, formData, 1).then((data) => {
      handleResponse(data)
    })
  }
  useEffect(() => {
    getService()
  }, [])
  const getService = () => {
    get(`admin/subscription/services`, 0).then((data) => {
      if (data.status) {
        setServices(data.data);
      }
    })
  }
  const handleResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setTimeout(() => {
        navigate('/admin/service-process')
      }, 800)
    }
    // formRef.current.resetForm()
    // fileRef.current.value = null
  }
  useEffect(() => {
    if (state != null) {
      formRef.current.setFieldValue('title_en', state?.title_en)
      formRef.current.setFieldValue('title_th', state?.title_th)
      formRef.current.setFieldValue('serviceId', state?.serviceId)
      formRef.current.setFieldValue('description_en', state?.description_en)
      formRef.current.setFieldValue('description_th', state?.description_th)
      if (state?.image) setimageUrl(`${ImageUrl}/${state?.image}`)
    }
  }, [state])
  return (
    <>
      <CContainer>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>Edit Serivce Process</h1>
                        </CCol>
                        <CCol md="1">
                          <Link
                            className={'btn btn-success mt-2'}
                            style={{ background: '#d3dc40', border: '#d3dc40' }}
                            to={{ pathname: `/admin/service-process` }}
                          >
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          title_en: '',
                          title_th: '',
                          serviceId: '',
                          image: '',
                          description_en: '',
                          description_th: '',
                        }}
                        validationSchema={serivceProcessEdit}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)
                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props.handleSubmit}>
                            <CFormLabel>Title(English)</CFormLabel>
                            <span className="text-danger" style={{ marginLeft: '5px' }}>
                              *
                            </span>
                            <DefaultInput
                              type={'text'}
                              placeholder={'Title(English)'}
                              // name={'Title (English)'}
                              id={'title_en'}
                              value={props?.values?.title_en ? props?.values?.title_en : ''}
                              onChange={props?.handleChange('title_en')}
                              onBlur={() => props?.setFieldTouched('title_en', true)}
                              error={props?.touched?.title_en && props.errors.title_en}
                              onKeyUp={() => {
                                if (state?.title_en == props?.values?.title_en) setIsDisable(false)
                                else setIsDisable(true)
                              }}
                            />
                            <CFormLabel>Title (Thai)</CFormLabel>
                            <span className="text-danger" style={{ marginLeft: '5px' }}>
                              *
                            </span>
                            <DefaultInput
                              type={'text'}
                              placeholder={'Title (Thai)'}
                              // name={'Title (Thai)'}
                              id={'title_th'}
                              value={props?.values?.title_th ? props?.values?.title_th : ''}
                              onChange={props?.handleChange('title_th')}
                              onBlur={() => props?.setFieldTouched('title_th', true)}
                              error={props?.touched?.title_th && props?.errors?.title_th}
                              onKeyUp={() => {
                                if (state?.title_th == props?.values?.title_th) setIsDisable(false)
                                else setIsDisable(true)
                              }}
                            />
                            <CFormLabel>Service</CFormLabel>
                            <span className="text-danger" style={{ marginLeft: '5px' }}>
                              *
                            </span>
                            <CFormSelect aria-label="Default select example"
                              onChange={props?.handleChange('serviceId')}
                              onBlur={() => props?.setFieldTouched('serviceId', true)}
                              value={props?.values.serviceId}
                              error={props?.touched?.serviceId && props?.errors?.serviceId}>
                              <option value={""}>Select service</option>

                              {services.map((v) => {
                                return <option value={v._id}>{v.serviceName_EN}</option>
                              })}
                            </CFormSelect>
                            {props.touched.serviceId && props.errors.serviceId && (
                              <CFormFeedback style={{ color: "#e55353" }}>{props.errors.serviceId}</CFormFeedback>
                            )}
                            <CFormLabel>Image</CFormLabel>
                            <span className="text-danger" style={{ marginLeft: '5px' }}>
                              *
                            </span>
                            <Info message="100x120"/>

                            <DefaultInput
                              type={'file'}
                              placeholder={'Image'}
                              // name={'Image'}
                              accept="image/*"
                              refr={fileRef}
                              onChange={(e) => {
                                const imageFile = e?.target?.files[0]
                                if (state?.image == imageFile.name) setIsDisable(false)
                                else setIsDisable(true)
                                //for image preview
                                const reader = new FileReader()
                                reader?.addEventListener('load', () =>
                                  setSerivceProcessImagePreview(reader?.result),
                                )
                                reader?.readAsDataURL(e?.target?.files[0])
                                //for image preview
                                props.setFieldValue('image', e?.target?.files[0])
                              }}
                              onBlur={() => props?.setFieldTouched('image', true)}
                              error={props?.touched?.image && props?.errors?.image}
                            />
                            {/* for image preview */}
                            {serivceProcessImagePreview?.length > 0 ? (
                              <>
                                <CCol>
                                  <CImage
                                    rounded
                                    thumbnail
                                    className="mt-2"
                                    src={`${serivceProcessImagePreview}`}
                                    style={{
                                      maxHeight: '80px',
                                      maxWidth: '80px',
                                      alignItems: 'center',
                                    }}
                                  />
                                </CCol>
                              </>
                            ) : (
                              ''
                            )}
                            {/* for image preview */}
                            <CFormLabel>Description(English)</CFormLabel>
                            <span className="text-danger" style={{ marginLeft: '5px' }}>
                              *
                            </span>
                            <Ckeditor
                              setDescription={setDescription}
                              description={props?.values?.description_en || ''}
                              value={
                                props?.values?.description_en ? props?.values?.description_en : ''
                              }
                              onChange={(val) => {
                                // setDescription(val)
                                // console.log(val,"DESCRIPT");
                                if (state.description_en == val) setIsDisable(false)
                                else setIsDisable(true)
                                props?.setFieldValue('description_en', val)
                              }}
                              onBlur={() => props?.setFieldTouched('description_en', true)}
                              error={
                                props?.touched?.description_en && props?.errors?.description_en
                              }
                            />
                            <CFormLabel>Description(Thai)</CFormLabel>
                            <span className="text-danger" style={{ marginLeft: '5px' }}>
                              *
                            </span>
                            <Ckeditor
                              setDescription={setDescription}
                              description={props?.values?.description_th}
                              value={
                                props?.values?.description_th ? props?.values?.description_th : ''
                              }
                              onChange={(val) => {
                                // setDescription(val)
                                // console.log(val,"DESCRIPT");
                                if (state.description_th == val) setIsDisable(false)
                                else setIsDisable(true)
                                props?.setFieldValue('description_th', val)
                              }}
                              onBlur={() => props?.setFieldTouched('description_th', true)}
                              error={
                                props?.touched?.description_th && props?.errors?.description_th
                              }
                            />
                            <div
                              style={{
                                marginTop: '10px',
                                justifyContent: 'center',
                              }}
                            >
                              {imageUrl && (
                                <CImage
                                  rounded
                                  thumbnail
                                  src={imageUrl}
                                  style={{
                                    maxHeight: '80px',
                                    maxWidth: '80px',
                                    alignItems: 'center',
                                  }}
                                />
                              )}
                            </div>
                            <CRow></CRow>
                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"
                                  className={'btn btn-success mt-2'}
                                // disabled={!isDisable || props?.isSubmitting}
                                >
                                  Submit
                                </button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={() => navigate('/admin/service-process')}
                                >
                                  Cancel
                                </button>
                              </CCol>
                              <CCol md="3"></CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default editSerivceProcess
