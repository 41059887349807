import React, { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import { useNavigate, Link } from 'react-router-dom'
import { post, get } from 'src/utils/apiManager';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormLabel,
  CImage,
  CRow,
} from '@coreui/react'
import { DefaultInput, DefaultMobileInput } from 'src/components/common/input'
import { validateDriver } from 'src/utils/validator'
import Select from 'react-select'
import { ErrorText } from "src/components/common/commonError";
import Info from '../info/info';

function addDriver() {
  const navigate = useNavigate()
  const fileRef = useRef(null)
  const formRef = useRef(null)
  const [invalidImage, setInvalidImage] = useState('')
  const [selectedVanOption, setSelectedVanOption] = useState(null);
  const [selectedCityOption, setSelectedCityOption] = useState(null);
  const [selectedAreaOption, setSelectedAreaOption] = useState(null);
  const [vanData, setvanData] = useState(null);
  const [cityData, setCityData] = useState(null);
  const [areaData, setAreaData] = useState(null);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [passVisibility, setPassVisibility] = useState(false)
  const [passwordType, setPasswordType] = useState("password")

  const [driverImagePreview, setDriverImagePreview] = useState();
  const vanOptions = [];
  const cityOptions = [];
  const options = [];
  const getVanData = () => {
    get(`admin/Driver/van-no`).then((data) => { setvanData(data.data) })
  }
  if (vanData !== null) {
    vanData?.map((item, key) => {
      vanOptions?.push({ label: item?.vanNo, value: item?._id })
    })
  }
  // const getCityData = () => {
  //   get(`admin/Driver/city`).then((data) => {
  //     setCityData(data.data)
  //   })
  // }
  // if (cityData !== null) {
  //   cityData.map((item, key) => {
  //     cityOptions.push({ label: item.cityName_EN, value: item._id })
  //   })
  // }
  const getAreaData = () => {
    // get(`admin/Driver/area-name?city_id=${selectedCityOption.value}`).then((data) => {
    get(`admin/Driver/area-name`).then((data) => {
      setAreaData(data?.data)
    })
  }
  if (areaData !== null) {
    areaData?.map((item, key) => {
      options?.push({ label: item?.areaName_EN, value: item?._id })
    })
  }
  useEffect(() => {
    getVanData();
    // getCityData();
  }, [])

  useEffect(() => {
    if (passVisibility) {
      setPasswordType("text")
    } else {
      setPasswordType("password")
    }
  }, [passVisibility]);

  const submit = async (values) => {
    setButtonDisable(true)
    // var AREA = []
    // values.areaName.forEach((item, key) => {
    //   AREA.push(item.value)
    // })
    // console.log(AREA)
    let formData = new FormData()
    formData.append('username', values?.username)
    formData.append('email', values?.email)
    formData.append('mobile', values?.mobile)
    formData.append('image', values?.image)
    formData.append('vanNo', selectedVanOption?.value)
    // formData.append('city_id', selectedCityOption?.value)
    // formData.append('areaName', AREA)
    formData.append('areaName', selectedAreaOption?.value)
    formData.append('password', values?.password)
    // console.log(driverData, "area data after submted")
    post(`admin/Driver/add-driver`, formData, 1).then((data) => {
      handleResponse(data)
    })
  }
  const handleResponse = (data) => {
    console.log("data data", data)
    if (data.status == true) {
      toast.success(data.message)
      navigate('/admin/driver')
      // setTimeout(() => {
      // }, 800)
    } else {

      setButtonDisable(false)
      toast.error(data?.message)
    }
    formRef?.current?.resetForm()
    setSelectedVanOption('')
    setSelectedAreaOption('')
    setDriverImagePreview('')
  }
  const onClickReset = () => {
    // Reset Button Function
    formRef.current.setFieldValue('username', '')
    setSelectedVanOption('')
    setSelectedAreaOption('')

  };
  useEffect(() => {
    getAreaData();
  }, [])
  // useEffect(() => {
  //   if (selectedCityOption) {
  //     getAreaData();
  //   }
  // }, [selectedCityOption])
  return (
    <>
      <CContainer >
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>New Driver</h1>
                        </CCol>
                        <CCol md="1">
                          <Link className={'btn btn-success mt-2'} style={{ background: '#d3dc40', border: '#d3dc40' }} to={{ pathname: `/admin/driver` }}>
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          username: '',
                          email: '',
                          image: '',
                          mobile: '',
                          password: '',
                          vanNo: '',
                          // city_id: '',
                          areaName: ''
                        }}
                        validationSchema={validateDriver}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)

                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props?.handleSubmit}>
                            {/* {console.log(props)} */}
                            <CRow>
                              <CCol md={6}>
                                <CFormLabel>Name</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Name'}
                                  // name={'Name'}
                                  id={'username'}
                                  maxLength="70"
                                  value={props?.values?.username}
                                  onChange={props?.handleChange('username')}
                                  onBlur={() => props?.setFieldTouched('username', true)}
                                  error={props?.touched?.username && props?.errors?.username}
                                />
                                <DefaultMobileInput
                                  type={'text'}
                                  placeholder={'Mobile'}
                                  name={'Mobile'}
                                  id={'mobile'}
                                  minLength={7}
                                  maxLength={15}
                                  value={props?.values?.mobile}
                                  onChange={(val) => props?.setFieldValue('mobile', val || '')}
                                  onBlur={() => props?.setFieldTouched('mobile', true)}
                                  error={props?.touched?.mobile && props?.errors?.mobile}
                                />
                                <CFormLabel>Van No</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <Select
                                  className='mt-2'
                                  defaultValue={selectedVanOption}
                                  onChange={(val) => {
                                    setSelectedVanOption(val)
                                    props?.setFieldValue("vanNo", val?.value)
                                  }}
                                  options={vanOptions}
                                  placeholder={"Select van no"}
                                  value={selectedVanOption}
                                  name="Van Number" />
                                {selectedVanOption == undefined ? <span className='text-danger'>{props?.errors?.vanNo}</span> : ''}<br />
                                <CFormLabel>Image</CFormLabel>
                                {/* <span className='text-danger' style={{ marginLeft: '5px' }}>*</span> */}
                                <span> </span>
                                <Info message="100x120"/>
                                <DefaultInput
                                  type={'file'}
                                  placeholder={'Image'}
                                  // name={'Image'}
                                  // accept="image/*"
                                  accept="image/png, image/gif, image/jpeg, image/jpg"
                                  refr={fileRef}
                                  onChange={(e) => {
                                    const imageFile = e?.target?.files[0];
                                    if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                      setInvalidImage("Please select valid image")
                                    }
                                    else {
                                      setInvalidImage('')
                                    }
                                    //for image preview
                                    const reader = new FileReader();
                                    reader?.addEventListener("load", () => setDriverImagePreview(reader?.result));
                                    reader?.readAsDataURL(e?.target?.files[0]);
                                    //for image preview
                                    props?.setFieldValue('image', e?.target?.files[0])
                                  }}
                                  onBlur={() => props?.setFieldTouched('image', true)}
                                  error={(props?.touched?.image && props?.errors?.image) || invalidImage}
                                />
                                {/* for image preview */}
                                {driverImagePreview?.length > 0 ?
                                  <>
                                    <CCol>
                                      <CImage
                                        rounded
                                        thumbnail
                                        className="mt-2"
                                        src={`${driverImagePreview}`}
                                        style={{
                                          maxHeight: '80px',
                                          maxWidth: '80px',
                                          alignItems: 'center',
                                        }}
                                      />
                                    </CCol>
                                  </>
                                  : ''}
                                {/* for image preview */}
                              </CCol>
                              <CCol md={6}>
                                <CFormLabel>Email</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'email'}
                                  placeholder={'Email'}
                                  // name={'Email'}
                                  id={'email'}
                                  value={props?.values?.email}
                                  // value={this.state.name}
                                  onChange={props?.handleChange('email')}
                                  onBlur={() => props?.setFieldTouched('email', true)}
                                  error={props?.touched?.email && props?.errors?.email}
                                />
                                {/* <CFormLabel>City </CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <Select
                                  className='mt-2'
                                  defaultValue={selectedCityOption}
                                  onChange={(val) => {
                                    setSelectedCityOption(val)
                                    props?.setFieldValue("city_id", val.value)
                                  }}
                                  options={cityOptions}
                                  placeholder={"Select cites"}
                                  value={selectedCityOption}
                                  name="City" />
                                {selectedCityOption == undefined ? <span className='text-danger mt-2'>{props?.errors?.city_id}</span> : ''}<br /> */}

                                <CFormLabel>Area Name</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <Select
                                  // isMulti
                                  // className='mt-2'
                                  defaultValue={selectedAreaOption}
                                  onChange={(val) => {
                                    setSelectedAreaOption(val)
                                    props?.setFieldValue("areaName", val?.value)
                                    // props?.setFieldValue("areaName", val)
                                  }}
                                  options={options}
                                  placeholder={"Select Area Name"}
                                  value={selectedAreaOption}
                                  name="Area Name" />
                                {selectedAreaOption == undefined ? <span className='text-danger'>{props?.errors?.areaName}</span> : ''}
                                <br />
                                <CFormLabel>Password</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>

                                <div className="input-group">
                                  <input
                                    type={passwordType}
                                    autoComplete="off"
                                    className="form-control"
                                    placeholder={"Current Password"}
                                    id={"password"}
                                    value={props?.values?.password}
                                    onChange={props?.handleChange('password')}
                                    onBlur={() => props?.setFieldTouched('password', true)}
                                  />
                                  <button
                                    className="btn btn-outline-secondary"
                                    type="button"
                                    onClick={(e) => setPassVisibility(!passVisibility)}
                                    style={{ backgroundColor: '#d8dbe0' }}
                                  >
                                    {passwordType == "password" ? (
                                      <i style={{ color: '#2C384A' }} className="fa-solid fa-eye-slash"></i>
                                    ) : <i style={{ color: '#2C384A' }} className="fa-solid fa-eye"></i>}
                                  </button>
                                </div>
                                {props?.touched?.password && props?.errors?.password ? <ErrorText title={props?.errors?.password} /> : null}

                                {/* <DefaultInput
                                  type={'password'}
                                  placeholder={'password'}
                                  // name={'Password'}
                                  id={'password'}
                                  maxLength={8}
                                  value={props?.values?.password}
                                  onChange={props?.handleChange('password')}
                                  onBlur={() => props?.setFieldTouched('password', true)}
                                  error={props?.touched?.password && props?.errors?.password}
                                /> */}
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol md={6}>
                              </CCol>
                              <CCol md={6}>

                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol md={6}>

                              </CCol>
                              <CCol md={6}>
                                {/* <DefaultTextarea
                            placeholder={"Address"}
                            name={"address"}
                            id={"address"}
                            maxLength={50}
                            value={props?.values?.address}
                            onChange={props?.handleChange("address")}
                            onBlur={() =>
                              props?.setFieldTouched("address", true)
                            }
                            error={ props?.touched?.address && props?.errors?.address}
                          /> */}
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"

                                  className={'btn btn-success mt-2'}
                                  disabled={props?.isSubmitting}
                                >
                                  Submit
                                </button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={onClickReset}
                                >
                                  Reset
                                </button>
                              </CCol>
                              <CCol md="3"></CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>

    </>
  )
}

export default addDriver