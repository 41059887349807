import React, { useRef, useState, useEffect } from 'react';
import { Formik } from 'formik';
import { useLocation, useNavigate, Link, useParams } from 'react-router-dom'
import { get, put } from 'src/utils/apiManager';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Select from 'react-select'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormLabel,
  CRow,
  CImage,
} from '@coreui/react'
import { DefaultInput, DefaultTextarea } from 'src/components/common/input'
import { validateOfferEditValidation } from 'src/utils/validator'
import { components } from "react-select";
import MySelect from './MySelect';
import makeAnimated from "react-select/animated";
import moment from 'moment';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { ImageUrl } from 'src/config/config'
import Info from '../info/info';

function edit() {
  const navigate = useNavigate();
  const { state } = useLocation()
  const formRef = useRef(null);
  const fileRef = useRef(null);
  const [isDisable, setIsDisable] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [toMinimum, setToMinimum] = useState(null);
  const [fromMax, setFromMax] = useState(null);
  const [defaultToDate, setDefaultToDate] = useState();
  const [defaultFromDate, setDefaultFromDate] = useState();
  const [serviceType, setServiceType] = useState();
  const [offerType, setOfferType] = useState();
  const [servicesOptions, setServicesOptions] = useState([]);
  const [selectedServices, setSelectedServices] = useState();
  const [invalidImageEN, setInvalidImageEN] = useState('');
  const [invalidImageTH, setInvalidImageTH] = useState('');
  const [imageUrlEN, setimageUrlEN] = useState()
  const [imageUrlTH, setimageUrlTH] = useState()
  const { id } = useParams()
  const { service_type } = useParams()
  const [eNImagePreview, setENImagePreview] = useState();
  const [tHImagePreview, setTHImagePreview] = useState();

  const serviceTypeOptions = [
    { value: '0', label: "Regular" },
    { value: '1', label: "Package" },
  ]

  const offerTypeOptions = [
    { value: "percent", label: "Percentage(%)" },
    { value: "flat", label: "FLAT" },
    { value: "freeDelivery", label: "Free Delivery" }
  ]

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props?.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props?.label}</label>
        </components.Option>
      </div>
    );
  };

  const MultiValue = props => (
    <components.MultiValue {...props}>
      <span>{props?.data?.label}</span>
    </components.MultiValue>
  );

  const animatedComponents = makeAnimated();

  const handleDateChange = (event, type) => {
    if (type == 'fromDate') {
      setFromDate(event?.$d)
      const minTodate = toMinDate(event?.$d)
      setToMinimum(minTodate)
    } else {
      setToDate(event?.$d)
      const maxfrom = fromMaxDate(event?.$d)
      setFromMax(maxfrom)
    }
  }

  const fromMaxDate = (date) => {
    const prevDate = moment(date).add(-1, 'days');
    const d = prevDate?._d
    return d
  }

  const toMinDate = (date) => {
    const tomorrow = moment(date).add(+1, 'days');
    const d = tomorrow?._d
    return d
  }

  const getServices = () => {

    get(`admin/offer-promotion/get-edit?_id=${atob(id)}&service_type=${atob(service_type)}`).then((data) => {
      const selService = []
      data?.data?.map((item, key) => {
        if (item?.service_type == 1) {
          const setoffer = item?.package_id?.map((data, key) => {
            selService?.push({ label: data?.packageName_EN, value: data?._id })
            return { label: item?.packageName_EN, value: item?._id }
          })
          setSelectedServices(selService)
          setServicesOptions(setoffer)
        } else {
          const setoffer = item?.regular_id?.map((data, key) => {
            selService?.push({ label: data?.serviceName_EN, value: data?._id })
            return { label: data?.serviceName_EN, value: data?._id }
          })
          setSelectedServices(selService)
          setServicesOptions(setoffer)
        }
      })
    })
  }

  const getServicesList = (type) => {
    get(
      `/admin/offer-promotion/services?service_type=${type}`
    ).then((res) => {
      handleResponse(res, type)
    });
  };

  const handleResponse = (res, type) => {
    const services = []
    if (type == '0') {
      res?.data?.map((data, index) => {
        data?.services?.map((data, i) => {
          const regularService = {
            value: data?._id,
            label: data?.serviceName_EN
          }
          services.push(regularService)
        })
      })
      setServicesOptions(services)
    } else {
      res?.data?.map((data) => {

        const packageServices = {
          value: data?._id,
          label: data?.packageName_EN
        }
        services.push(packageServices)
      })
      setServicesOptions(services)
    }
  }

  const submit = (values) => {
    if (values?.minCart <= 0 || values?.quantity <= 0 || values?.offerAmount <= 0 || values?.services?.length == 0) {
      if (values?.quantity <= 0) {
        formRef.current.setFieldValue('quantity', '')
      } else if (values?.minCart <= 0) {
        formRef.current.setFieldValue('minCart', '')
      } else if (values?.offerAmount <= 0) {
        formRef.current.setFieldValue('offerAmount', '')
      } else {
        toast.error("Services required")
      }
    } else {
      if (values?.services.length > 0) {
        if (invalidImageEN?.length === 0 && invalidImageTH?.length === 0) {
          let services_Ids = [];
          values?.services?.map((data) => {
            services_Ids?.push(data?.value)
          })
          const demoService = []
          selectedServices?.map((item) => {
            demoService?.push(item?.value)
          })

          let formData = new FormData();
          formData.append('service_type', values?.serviceType);
          formData.append('title_EN', values?.title_en);
          formData.append('title_TH', values?.title_th);
          formData.append('offer_type', values?.offerType);
          formData.append('offerQuantity', values?.quantity);
          formData.append('offerAmount', values?.offerAmount);
          formData.append('minimumCartValue', values?.minCart);
          formData.append('couponCode', values?.code);
          formData.append('from_date', fromDate ? fromDate : state?.from_date);
          formData.append('to_date', toDate ? toDate : state?.to_date);
          formData.append('description_EN', values?.description_EN);
          formData.append('description_TH', values?.description_TH);
          formData.append('image_EN', values?.image_EN);
          formData.append('image_TH', values?.image_TH);
          if (values?.serviceType == "0") {
            formData.append('regular_id', services_Ids[0] == undefined ? demoService : services_Ids);
          } else {
            formData.append('package_id', services_Ids[0] == undefined ? demoService : services_Ids);
          }

          put(
            `/admin/offer-promotion/edit-offer?_id=${state?._id}`, formData, 1
          ).then((res) => {
            handleSubmitResponse(res);
          })
        } else {
          return
        }
      } else {
        toast.error("Fill the required fields")
      }
    }

  }

  const handleSubmitResponse = (res) => {
    if (res?.status == true) {
      toast.success(res?.message)
      setTimeout(() => {
        navigate('/admin/offer')
      }, 800)
    } else {
      toast.error(res?.message)
    }
  }

  useEffect(() => {
    getServices()
    getServicesList(state?.service_type)
    if (state?.service_type == 0) {
      setServiceType({ value: '0', label: "Regular" })
    } else {
      setServiceType({ value: '1', label: "Package" })
    }

    if (state?.offer_type == 'flat') {
      setOfferType({ value: "flat", label: "FLAT" })
    } else if (state?.offer_type == 'freeDelivery') {
      setOfferType({ value: "freeDelivery", label: "Free Delivery" })
    } else {
      setOfferType({ value: "percent", label: "Percentage(%)" })
    }

    if (state != null) {
      setDefaultToDate(state?.to_date)
      setDefaultFromDate(state?.from_date)
      formRef.current.setFieldValue('serviceType', state?.service_type)
      state?.service_type == 0 ? formRef.current.setFieldValue('services', state?.regular_id)
        : formRef.current.setFieldValue('services', state?.package_id)
      formRef.current.setFieldValue('offerType', state?.offer_type)
      formRef.current.setFieldValue('offerAmount', state?.offerAmount)
      formRef.current.setFieldValue('minCart', state?.minimumCartValue)
      formRef.current.setFieldValue('fromDate', state?.from_date)
      formRef.current.setFieldValue('todate', state?.to_date)
      formRef.current.setFieldValue('code', state?.couponCode)
      formRef.current.setFieldValue('title_en', state?.title_EN)
      formRef.current.setFieldValue('title_th', state?.title_TH)
      formRef.current.setFieldValue('quantity', state?.offerQuantity)
      formRef.current.setFieldValue('description_EN', state?.description_EN)
      formRef.current.setFieldValue('description_TH', state?.description_TH)
      if (state?.image_EN) setimageUrlEN(`${ImageUrl}${state?.image_EN}`)
      if (state?.image_TH) setimageUrlTH(`${ImageUrl}${state?.image_TH}`)
    }
  }, [state])

  return (
    <>
      <CContainer >
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>Edit Offer</h1>
                        </CCol>
                        <CCol md="1">
                          <Link className={'btn btn-success mt-2'} style={{ background: '#d3dc40', border: '#d3dc40' }} to={{ pathname: `/admin/offer` }}>
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          title_th: '',
                          offerAmount: '',
                          title_en: '',
                          serviceType: '',
                          services: '',
                          offerType: '',
                          minCart: '',
                          fromDate: '',
                          todate: '',
                          code: '',
                          quantity: '',
                          description_EN: '',
                          description_TH: '',
                          image_EN: '',
                          image_TH: '',
                        }}
                        validationSchema={validateOfferEditValidation}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)
                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props?.handleSubmit}>
                            <CRow>
                              <CCol md={6}>
                                <CFormLabel>Title (EN)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={"text"}
                                  placeholder={"Title (EN)"}
                                  value={props?.values?.title_en}
                                  onChange={props?.handleChange("title_en")}
                                  onKeyUp={() => {
                                    if (state?.title_EN == props?.values?.title_en) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                                {props?.values?.title_en == '' && props?.touched?.title_en == true ? <p className='text-danger mt-2'>{props?.errors?.title_en}</p> : ''}

                                <CFormLabel>Offer Amount</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={"number"}
                                  placeholder={"Offer Amount"}
                                  value={props.values.offerAmount}
                                  onChange={props?.handleChange("offerAmount")}
                                  onKeyUp={() => {
                                    if (state?.offerAmount == props.values.offerAmount)
                                      setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                                {props?.values?.offerAmount == '' && props?.touched?.offerAmount == true ? <p className='text-danger mt-2'>{props?.errors?.offerAmount}</p> : ''}
                                <CFormLabel>Service Type</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <Select
                                  defaultValue={serviceType}
                                  onChange={(val) => {
                                    setServiceType(val)
                                    if (val == serviceType) setIsDisable(false)
                                    else setIsDisable(true)
                                    setSelectedServices('')
                                    props?.setFieldValue("serviceType", val?.value)
                                    getServicesList(val?.value);
                                  }}
                                  options={serviceTypeOptions}
                                  value={serviceType}
                                  placeholder={"Service Type"}
                                  name={"Service Type"}
                                  onKeyUp={() => {
                                    if (state?.serviceType == props?.values?.serviceType)
                                      setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                                {props?.values?.serviceType == null || props?.values?.serviceType == '' && props?.touched?.serviceType == true ? <p className='text-danger mt-2'>{props?.errors?.serviceType}</p> : ''}

                                <div><CFormLabel>Services</CFormLabel>
                                  <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                  <MySelect
                                    defaultValue={selectedServices}
                                    options={servicesOptions}
                                    isMulti
                                    placeholder={"Services"}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{ Option, MultiValue, animatedComponents }}
                                    onChange={(val) => {
                                      setSelectedServices(val)
                                      if (val == selectedServices) setIsDisable(false)
                                      else setIsDisable(true)
                                      props?.setFieldValue('services', val)
                                    }}
                                    allowSelectAll={true}
                                    value={selectedServices}
                                  /></div>
                                {selectedServices?.length == 0 ? <p className='text-danger mt-2' >Services are required</p> : ''}

                                <DefaultTextarea
                                  placeholder={'Description (EN)'}
                                  name={'Description (EN)'}
                                  id={'description_EN'}
                                  value={props?.values?.description_EN}
                                  onChange={props?.handleChange('description_EN')}
                                  onBlur={() => props?.setFieldTouched('description_EN', true)}
                                  error={props?.touched?.description_EN && props?.errors?.description_EN}
                                  onKeyUp={() => {
                                    if (state?.description_EN == props?.values?.description_EN) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <div><CFormLabel>From Date</CFormLabel>
                                    <span className='text-danger' style={{ marginLeft: '5px' }}>*</span></div>
                                  <DesktopDatePicker
                                    inputFormat="DD/MM/YYYY"
                                    name="fromDate"
                                    style={{ height: '68px', height: '595px' }}
                                    value={fromDate == null ? defaultFromDate : fromDate}
                                    minDate={moment()}
                                    maxDate={fromMax}
                                    onChange={(e) => {
                                      if (e?.$d) setIsDisable(true)
                                      else setIsDisable(false)
                                      handleDateChange(e, 'fromDate')
                                      props?.setFieldValue('fromDate', e?.$d)
                                    }}
                                    renderInput={(params) => <TextField style={{ marginTop: '5px' }} name="fromDate" {...params} />}
                                  />
                                </LocalizationProvider>

                                {fromDate == null && props?.touched?.fromDate == true ? <p className='text-danger mt-2'>{props?.errors?.fromDate}</p> : ''}

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <div><CFormLabel>To Date</CFormLabel>
                                    <span className='text-danger' style={{ marginLeft: '5px' }}>*</span></div>
                                  <DesktopDatePicker

                                    inputFormat="DD/MM/YYYY"
                                    name="todate"
                                    style={{ height: '68px', height: '595px' }}
                                    value={toDate == null ? defaultToDate : toDate}
                                    minDate={toMinimum}
                                    onChange={(e) => {
                                      if (e?.$d) setIsDisable(true)
                                      else setIsDisable(false)
                                      handleDateChange(e, 'toDate')
                                      props?.setFieldValue('todate', e?.$d)
                                    }}
                                    renderInput={(params) => <TextField defaultValue={new Date()} style={{ marginTop: '5px' }} name="todate" {...params} />}
                                  />
                                </LocalizationProvider>

                                {toDate == null && props?.touched?.todate == true ? <p className='text-danger mt-2'>{props?.errors?.todate}</p> : ''}

                                <CFormLabel>Image(EN)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <Info message="100x120"/>
                                <DefaultInput
                                  type={'file'}
                                  placeholder={'Image (EN)'}
                                  accept="image/*"
                                  refr={fileRef}
                                  onChange={(e) => {
                                    const imageFile = e?.target?.files[0];
                                    if (imageUrlEN == imageFile?.name) setIsDisable(false)
                                    else setIsDisable(true)
                                    if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                      setInvalidImageEN("Please select valid image")
                                    }
                                    else {
                                      setInvalidImageEN('')
                                    }
                                    //for image preview
                                    const reader = new FileReader();
                                    reader?.addEventListener("load", () => setENImagePreview(reader?.result));
                                    reader?.readAsDataURL(e?.target?.files[0]);
                                    //for image preview
                                    props?.setFieldValue('image_EN', e?.target?.files[0])
                                  }}
                                  onBlur={() => props?.setFieldTouched('image_EN', true)}
                                  error={(props?.touched?.image_EN && props?.errors?.image_EN) || invalidImageEN}
                                />
                                {/* for image preview */}
                                {eNImagePreview?.length > 0 ?
                                  <>
                                    <CCol>
                                      <CImage
                                        rounded
                                        thumbnail
                                        className="mt-2"
                                        src={`${eNImagePreview}`}
                                        style={{
                                          maxHeight: '80px',
                                          maxWidth: '80px',
                                          alignItems: 'center',
                                        }}
                                      />
                                    </CCol>
                                  </>
                                  : ''}
                                {/* for image preview */}
                                {imageUrlEN && (
                                  <CImage
                                    rounded
                                    thumbnail
                                    className='mt-2'
                                    src={`${imageUrlEN}`}
                                    style={{
                                      maxHeight: "80px",
                                      maxWidth: "80px",
                                      alignItems: "center",
                                    }}
                                  />
                                )}

                              </CCol>
                              <CCol md="6">

                                <CFormLabel>Title (TH)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={"text"}
                                  placeholder={"Title (TH)"}
                                  value={props?.values?.title_th}
                                  onChange={props?.handleChange("title_th")}
                                  onKeyUp={() => {
                                    if (state?.title_TH == props?.values?.title_th)
                                      setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                                {props?.values?.title_th == '' && props?.touched?.title_th == true ? <p className='text-danger mt-2'>{props?.errors?.title_th}</p> : ''}

                                <CFormLabel>Offer Type</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <Select
                                  defaultValue={offerType}
                                  onChange={(val) => {
                                    setOfferType(val)
                                    if (val == offerType) setIsDisable(false)
                                    else setIsDisable(true)
                                    props?.setFieldValue("offerType", val.value)
                                  }}
                                  value={offerType}
                                  options={offerTypeOptions}
                                  placeholder={"Offer Type"}
                                  name={"offerType"}
                                  onKeyUp={() => {
                                    if (state?.offerType == props?.values?.offerType)
                                      setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                                {props?.values?.offerType == '' && props?.touched?.offerType == true ? <p className='text-danger mt-2'>{props?.errors?.offerType}</p> : ''}

                                <CFormLabel>Minimum Cart Items</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={"number"}
                                  placeholder={"Minimum Cart Items"}
                                  value={props?.values?.minCart}
                                  onChange={props?.handleChange("minCart")}
                                  onKeyUp={() => {
                                    if (state?.minimumCartValue == props?.values?.minCart)
                                      setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                                {props?.values?.minCart == '' && props?.touched?.minCart == true ? <p className='text-danger mt-2'>{props?.errors?.minCart}</p> : ''}

                                <DefaultTextarea
                                  placeholder={'Description (TH)'}
                                  name={'Description (TH)'}
                                  id={'description_TH'}
                                  value={props?.values?.description_TH}
                                  onChange={props?.handleChange('description_TH')}
                                  onBlur={() => props?.setFieldTouched('description_TH', true)}
                                  error={props?.touched?.description_TH && props?.errors?.description_TH}
                                  onKeyUp={() => {
                                    if (state?.description_TH == props?.values?.description_TH) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />

                                <CFormLabel>Coupon Code</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={"text"}
                                  placeholder={"Coupon Code"}
                                  value={props?.values?.code}
                                  onChange={props?.handleChange("code")}
                                  onKeyUp={() => {
                                    if (state?.couponCode == props?.values?.code)
                                      setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                                {props?.values?.code == '' && props?.touched?.code == true ? <p className='text-danger mt-2'>{props?.errors?.code}</p> : ''}

                                <CFormLabel>Offer Quantity</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={"text"}
                                  placeholder={"Offer quantity"}
                                  value={props?.values?.quantity}
                                  onChange={props?.handleChange("quantity")}
                                  onKeyUp={() => {
                                    if (state?.offerQuantity == props?.values?.quantity)
                                      setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                                {props?.values?.quantity == '' && props?.touched?.quantity == true ? <p className='text-danger mt-2'>{props?.errors?.quantity}</p> : ''}

                                <CFormLabel>Image (TH)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <Info message="100x120"/>
                                <DefaultInput
                                  type={'file'}
                                  placeholder={'Image (TH)'}
                                  // name={'Image (TH)'}
                                  accept="image/*"
                                  refr={fileRef}
                                  onChange={(e) => {
                                    const imageFile = e?.target?.files[0];
                                    if (imageUrlTH == imageFile?.name) setIsDisable(false)
                                    else setIsDisable(true)
                                    if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                      setInvalidImageTH("Please select valid image")
                                    }
                                    else {
                                      setInvalidImageTH('')
                                    }
                                    //for image preview
                                    const reader = new FileReader();
                                    reader?.addEventListener("load", () => setTHImagePreview(reader?.result));
                                    reader?.readAsDataURL(e?.target?.files[0]);
                                    //for image preview
                                    props?.setFieldValue('image_TH', e?.target?.files[0])
                                  }}
                                  onBlur={() => props?.setFieldTouched('image_TH', true)}
                                  error={(props?.touched?.image_TH && props?.errors?.image_TH) || invalidImageTH}
                                />
                                {/* for image preview */}
                                {tHImagePreview?.length > 0 ?
                                  <>
                                    <CCol>
                                      <CImage
                                        rounded
                                        thumbnail
                                        className="mt-2"
                                        src={`${tHImagePreview}`}
                                        style={{
                                          maxHeight: '80px',
                                          maxWidth: '80px',
                                          alignItems: 'center',
                                        }}
                                      />
                                    </CCol>
                                  </>
                                  : ''}
                                {/* for image preview */}
                                {imageUrlTH && (
                                  <CImage
                                    rounded
                                    thumbnail
                                    className='mt-2'
                                    src={`${imageUrlTH}`}
                                    style={{
                                      maxHeight: "80px",
                                      maxWidth: "80px",
                                      alignItems: "center",
                                    }}
                                  />
                                )}

                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"
                                  className={'btn btn-success mt-2'}
                                  disabled={!isDisable || props?.isSubmitting}
                                >
                                  Submit
                                </button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={() => navigate('/admin/offer')}
                                >
                                  Cancel
                                </button>
                              </CCol>
                              <CCol md="3"></CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer >
    </>
  )
}

export default edit