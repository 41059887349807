export const baseURL = 'https://cuelaundry.devtechnosys.tech'
export const ImageUrl = 'https://cuelaundry.devtechnosys.tech/images/'
export const ImageUrl2 = 'https://cuelaundry.devtechnosys.tech/'


// Pawan IP
// export const baseURL = 'http://172.16.11.203:3006'
// export const ImageUrl = 'http://172.16.11.203:3006/images/'
// export const ImageUrl2 = 'http://172.16.11.203:3006/'




// Ashok sir IP
// export const baseURL = 'http://172.16.11.202:3006'
// export const ImageUrl = 'http://172.16.11.202:3006/images/'
// export const ImageUrl2 = 'http://172.16.11.202:3006/'

// Harsh Api

// export const baseURL = 'http://172.16.11.202:3006'
// export const ImageUrl = 'http://172.16.11.202:3006/images/'
// export const ImageUrl2 = 'http://172.16.11.202:3006/'

// Sahil 
// export const baseURL = 'http://172.16.1.178:3006'
// export const ImageUrl = 'http://172.16.1.178:3006/images/'
// export const ImageUrl2 = 'http://172.16.1.178:3006/'

// export const baseURL = 'http://172.16.11.215:3006'
// export const ImageUrl = 'http://172.16.1.188:3006/images/'
// export const ImageUrl2 = 'http://172.16.1.188:3006/'

// NjQwMTdiOTEwMmRlZmY2ZTNkOTA3MWUwOjkxMTUxMg ==

// export const baseURL = 'http://172.16.11.93:3006'
// export const ImageUrl = 'http://172.16.11.93:3006/images/'
// export const ImageUrl2 = 'http://172.16.11.93:3006/'

export const google_api_key = 'AIzaSyBNPYbsmeUKRDH6WgLqnmyR2C60kgjkqoQ'
