import React, { useEffect, useRef, useState } from 'react'
import { Formik } from 'formik'
import { useNavigate, Link } from 'react-router-dom'
import { post, get } from 'src/utils/apiManager'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  CBadge,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormLabel,
  CImage,
  CRow,
} from '@coreui/react'
import { DefaultInput, DefaultTextarea } from 'src/components/common/input'
import { validateServices } from 'src/utils/validator'
import Select from 'react-select'
import { FormLabel } from 'react-bootstrap'
import { Ckeditor } from '../Template/Ckeditor'
import CIcon from '@coreui/icons-react'
import { FaInfoCircle } from 'react-icons/fa';
import Info from '../info/info'

function add() {
  const navigate = useNavigate()
  const formRef = useRef(null)
  const fileRef = useRef(null)
  const [invalidImage, setInvalidImage] = useState('')
  const [invalidServiceIconImage, setInvalidServiceIconImage] = useState('')
  const [invalidServiceProcessImage, setInvalidServiceProcessImage] = useState('')
  const [description_EN, setDescription_EN] = useState('')
  const [description_TH, setDescription_TH] = useState('')
  const [userPolicy_EN, setUserPolicy_EN] = useState('')
  const [userPolicy_TH, setUserPolicy_TH] = useState('')
  const [imageFile, setImageFile] = useState("")
  const [serviceProcessTitle_EN, setServiceProcessTitle_EN] = useState('')
  const [serviceProcessTitle_TH, setServiceProcessTitle_TH] = useState('')
  const [serviceProcessDescription_TH, setServiceProcessDescription_TH] = useState('')
  const [serviceProcessDescription_EN, setServiceProcessDescription_EN] = useState('')
  const [selectedUnitOption, setSelectedUnitOption] = useState(null)
  const [unitData, setUnitData] = useState(null)
  const unitOption = []
  const [selectedItemOption, setSelectedItemOption] = useState([])
  const [itemSizeData, setItemSizeData] = useState(null)
  const itemOptions = [{ label: 'All Items', value: '001' }]
  const itemsData = []
  const [serviceImagePreview, setServiceImagePreview] = useState()
  const [serviceIconPreview, setServiceIconPreview] = useState()
  const [serviceProcessPreview, setServiceProcessPreview] = useState()
  const [itemsDataOptions, setItemsDataOptions] = useState([])

  const [serviceProcessSections, setServiceProcessSections] = useState([{
    serviceProcessTitle_EN: '',
    serviceProcessTitle_TH: '',
    serviceProcessDescription_EN: '',
    serviceProcessDescription_TH: '',
    serviceProcessImage: null
  }]);



  const [validationErrors, setValidationErrors] = useState(
    serviceProcessSections.map(() => ({
      serviceProcessTitle_EN: '',
      serviceProcessTitle_TH: '',
      serviceProcessDescription_EN: '',
      serviceProcessDescription_TH: '',
      serviceProcessImage: '',
    }))
  );


  const validateForm = () => {
    let valtrue = true
    const errors = serviceProcessSections.map((section) => {
      const sectionErrors = {};

      sectionErrors.serviceProcessTitle_EN = section.serviceProcessTitle_EN ? '' : 'Title (EN) is required';
      sectionErrors.serviceProcessTitle_TH = section.serviceProcessTitle_TH ? '' : 'Title (TH) is required';
      sectionErrors.serviceProcessDescription_EN = section.serviceProcessDescription_EN ? '' : 'Description (EN) is required';
      sectionErrors.serviceProcessDescription_TH = section.serviceProcessDescription_TH ? '' : 'Description (TH) is required';
      sectionErrors.serviceProcessImage = section.serviceProcessImage ? '' : 'Image is required';

      return sectionErrors;
    });

    setValidationErrors(errors);
    console.log({ errors })
    console.log(errors.every((err) => Object.values(err).every((msg) => msg === '')))
    return errors.every((err) => Object.values(err).every((msg) => msg === ''));
  };
  // const [itemsData, setItemsData] = useState(null)
  const getUnit = () => {
    get(`admin/services/get-unit`).then((data) => {
      setUnitData(data?.data)
    })
  }
  if (unitData !== null) {
    unitData.map((item, key) => {
      unitOption.push({ label: item?.title_EN, value: item?._id })
    })
  }

  const getItems = () => {
    get(`admin/services/get-items`).then((data) => {
      setItemSizeData(data?.data)
    })
  }
  if (itemSizeData !== null) {
    itemSizeData?.map((item, key) => {
      itemsData.push({ label: item?.itemName_EN, value: item?._id })
      itemOptions?.push({ label: item?.itemName_EN, value: item?._id })
    })
  }
  useEffect(() => {
    getUnit()
    getItems()
  }, [])
  const handleAddServiceProcess = () => {
    const newSections = [
      ...serviceProcessSections,
      {
        serviceProcessTitle_EN: '',
        serviceProcessTitle_TH: '',
        serviceProcessDescription_EN: '',
        serviceProcessDescription_TH: '',
        serviceProcessImage: null,
      }
    ];
    setServiceProcessSections(newSections);

    setValidationErrors([
      ...validationErrors,
      {
        serviceProcessTitle_EN: '',
        serviceProcessTitle_TH: '',
        serviceProcessDescription_EN: '',
        serviceProcessDescription_TH: '',
        serviceProcessImage: '',
      }
    ]);
  };

  useEffect(() => {
    setValidationErrors(serviceProcessSections.map(() => ({
      serviceProcessTitle_EN: '',
      serviceProcessTitle_TH: '',
      serviceProcessDescription_EN: '',
      serviceProcessDescription_TH: '',
      serviceProcessImage: '',
    })));
  }, [serviceProcessSections]);

  const handleRemoveServiceProcess = (index) => {

    const updatedSections = serviceProcessSections.filter((_, i) => i !== index);
    console.log(updatedSections)
    setServiceProcessSections(updatedSections);
  };
  const handleServiceProcessInputChange = (index, field, value) => {
    const updatedSections = [...serviceProcessSections];
    updatedSections[index][field] = value;
    setServiceProcessSections(updatedSections);

    const updatedErrors = [...validationErrors];
    if (value) {
      updatedErrors[index][field] = '';
    } else {
      updatedErrors[index][field] = `${field.replace(/_/g, ' ')} is required`;
    }

    setValidationErrors(updatedErrors);
  };

  const handleServiceProcessImageChange = (index, e) => {
    const imageFile = e.target.files[0];

    if (!imageFile || !imageFile.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      alert('Please upload a valid image (jpg, jpeg, png, gif).');
      e.target.value = '';
      return;
    }

    setImageFile(imageFile)
    let formData = new FormData();
    formData.append('image', imageFile);

    post(`admin/services/service-process-image`, formData, 1).then((data) => {
      serviceProcessSections[index].serviceProcessImage = data.url;
      setServiceProcessSections([...serviceProcessSections]);
    }).catch(err => {
      console.error('Image upload failed:', err);
    });
  };

  const submit = async (values) => {

    if (validateForm()) {
      console.log('Form submitted successfully!');
    } else {
      return
    }


    if (values?.price <= 0 || selectedItemOption?.length == 0) {
      if (values?.item?.length == 0) {
        toast.error('Items required')
      } else if (values?.price <= 0) {
        formRef.current.setFieldValue('price', '')
      }
      // values?.price <= 0 ? formRef.current.setFieldValue('price', '') : formRef.current.setFieldValue('serviceQuota', '')
    } else {
      if (invalidImage?.length === 0) {
        // var SelectedItem = []
        // values?.item?.map((item, key) => {
        //   SelectedItem?.push(item?.value)
        // })
        // console.log(typeof(SelectedItem))
        let items = []
        selectedItemOption?.map((item) => {
          items.push(item.value)
        })

        let formData = new FormData()
        formData.append('serviceName_EN', values?.serviceName_EN)
        formData.append('serviceName_TH', values?.serviceName_TH)
        formData.append('subTitle_EN', values?.subTitle_EN)
        formData.append('subTitle_TH', values?.subTitle_TH)
        formData.append('description_EN', values?.description_EN)
        formData.append('description_TH', values?.description_TH)
        formData.append('userPolicy_EN', values?.userPolicy_EN)
        formData.append('serviceProcess', JSON.stringify(serviceProcessSections))
        // formData.append('serviceProcessTitle_EN', values?.serviceProcessTitle_EN)
        // formData.append('serviceProcessTitle_TH', values?.serviceProcessTitle_TH)
        // formData.append('serviceProcessDescription_EN', values?.serviceProcessDescription_EN)
        // formData.append('serviceProcessDescription_TH', values?.serviceProcessDescription_TH)
        // formData.append('serviceProcessImage', values?.serviceProcessImage)
        formData.append('unit', values?.unit)
        // formData.append('item', SelectedItem)
        // var items = []
        // formData.append('item', [selectedItemOption.map((item) => item.value)])
        formData.set('item', [selectedItemOption.map((item) => item.value)])
        formData.append('price', values?.price)
        formData.append('image', values?.image)
        formData.append('serviceQuota', values?.serviceQuota)
        formData.append('service_icon', values?.service_icon)
        post(`admin/services/add-service`, formData, 1).then((data) => handleResponse(data))
      } else {
        return
      }
    }
  }
  const handleResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      navigate('/admin/services')
    }
    // formRef.current.resetForm()
    setSelectedUnitOption('')
  }
  const onClickReset = () => {
    // Reset Button Function
    formRef.current.resetForm()
    fileRef.current.value = null
    setSelectedUnitOption('')
    setSelectedItemOption('')
    setServiceProcessSections([{
      serviceProcessTitle_EN: '',
      serviceProcessTitle_TH: '',
      serviceProcessDescription_EN: '',
      serviceProcessDescription_TH: '',
      serviceProcessImage: null
    }]);
  }

  return (
    <>
      <CContainer>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>New Service</h1>
                        </CCol>
                        <CCol md="1">
                          <Link
                            className={'btn btn-success mt-2'}
                            style={{ background: '#d3dc40', border: '#d3dc40' }}
                            to={{ pathname: `/admin/services` }}
                          >
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          serviceName_EN: '',
                          serviceName_TH: '',
                          subTitle_EN: '',
                          subTitle_TH: '',
                          description_EN: '',
                          description_TH: '',
                          userPolicy_EN: '',
                          userPolicy_TH: '',
                          unit: '',
                          // item: '',
                          price: '',
                          image: '',
                          service_icon: '',
                          serviceQuota: '',
                          serviceProcessTitle_EN: '',
                          serviceProcessTitle_TH: '',
                          serviceProcessDescription_EN: '',
                          serviceProcessDescription_TH: '',
                          serviceProcessImage: ''
                        }}
                        validationSchema={validateServices}

                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)
                        }}
                        innerRef={formRef}>
                        {(props) => (
                          <form onSubmit={props?.handleSubmit}>
                            <CRow>
                              <CCol md={6}>
                                <CFormLabel>Service Name (EN)</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Service Name (EN)'}
                                  // name={'Service Name (EN)'}
                                  id={'serviceName_EN'}
                                  maxLength="50"
                                  value={props?.values?.serviceName_EN}
                                  onChange={props?.handleChange('serviceName_EN')}
                                  onBlur={() => props?.setFieldTouched('serviceName_EN', true)}
                                  error={
                                    props?.touched?.serviceName_EN && props?.errors?.serviceName_EN
                                  }
                                />
                                <CFormLabel>Sub Title (EN)</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Sub Title (EN)'}
                                  id={'subTitle_EN'}
                                  maxLength="50"
                                  value={props?.values?.subTitle_EN}
                                  onChange={props?.handleChange('subTitle_EN')}
                                  onBlur={() => props?.setFieldTouched('subTitle_EN', true)}
                                  error={props?.touched?.subTitle_EN && props?.errors?.subTitle_EN}
                                />
                                {console.log("props.errors", props.errors)}
                                <FormLabel> Description (EN)</FormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <Ckeditor
                                  setDescription_EN={setDescription_EN}
                                  description_EN={description_EN}
                                  value={props?.values?.description_EN}
                                  onChange={(val) => {
                                    setDescription_EN(val)
                                    props?.setFieldValue('description_EN', val)
                                  }}
                                  onBlur={() => props?.setFieldTouched('description_EN', true)}
                                  error={
                                    props?.touched?.description_EN && props?.errors?.description_EN
                                  }
                                />
                                <CFormLabel>Image</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <Info message="110x120" />
                                <DefaultInput
                                  type={'file'}
                                  placeholder={'Image'}
                                  accept="image/*"
                                  refr={fileRef}
                                  onChange={(e) => {
                                    const imageFile = e?.target?.files[0]
                                    // console.log(imageFile)
                                    if (!imageFile.name.match(/\.(jpg|jpeg|png|gif)$/)) {
                                      setInvalidImage('Please select valid image')
                                    } else {
                                      setInvalidImage('')
                                    }
                                    //for image preview
                                    const reader = new FileReader()
                                    reader?.addEventListener('load', () =>
                                      setServiceImagePreview(reader?.result),
                                    )
                                    reader?.readAsDataURL(e?.target?.files[0])
                                    //for image preview
                                    props?.setFieldValue('image', e?.target?.files[0])
                                  }}
                                  onBlur={() => props?.setFieldTouched('image', true)}
                                  error={
                                    (props?.touched?.image && props?.errors?.image) || invalidImage
                                  }
                                />
                                {/* for image preview */}
                                {serviceImagePreview?.length > 0 ? (
                                  <>
                                    <CCol>
                                      <CImage
                                        rounded
                                        thumbnail
                                        className="mt-2"
                                        src={`${serviceImagePreview}`}
                                        style={{
                                          maxHeight: '80px',
                                          maxWidth: '80px',
                                          alignItems: 'center',
                                        }}
                                      />
                                    </CCol>
                                  </>
                                ) : (
                                  ''
                                )}
                                {/* for image preview */}

                                <FormLabel> User Policy (EN)</FormLabel>
                                {/* <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span> */}
                                <Ckeditor
                                  setUserPolicy_EN={setUserPolicy_EN}
                                  userPolicy_EN={userPolicy_EN}
                                  value={props?.values?.userPolicy_EN}
                                  onChange={(val) => {
                                    setDescription_EN(val)
                                    props?.setFieldValue('userPolicy_EN', val)
                                  }}
                                onBlur={() => props?.setFieldTouched('userPolicy_EN', true)}
                                error={props?.touched?.userPolicy_EN && props?.errors?.userPolicy_EN}
                                />
                                {/* <CFormLabel>Service Quota</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Service Quota'}
                                  value={props?.values?.serviceQuota}
                                  onChange={props?.handleChange('serviceQuota')}
                                  onBlur={() => props?.setFieldTouched('serviceQuota', true)}
                                  error={
                                    props?.touched?.serviceQuota && props?.errors?.serviceQuota
                                  }
                                /> */}
                                <CFormLabel>Unit (Per)</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <Select
                                  defaultValue={selectedUnitOption}
                                  onChange={(val) => {
                                    setSelectedUnitOption(val)
                                    props?.setFieldValue('unit', val.value)
                                  }}
                                  options={unitOption}
                                  placeholder={'Select unit'}
                                  value={selectedUnitOption}
                                />
                                {selectedUnitOption == undefined ? (
                                  <span className="text-danger">{props?.errors?.unit}</span>
                                ) : (
                                  ''
                                )}
                              </CCol>
                              <CCol md={6}>
                                <CFormLabel>Service Name (TH)</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Service Name (TH)'}
                                  id={'serviceName_TH'}
                                  maxLength="50"
                                  value={props?.values?.serviceName_TH}
                                  onChange={props?.handleChange('serviceName_TH')}
                                  onBlur={() => props?.setFieldTouched('serviceName_TH', true)}
                                  error={
                                    props?.touched?.serviceName_TH && props?.errors?.serviceName_TH
                                  }
                                />
                                <CFormLabel>Sub Title (TH)</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Sub Title (TH)'}
                                  id={'subTitle_TH'}
                                  maxLength="50"
                                  value={props?.values?.subTitle_TH}
                                  onChange={props?.handleChange('subTitle_TH')}
                                  onBlur={() => props?.setFieldTouched('subTitle_TH', true)}
                                  error={props?.touched?.subTitle_TH && props?.errors?.subTitle_TH}
                                />
                                <FormLabel> Description (TH)</FormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <Ckeditor
                                  setDescription_TH={setDescription_TH}
                                  description_TH={description_TH}
                                  value={props?.values?.description_TH}
                                  onChange={(val) => {
                                    setDescription_TH(val)
                                    // console.log(val,"DESCRIPT");
                                    props?.setFieldValue('description_TH', val)
                                  }}
                                  onBlur={() => props?.setFieldTouched('description_TH', true)}
                                  error={
                                    props?.touched?.description_TH && props?.errors?.description_TH
                                  }
                                />
                                <CFormLabel>Service Icon</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <Info message="110x120" />
                                <DefaultInput
                                  type={'file'}
                                  placeholder={'Image'}
                                  accept="image/*"
                                  refr={fileRef}
                                  onChange={(e) => {
                                    const imageFile = e?.target?.files[0]
                                    if (!imageFile.name.match(/\.(jpg|jpeg|png|gif)$/)) {
                                      setInvalidServiceIconImage('Please select valid image')
                                    } else {
                                      setInvalidServiceIconImage('')
                                    }
                                    //for image preview
                                    const reader = new FileReader()
                                    reader?.addEventListener('load', () =>
                                      setServiceIconPreview(reader?.result),
                                    )
                                    reader?.readAsDataURL(e?.target?.files[0])
                                    //for image preview
                                    props?.setFieldValue('service_icon', e?.target?.files[0])
                                  }}
                                  onBlur={() => props?.setFieldTouched('service_icon', true)}
                                  error={
                                    (props?.touched?.service_icon && props?.errors?.service_icon) ||
                                    invalidServiceIconImage
                                  }
                                />
                                {/* for image preview */}
                                {serviceIconPreview?.length > 0 ? (
                                  <>
                                    <CCol>
                                      <CImage
                                        rounded
                                        thumbnail
                                        className="mt-2"
                                        src={`${serviceIconPreview}`}
                                        style={{
                                          maxHeight: '80px',
                                          maxWidth: '80px',
                                          alignItems: 'center',
                                        }}
                                      />
                                    </CCol>
                                  </>
                                ) : (
                                  ''
                                )}
                                {/* for image preview */}

                                <FormLabel> User Policy (TH)</FormLabel>
                                {/* <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span> */}
                                <Ckeditor
                                  setUserPolicy_TH={setUserPolicy_TH}
                                  userPolicy_TH={userPolicy_TH}
                                  value={props?.values?.userPolicy_TH}
                                  onChange={(val) => {
                                    setDescription_TH(val)
                                    props?.setFieldValue('userPolicy_TH', val)
                                  }}
                                onBlur={() => props?.setFieldTouched('userPolicy_TH', true)}
                                error={props?.touched?.userPolicy_TH && props?.errors?.userPolicy_TH}
                                />
                                <CFormLabel>Price</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Price'}
                                  id={'price'}
                                  maxLength="50"
                                  value={props?.values?.price}
                                  onChange={props?.handleChange('price')}
                                  onBlur={() => props?.setFieldTouched('price', true)}
                                  error={props?.touched?.price && props?.errors?.price}
                                />
                              </CCol>
                              <CCol md={12}>
                                <CFormLabel>Items</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>

                                <Select
                                  isMulti={true}
                                  defaultValue={selectedItemOption}
                                  onChange={(val) => {
                                    // if (val.length == 0) {
                                    //   setSelectedItemOption(val)
                                    // }
                                    // val.filter((ele) => {
                                    //   if (ele.value == '001') {
                                    //     setSelectedItemOption(itemsData)
                                    //   } else {
                                    //     setSelectedItemOption(val)
                                    //   }
                                    // })

                                    if (val.length == 0) {
                                      setSelectedItemOption(val)
                                    }
                                    val.filter((ele) => {
                                      if (ele.value == '001') {
                                        setSelectedItemOption(itemsData)
                                      } else {
                                        setSelectedItemOption(val)
                                      }
                                    })
                                    // props?.setFieldValue('item', val)
                                    props?.setFieldValue('item', ["0000000"])
                                  }}
                                  options={itemOptions}
                                  value={selectedItemOption}
                                  placeholder={'Select Items'}
                                  name="Item Size"
                                />
                                {selectedItemOption.length == 0 ? (
                                  <span className="text-danger">{props?.errors?.item}</span>
                                ) : (
                                  ''
                                )}
                              </CCol>


                              {/* Service Process Section Start  */}

                              <CBadge color="primary" shape="rounded-pill" style={{ margin: '10px' }}>
                                Service Process Section
                              </CBadge>

                              <CRow className="justify-content-end">
                                <CCol md="auto" className="text-right">
                                  <button type="button" className="btn btn-success" style={{ background: '#d3dc40', border: '#d3dc40' }} onClick={handleAddServiceProcess}>
                                    Add more
                                  </button>
                                </CCol>
                              </CRow>

                              {serviceProcessSections?.map((section, index) => (
                                <div key={index} >
                                  <CRow>
                                    <CCol md={6}>
                                      <CFormLabel>Title (EN)</CFormLabel>
                                      <span className="text-danger" style={{ marginLeft: '5px' }}>
                                        *
                                      </span>
                                      <DefaultInput
                                        type="text"
                                        placeholder={'Service Process Title EN'}
                                        maxLength="50"
                                        value={section?.serviceProcessTitle_EN || ""}
                                        onChange={(e) => handleServiceProcessInputChange(index, 'serviceProcessTitle_EN', e.target.value)}
                                        onBlur={() => handleServiceProcessInputChange(index, 'serviceProcessTitle_EN', section?.serviceProcessTitle_EN)}
                                      />
                                      {validationErrors[index]?.serviceProcessTitle_EN && (
                                        <div className="text-danger">{validationErrors[index]?.serviceProcessTitle_EN}</div>
                                      )}
                                    </CCol>

                                    <CCol md={6}>
                                      <CFormLabel>Title (TH)</CFormLabel>
                                      <span className="text-danger" style={{ marginLeft: '5px' }}>
                                        *
                                      </span>
                                      <DefaultInput
                                        type="text"
                                        placeholder={'Service Process Title TH'}
                                        value={section?.serviceProcessTitle_TH || ""}
                                        onChange={(e) => handleServiceProcessInputChange(index, 'serviceProcessTitle_TH', e.target.value)}
                                        onBlur={() => handleServiceProcessInputChange(index, 'serviceProcessTitle_TH', section?.serviceProcessTitle_EN)}
                                      />
                                      {validationErrors[index]?.serviceProcessTitle_TH && (
                                        <div className="text-danger">{validationErrors[index]?.serviceProcessTitle_TH}</div>
                                      )}
                                    </CCol>
                                  </CRow>

                                  <CRow>
                                    <CCol md={6}>
                                      <CFormLabel>Description (EN)</CFormLabel>
                                      <span className="text-danger" style={{ marginLeft: '5px' }}>
                                        *
                                      </span>
                                      <Ckeditor
                                        value={section?.serviceProcessDescription_EN || ""}
                                        onChange={(val) => handleServiceProcessInputChange(index, 'serviceProcessDescription_EN', val)}
                                        onBlur={() => handleServiceProcessInputChange(index, 'serviceProcessDescription_EN', section?.serviceProcessDescription_EN)}
                                      />
                                      {validationErrors[index]?.serviceProcessDescription_EN && (
                                        <div className="text-danger">{validationErrors[index]?.serviceProcessDescription_EN}</div>
                                      )}
                                    </CCol>
                                    <CCol md={6}>
                                      <CFormLabel>Description (TH)</CFormLabel>
                                      <span className="text-danger" style={{ marginLeft: '5px' }}>
                                        *
                                      </span>
                                      <Ckeditor
                                        value={section?.serviceProcessDescription_TH || ""}
                                        onChange={(val) => handleServiceProcessInputChange(index, 'serviceProcessDescription_TH', val)}
                                        onBlur={() => handleServiceProcessInputChange(index, 'serviceProcessDescription_TH', section?.serviceProcessDescription_EN)}
                                      />
                                      {validationErrors[index]?.serviceProcessDescription_TH && (
                                        <div className="text-danger">{validationErrors[index]?.serviceProcessDescription_TH}</div>
                                      )}
                                    </CCol>
                                  </CRow>

                                  <CRow className="align-items-center">
                                    <CCol md={6}>
                                      <CFormLabel>Image</CFormLabel>
                                      <span className="text-danger" style={{ marginLeft: '5px' }}>
                                        *
                                      </span>
                                      <Info message="110x120" />
                                      <DefaultInput
                                        type="file"
                                        // value={imageFile?.name || ""}
                                        onChange={(e) => handleServiceProcessImageChange(index, e)}
                                      />
                                      {section?.serviceProcessImage && (
                                        <CImage
                                          rounded
                                          thumbnail
                                          className="mt-2"
                                          src={serviceProcessPreview}
                                          style={{ maxHeight: '80px', maxWidth: '80px', alignItems: 'center' }}
                                        />
                                      )}
                                       {validationErrors[index]?.serviceProcessImage && (
                                        <div className="text-danger">{validationErrors[index]?.serviceProcessImage}</div>
                                      )}
                                    </CCol>

                                    <CCol md={6} className="text-right">
                                      {serviceProcessSections?.length > 1 && ( 
                                        <button
                                          type="button"
                                          className="btn btn-danger  text-white"
                                          style={{ background: '#dc3545', border: '#dc3545',marginTop:'1.9rem' }}
                                          onClick={() => handleRemoveServiceProcess(index)}
                                        >
                                          Remove
                                        </button>
                                      )}
                                    </CCol>
                                  </CRow>

                                </div>
                              ))}



                              {/* Service Process Section End  */}


                              {/* <CCol md={12}>
                                <CFormLabel>Items</CFormLabel>
                                <span className="text-danger" style={{ marginLeft: '5px' }}>
                                  *
                                </span>
                                <Select
                                  isMulti={true}
                                  defaultValue={selectedItemOption}
                                  onChange={(val) => {
                                    if (val.length == 0) {
                                      setSelectedItemOption(val)
                                    }
                                    val.filter((ele) => {
                                      if (ele.value == '001') {
                                        setSelectedItemOption(itemsData)
                                      } else {
                                        setSelectedItemOption(val)
                                      }
                                    })

                                    // setSelectedItemOption(val)
                                    if (val == selectedItemOption) setIsDisable(false)
                                    else setIsDisable(true)
                                    props?.setFieldValue('item', val)
                                  }}
                                  // options={[{ label: 'All Items', value: '001' }, ...itemsData]}
                                  options={itemsDataOptions}
                                  value={selectedItemOption}
                                  placeholder={'Select items'}
                                  name="Item Size"
                                />
                                {console.log('GHGHG', selectedItemOption)}
                                {selectedItemOption?.length == 0 ? (
                                  <span className="text-danger">Items required</span>
                                ) : (
                                  ''
                                )}
                              </CCol> */}
                            </CRow>
                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"
                                  className={'btn btn-success mt-2'}
                                  disabled={props?.isSubmitting}
                                >
                                  Submit
                                </button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={onClickReset}
                                >
                                  Reset
                                </button>
                              </CCol>
                              <CCol md="3"></CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default add











