import React, { useRef, useState, useEffect } from 'react'
import { connect, Formik } from 'formik'
import { useNavigate, Link } from 'react-router-dom'
import { post, get } from 'src/utils/apiManager'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Select from 'react-select'

import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormLabel,
  CRow,
  CTabs,
  CNavItem,
  CNav,
  CTabContent,
  CTabPane, 
  CNavLink,
  CBadge,
  CImage,
} from '@coreui/react'
import { DefaultInput } from 'src/components/common/input'
import { validatePackagePlan } from 'src/utils/validator'
import { FormLabel } from 'react-bootstrap'
import { Ckeditor } from '../Template/Ckeditor'
import Info from '../info/info'
function add() {
  const navigate = useNavigate()
  const formRef = useRef(null)
  const fileRef = useRef(null)
  const fileRefSmall = useRef(null)
  const fileRefMedium = useRef(null)
  const fileRefLarge = useRef(null)
  const [invalidImage, setInvalidImage] = useState('')
  const [smallInvalidImage, setSmallInvalidImage] = useState("")
  const [mediumInvalidImage, setMediumInvalidImage] = useState("")
  const [largeInvalidImage, setLargeInvalidImage] = useState("")
  const [activeKey, setActiveKey] = useState(0)
  const [subscriptionType, setSubscriptionType] = useState('1')
  const [smallPackageSize, setSmallPackageSize] = useState('S')
  const [mediumPackageSize, setMediumPackageSize] = useState('M')
  const [largePackageSize, setLargePackageSize] = useState('L')
  const [description_EN, setDescription_EN] = useState('')
  const [description_TH, setDescription_TH] = useState('')
  const [small_pack_description_EN, setSmall_pack_description_EN] = useState('')
  const [small_pack_description_TH, setSmall_pack_description_TH] = useState('')
  const [medium_pack_description_EN, setMedium_pack_description_EN] = useState('')
  const [medium_pack_description_TH, setMedium_pack_description_TH] = useState('')
  const [large_pack_description_EN, setLarge_pack_description_EN] = useState('')
  const [large_pack_description_TH, setLarge_pack_description_TH] = useState('')
  const [selectedUnitOption, setSelectedUnitOption] = useState(null)
  const [serviceProcessPreview, setServiceProcessPreview] = useState()
  const [serviceProcessDescription_TH, setServiceProcessDescription_TH] = useState('')
  const [serviceProcessDescription_EN, setServiceProcessDescription_EN] = useState('')
  const [unitData, setUnitData] = useState(null)
  const unitOption = []
  const [selectedMUnitOption, setSelectedMUnitOption] = useState(null)
  const [MunitData, setMUnitData] = useState(null)
  const MunitOption = []
  const [selectedLUnitOption, setSelectedLUnitOption] = useState(null)
  const [LunitData, setLUnitData] = useState(null)
  const LunitOption = []
  // const [selectService, setSelectService] = useState(null)
  const [selectService, setSelectService] = useState([])
  const [selectServiceM, setSelectServiceM] = useState([])
  const [selectServiceL, setSelectServiceL] = useState([])
  const [serviceData, setServiceData] = useState([])
  const options = []
  const [isDisable, setIsDisable] = useState(false)
  // const [selectOffer, setSelectOffer] = useState(null)
  // const [offerData, setOfferData] = useState(null)
  // const Offeroptions = []
  const [selectItem, setSelectItem] = useState(null)
  const [itemOptions, setItemOptions] = useState([])
  const [selectDuration, setSelectDuration] = useState(null)
  const [durationData, setDurationData] = useState(null)
  // const [remaningServiceQuota, setRemainingServiceQuota] = useState([])
  const [remaningServiceQuota, setRemainingServiceQuota] = useState([])
  const [remaningServiceQuotaM, setRemainingServiceQuotaM] = useState([])
  const [remaningServiceQuotaL, setRemainingServiceQuotaL] = useState([])
  const [selectStatus, setSelectStatus] = useState({ label: 'Inactive', value: 'inactive' })
  const [selectStatus2, setSelectStatus2] = useState({ label: 'Inactive', value: 'inactive' })
  const [selectStatus3, setSelectStatus3] = useState({ label: 'Inactive', value: 'inactive' })
  const [allServices, setAllServices] = useState(null)
  const [selectedValue, setSelectedValue] = useState([])
  const [selectedValueM, setSelectedValueM] = useState([])
  const [selectedValueL, setSelectedValueL] = useState([])

  const serviceOptions = []
  const remaningQuota = []

  const [serviceProcessSections, setServiceProcessSections] = useState([{
    serviceProcessTitle_EN: '',
    serviceProcessTitle_TH: '',
    serviceProcessDescription_EN: '',
    serviceProcessDescription_TH: '',
    serviceProcessImage: null
  }]);


  const [validationErrors, setValidationErrors] = useState(
    serviceProcessSections.map(() => ({
      serviceProcessTitle_EN: '',
      serviceProcessTitle_TH: '',
      serviceProcessDescription_EN: '',
      serviceProcessDescription_TH: '',
      serviceProcessImage: '',
    }))
  );


  const validateForm = () => {
    let valtrue = true
    const errors = serviceProcessSections.map((section) => {
      const sectionErrors = {};

      sectionErrors.serviceProcessTitle_EN = section.serviceProcessTitle_EN ? '' : 'Title (EN) is required';
      sectionErrors.serviceProcessTitle_TH = section.serviceProcessTitle_TH ? '' : 'Title (TH) is required';
      sectionErrors.serviceProcessDescription_EN = section.serviceProcessDescription_EN ? '' : 'Description (EN) is required';
      sectionErrors.serviceProcessDescription_TH = section.serviceProcessDescription_TH ? '' : 'Description (TH) is required';
      sectionErrors.serviceProcessImage = section.serviceProcessImage ? '' : 'Image is required';

      return sectionErrors;
    });

    setValidationErrors(errors);
    console.log({ errors })
    console.log(errors.every((err) => Object.values(err).every((msg) => msg === '')))
    return errors.every((err) => Object.values(err).every((msg) => msg === ''));
  };




  const getServices = () => {
    get(`admin/subscription/services`).then((data) => {
      setAllServices(data?.data)
      setServiceData(data.data)
    })
  }
  // const getServices = () => {
  //   console.log('CHALA HHHHH')
  //   get(`admin/subscription/services`).then((data) => {
  //     // console?.log('services data_+_++', data)
  //     if (data?.data !== null) {
  //       let tempSelcetedData = []
  //       const tempData = data?.data?.map((item, key) => {
  //         // console?.log('services data', item, typeof (state?.services))
  //         console.log('+++++', item)
  //         var ExistService = Object.values(state?.services)
  //         console?.log('ExistService data', ExistService)
  //         const isEqual = ExistService?.includes(item?._id)
  //         // console?.log('isEqual', isEqual, selectService)
  //         if (isEqual) {
  //           tempSelcetedData?.push({ label: item?.serviceName_EN, value: item?._id })
  //         }
  //         return { label: item?.serviceName_EN, value: item?._id }
  //       })
  //       setSelectService([...tempSelcetedData])
  //       console.log('selectServiceANDERM--->', tempSelcetedData)
  //       // get ka baad me dekhte hai
  //       setServiceData([...tempData])
  //       setAllServices(data?.data)
  //     }
  //   })
  // }
  if (serviceData !== null) {
    serviceData?.map((item, key) => {
      options?.push({ label: item?.serviceName_EN, value: item?._id })
    })
  }




  const handleAddServiceProcess = () => {
    const newSections = [
      ...serviceProcessSections,
      {
        serviceProcessTitle_EN: '',
        serviceProcessTitle_TH: '',
        serviceProcessDescription_EN: '',
        serviceProcessDescription_TH: '',
        serviceProcessImage: null,
      }
    ];
    setServiceProcessSections(newSections);

    setValidationErrors([
      ...validationErrors,
      {
        serviceProcessTitle_EN: '',
        serviceProcessTitle_TH: '',
        serviceProcessDescription_EN: '',
        serviceProcessDescription_TH: '',
        serviceProcessImage: '',
      }
    ]);
  };

  useEffect(() => {
    setValidationErrors(serviceProcessSections.map(() => ({
      serviceProcessTitle_EN: '',
      serviceProcessTitle_TH: '',
      serviceProcessDescription_EN: '',
      serviceProcessDescription_TH: '',
      serviceProcessImage: '',
    })));
  }, [serviceProcessSections]);

  const handleRemoveServiceProcess = (index) => {

    const updatedSections = serviceProcessSections.filter((_, i) => i !== index);
    console.log(updatedSections)
    setServiceProcessSections(updatedSections);
  };
  const handleServiceProcessInputChange = (index, field, value) => {
    const updatedSections = [...serviceProcessSections];
    updatedSections[index][field] = value;
    setServiceProcessSections(updatedSections);

    const updatedErrors = [...validationErrors];
    if (value) {
      updatedErrors[index][field] = '';
    } else {
      updatedErrors[index][field] = `${field.replace(/_/g, ' ')} is required`;
    }

    setValidationErrors(updatedErrors);
  };
  const handleServiceProcessImageChange = (index, e) => {
    console.log(index)
    const imageFile = e.target.files[0];
    
    if (!imageFile || !imageFile.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      alert('Please upload a valid image (jpg, jpeg, png, gif).');
       e.target.value = ''; 
      return; 
  }
    let formData = new FormData()
    formData.append('image', imageFile)

    post(`admin/services/service-process-image`, formData, 1).then((data) => {
      serviceProcessSections[index].serviceProcessImage = data.url;
      setServiceProcessSections(serviceProcessSections)
    })
  };





  const handleChangeValue = (e) => {
    setSelectedValue(e)
    setRemainingServiceQuota(e)
  }
  const handleChangeValueM = (e) => {
    setSelectedValueM(e)
    setRemainingServiceQuotaM(e)
  }
  const handleChangeValueL = (e) => {
    setSelectedValueL(e)
    setRemainingServiceQuotaL(e)
  }

  // const getOffers = () => {
  //   get(`admin/subscription/offers`).then((data) => {
  //     setOfferData(data.data)
  //   })
  // }
  // if (offerData !== null) {
  //   offerData.map((item, key) => {
  //     Offeroptions.push({ label: item.title_EN, value: item._id })
  //   })
  // }









  const getItemData = (serviceId) => {
    get(`admin/subscription/get-item?_id=${serviceId?.toString()}`).then((data) => {
      let tempArr = []
      data?.data?.forEach((data, key) => {
        const itemArr = data?.item?.map((item, key) => {
          return { label: item?.itemName_EN, value: item?._id }
        })
        tempArr.push.apply(tempArr, itemArr)
      })
      setItemOptions(tempArr)
      setSelectItem(tempArr)
    })
  }

  useEffect(() => {
    if (selectService) {
      getItemData()
    }
  }, [selectService])

  useEffect(() => {
    //for get service id which one is selected
    if (selectService?.length > 0) {
      const selected = selectService?.map((i) => i.value)
      getItemData(selected)
    }
  }, [selectService])

  const getPlanDuration = () => {
    get(`admin/subscription/plan-duration`).then((data) => {
      setDurationData(data.data)
    })
  }

  if (durationData !== null) {
    durationData.map((item, key) => {
      // console.log(item)
      serviceOptions.push({ label: item.title_EN, value: item._id })
    })
    // console.log(options)
  }
  // const getUnit= () =>{
  //   get(`admin/services/get-unit`).then((data) => {
  //      setUnitData(data.data)
  //      setMUnitData(data.data)
  //      setLUnitData(data.data)
  //   })
  // }
  // if(unitData !== null ) {
  //   unitData.map((item,key)=>{
  //     unitOption.push({label:item.title_EN, value: item._id})
  //   })
  // }
  // if(MunitData !== null ) {
  //   MunitData.map((item,key)=>{
  //     MunitOption.push({label:item.title_EN, value: item._id})
  //   })
  // }
  // if(LunitData !== null ) {
  //   LunitData.map((item,key)=>{
  //     LunitOption.push({label:item.title_EN, value: item._id})
  //   })
  // }
  useEffect(() => {
    getServices()
    // getOffers()
    getPlanDuration()
  }, [])

  const submit = async (values) => {

    if (validateForm()) {
      console.log('Form submitted successfully!');
    } else {
      return
    }
    console.log(values, 'submit valu')
    if (values?.services?.length == 0) {
      if (values?.services?.length == 0) toast.error('Services are required')
    } else {
      var SERVICE = []
      values?.services?.forEach((item, key) => {
        SERVICE?.push(item?.value)
      })
      // var OFFERS = []
      // values.ApplyOffers.forEach((item, key) => {
      //   OFFERS.push(item.value)
      // })
      // var ITEM = []
      // values?.item?.forEach((item, key) => {
      //   ITEM?.push(item?.value)
      // })
      var REMAINQUOTA = []
      remaningServiceQuota?.forEach((item, key) => {
        REMAINQUOTA?.push(item)
      })

      let formData = new FormData()
      formData.append('subcribe_type', subscriptionType)
      formData.append('S_package_size', smallPackageSize)
      formData.append('M_package_size', mediumPackageSize)
      formData.append('L_package_size', largePackageSize)
      formData.append('image', values?.image)
      formData.append('packageName_EN', values?.packageName_EN)
      formData.append('packageName_TH', values?.packageName_TH)
      // formData.append('services', SERVICE)
      // formData.append('ApplyOffers', OFFERS);
      // formData.append('remainingServicesQuota', JSON.stringify(REMAINQUOTA))
      // formData.append('item', ITEM)
      formData.append('subcribeDuration', values.subcribeDuration)
      formData.append('description_EN', values?.description_EN)
      formData.append('description_TH', values?.description_TH)
      // formData.append('serviceProcessTitle_EN', values?.serviceProcessTitle_EN)
      // formData.append('serviceProcessTitle_TH', values?.serviceProcessTitle_TH)
      // formData.append('serviceProcessDescription_EN', values?.serviceProcessDescription_EN)
      // formData.append('serviceProcessDescription_TH', values?.serviceProcessDescription_TH)
      // formData.append('serviceProcessImage', values?.serviceProcessImage)
      formData.append('serviceProcess', JSON.stringify(serviceProcessSections))
      formData.append('small_pack_description_EN', values?.small_pack_description_EN)
      formData.append('small_pack_description_TH', values?.small_pack_description_TH)
      formData.append('medium_pack_description_EN', values?.medium_pack_description_EN)
      formData.append('medium_pack_description_TH', values?.medium_pack_description_TH)
      formData.append('large_pack_description_EN', values?.large_pack_description_EN)
      formData.append('large_pack_description_TH', values?.large_pack_description_TH)
      formData.append('S_price', values?.S_price)
      formData.append('M_price', values?.M_price)
      formData.append('L_price', values?.L_price)
      formData.append('S_quantity', values?.S_quantity)
      formData.append('M_quantity', values?.M_quantity)
      formData.append('L_quantity', values?.L_quantity)
      formData.append('S_delivery', values?.S_delivery)
      formData.append('M_delivery', values?.M_delivery)
      formData.append('L_delivery', values?.L_delivery)
      // formData.getAll('services')
      formData.append('small_pack_title_EN', values?.small_pack_title_EN)
      formData.append('small_pack_title_TH', values?.small_pack_title_TH)
      formData.append('small_pack_image', values?.small_pack_image)
      formData.append('medium_pack_title_EN', values?.medium_pack_title_EN)
      formData.append('medium_pack_title_TH', values?.medium_pack_title_TH)
      formData.append('medium_pack_image', values?.medium_pack_image)
      formData.append('large_pack_title_EN', values?.large_pack_title_EN)
      formData.append('large_pack_title_TH', values?.large_pack_title_TH)
      formData.append('large_pack_image', values?.large_pack_image)


      let smallArray = []
      let mediumArray = []
      let largeArray = []
      remaningServiceQuota.map((item) => {
        smallArray.push({ serviceId: item?.value, quota: item?.quota })
      })
      remaningServiceQuotaM.map((item) => {
        mediumArray.push({ serviceId: item?.value, quota: item?.quota })
      })
      remaningServiceQuotaL.map((item) => {
        largeArray.push({ serviceId: item?.value, quota: item?.quota })
      })

      formData.append('services', JSON.stringify({ s: smallArray, l: largeArray, m: mediumArray }))
      formData.append(
        'packageStatus',
        JSON.stringify({
          s: selectStatus?.value,
          m: selectStatus2?.value,
          l: selectStatus3?.value,
        }),
      )

      post(`admin/subscription/add`, formData, 1).then((data) => handleResponse(data))
    }
  }
  const handleSelectChange = (value, valuess) => {
    const updatedQuotas = remaningServiceQuota.map((item) => {
      if (valuess.value == item.value) {
        item.quota = value.target.value
      }

      return item
    })
    setRemainingServiceQuota(updatedQuotas)
  }

  const handleSelectChangeM = (value, valuess) => {
    const updatedQuotas = remaningServiceQuotaM.map((item) => {
      if (valuess.value == item.value) {
        item.quota = value.target.value
      }

      return item
    })
    setRemainingServiceQuotaM(updatedQuotas)
  }

  const handleSelectChangeL = (value, valuess) => {
    const updatedQuotas = remaningServiceQuotaL.map((item) => {
      if (valuess.value == item.value) {
        item.quota = value.target.value
      }

      return item
    })
    setRemainingServiceQuotaL(updatedQuotas)
  }

  const handleResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setTimeout(() => {
        navigate('/admin/subscription-plan/package')
      }, 800)
    }
    formRef.current.resetForm()
    setSelectService('')
    setItemOptions('')
    setSelectDuration('')
    setSelectedUnitOption('')
    setSelectedMUnitOption('')
    setSelectedLUnitOption('')
    fileRef.current.value = null
  }
  const onClickReset = () => {
    // console.log('this')
    // console.log(this)
    setSelectService('')
    setSelectDuration('')
    setRemainingServiceQuota([])
    setServiceProcessSections([{
      serviceProcessTitle_EN: '',
      serviceProcessTitle_TH: '',
      serviceProcessDescription_EN: '',
      serviceProcessDescription_TH: '',
      serviceProcessImage: ""
    }]);
    // Reset Button Function
    // formRef.current.setFieldValue('vanNo', '')
  }

  return (
    <>
      <CContainer>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>Package Plan</h1>
                        </CCol>
                        <CCol md="1">
                          <Link
                            className={'btn btn-success mt-2'}
                            style={{ background: '#d3dc40', border: '#d3dc40' }}
                            to={{ pathname: `/admin/subscription-plan/package` }}
                          >
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          packageName_EN: '',
                          packageName_TH: '',
                          subcribeDuration: '',
                          small_pack_title_EN: '',
                          small_pack_title_TH: '',
                          image: '',
                          S_price: '',
                          S_quantity: '',
                          S_delivery: '',
                          // S_unit: '',
                          M_price: '',
                          M_quantity: '',
                          M_delivery: '',
                          // M_unit: '',
                          L_price: '',
                          L_quantity: '',
                          L_delivery: '',
                          // L_unit: '',
                          // services: '',
                          // item: '',
                          description_EN: '',
                          description_TH: '',
                          small_pack_description_EN: '',
                          small_pack_description_TH: '',
                          medium_pack_description_EN: '',
                          medium_pack_description_TH: '',
                          large_pack_description_EN: '',
                          large_pack_description_TH: '',


                          small_pack_image: "",
                          medium_pack_title_EN: "",
                          medium_pack_title_TH: "",

                          medium_pack_image: "",
                          large_pack_title_EN: "",
                          large_pack_title_TH: "",
                          large_pack_image: "",
                          serviceProcessTitle_EN: '',
                          serviceProcessTitle_TH: '',
                          serviceProcessDescription_EN: '',
                          serviceProcessDescription_TH: '',
                          serviceProcessImage: ''
                          // ApplyOffers: '',
                        }}
                        validationSchema={validatePackagePlan}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)
                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props?.handleSubmit}>
                            <CRow xs={12}>
                              <CCol>
                                <CNav variant="pills" role="tablist" className="mt-2">
                                  <CNavItem>
                                    <CNavLink
                                      href="javascript:void(0);"
                                      active={activeKey === 0}
                                      onClick={() => setActiveKey(0)}
                                    >
                                      Global
                                    </CNavLink>
                                  </CNavItem>
                                  <CNavItem>
                                    <CNavLink
                                      href="javascript:void(0);"
                                      active={activeKey === 1}
                                      onClick={() => setActiveKey(1)}
                                    >
                                      Small
                                    </CNavLink>
                                  </CNavItem>
                                  <CNavItem>
                                    <CNavLink
                                      href="javascript:void(0);"
                                      active={activeKey === 2}
                                      onClick={() => setActiveKey(2)}
                                    >
                                      Medium
                                    </CNavLink>
                                  </CNavItem>
                                  <CNavItem>
                                    <CNavLink
                                      href="javascript:void(0);"
                                      active={activeKey === 3}
                                      onClick={() => setActiveKey(3)}
                                    >
                                      Large
                                    </CNavLink>
                                  </CNavItem>
                                </CNav>
                                <CTabContent>
                                  <CTabPane
                                    className="p-3 preview"
                                    role="tabpanel"
                                    aria-labelledby="home-tab"
                                    visible={activeKey === 0}
                                  >
                                    <CRow xs={12}>
                                      <CCol md={6}>
                                        <input
                                          type="hidden"
                                          name="subcribe_type"
                                          value={subscriptionType}
                                        />
                                        <input
                                          type="hidden"
                                          name="S_package_size"
                                          value={smallPackageSize}
                                        />
                                        <CFormLabel>Package Name (EN)</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <DefaultInput
                                          type={'text'}
                                          placeholder={'Package Name (EN)'}
                                          value={props?.values?.packageName_EN}
                                          onChange={props?.handleChange('packageName_EN')}
                                          onBlur={() =>
                                            props?.setFieldTouched('packageName_EN', true)
                                          }
                                          error={
                                            props?.touched?.packageName_EN &&
                                            props?.errors?.packageName_EN
                                          }
                                        />

                                        <FormLabel> Description (EN)</FormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <Ckeditor
                                          setDescription_EN={setDescription_EN}
                                          description_EN={description_EN}
                                          value={props?.values?.description_EN}
                                          onChange={(val) => {
                                            setDescription_EN(val)
                                            props?.setFieldValue('description_EN', val)
                                          }}
                                          onBlur={() =>
                                            props?.setFieldTouched('description_EN', true)
                                          }
                                          error={
                                            props?.touched?.description_EN &&
                                            props?.errors?.description_EN
                                          }
                                        />
                                        <CFormLabel>Image</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                <Info message="110x120"/>
                                        
                                        <DefaultInput
                                          type={'file'}
                                          placeholder={'Image'}
                                          accept="image/*"
                                          refr={fileRef}
                                          onChange={(e) => {
                                            const imageFile = e?.target?.files[0]
                                            if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                              setInvalidImage('Please select valid image')
                                            } else {
                                              setInvalidImage('')
                                            }
                                            props?.setFieldValue('image', e?.target?.files[0])
                                          }}
                                          onBlur={() => props?.setFieldTouched('image', true)}
                                          error={
                                            (props?.touched?.image && props?.errors?.image) ||
                                            invalidImage
                                          }
                                        />
                                        {/* Old servies */}
                                        {/* <CFormLabel>Services</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <Select
                                          isMulti={true}
                                          defaultValue={selectService}
                                          onChange={(val) => {
                                            setSelectService(val)
                                            if (Object.keys(val).length == 0) {
                                              setSelectItem([])
                                              setItemOptions([])
                                              setRemainingServiceQuota([])
                                            }
                                            serviceData?.find((element) => {
                                              val?.map((item) => {
                                                if (element?._id == item?.value) {
                                                  remaningQuota?.push({
                                                    quota: element?.serviceQuota,
                                                    value: element?._id,
                                                    serviceName: element?.serviceName_EN,
                                                  })
                                                  setRemainingServiceQuota(remaningQuota)
                                                }
                                              })
                                            })
                                            props?.setFieldValue('services', val)
                                          }}
                                          options={options}
                                          value={selectService}
                                          placeholder={'Select services'}
                                          name="Services"
                                        />
                                        {selectService == undefined ? (
                                          <span className="text-danger">
                                            {props?.errors?.services}
                                          </span>
                                        ) : (
                                          ''
                                        )}
                                        <br /> */}
                                        {/* <CFormLabel>Item</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <Select
                                          isMulti={true}
                                          defaultValue={selectItem}
                                          onChange={(val) => {
                                            setSelectItem(val)
                                            props?.setFieldValue('item', val)
                                          }}
                                          options={itemOptions}
                                          value={selectItem}
                                          placeholder={'Select items'}
                                          name="Items"
                                        />
                                        {selectItem == undefined ? (
                                          <span className="text-danger">{props?.errors?.item}</span>
                                        ) : (
                                          ''
                                        )} */}
                                      </CCol>
                                      <CCol md="6">

                                        <CFormLabel>Package Name (TH)</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <DefaultInput
                                          type={'text'}
                                          placeholder={'Package Name (TH)'}
                                          value={props?.values?.packageName_TH}
                                          onChange={props?.handleChange('packageName_TH')}
                                          onBlur={() =>
                                            props?.setFieldTouched('packageName_TH', true)
                                          }
                                          error={
                                            props?.touched?.packageName_TH &&
                                            props?.errors?.packageName_TH
                                          }
                                        />

                                        <FormLabel> Description (TH)</FormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <Ckeditor
                                          setDescription_TH={setDescription_TH}
                                          description_TH={description_TH}
                                          value={props?.values?.description_TH}
                                          onChange={(val) => {
                                            setDescription_TH(val)
                                            props?.setFieldValue('description_TH', val)
                                          }}
                                          onBlur={() =>
                                            props?.setFieldTouched('description_TH', true)
                                          }
                                          error={
                                            props?.touched?.description_TH &&
                                            props?.errors?.description_TH
                                          }
                                        />
                                        <CFormLabel>Plan Duration</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                               

                                        <Select
                                          // isMulti={true}
                                          defaultValue={selectDuration}
                                          onChange={(val) => {
                                            setSelectDuration(val)
                                            props?.setFieldValue('subcribeDuration', val.value)
                                          }}
                                          options={serviceOptions}
                                          value={selectDuration}
                                          placeholder={'Select plan duration'}
                                          name="plan Duration"
                                        />

                                        {selectDuration == undefined ? (
                                          <span className="text-danger">
                                            {props?.errors?.subcribeDuration}
                                          </span>
                                        ) : (
                                          ''
                                        )}
                                        <br />
                                        {/* <CFormLabel>Apply Offers</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <Select
                                          isMulti={true}
                                          defaultValue={selectOffer}
                                          onChange={(val) => {
                                            setSelectOffer(val)
                                            props?.setFieldValue('ApplyOffers', val)
                                          }}
                                          options={Offeroptions}
                                          value={selectOffer}
                                          placeholder={'Select offers'}
                                          name="Offers"
                                        />
                                        {selectOffer == undefined ? (<span className="text-danger">{props?.errors?.ApplyOffers}</span>) : ('')} */}
                                        {/* <CFormLabel>Unit</CFormLabel>
                                        <span className='text-danger' style={{marginLeft:'5px'}}>*</span>
                                        <Select
                                        defaultValue={selectedUnitOption}
                                        onChange={(val)=>{setSelectedUnitOption(val)
                                            props?.setFieldValue("S_unit",val.value)}}
                                        options={unitOption}
                                        placeholder={"Select unit"}
                                        value= {selectedUnitOption}
                                        />
                                        {selectedUnitOption == undefined ? <span className='text-danger'>{props?.errors?.S_unit}</span> : '' }<br/>  */}

                                        {/* {remaningServiceQuota?.length > 0
                                          ? Object.values(remaningServiceQuota).map(
                                              (item, index) => {
                                                return (
                                                  <CCol style={{ marginBottom: '5px' }}>
                                                    <CFormLabel>
                                                      {item?.serviceName} (Remain Quota)
                                                    </CFormLabel>
                                                    <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                                    <DefaultInput
                                                      type="number"
                                                      disabled={true}
                                                      placeholder={'Remain Service Quota'}
                                                      value={item?.quota}
                                                      onChange={props?.handleChange(
                                                        'servicesQuota',
                                                      )}
                                                    />
                                                  </CCol>
                                                )
                                              },
                                            )
                                          : ''} */}
                                      </CCol>
                                    </CRow>
                                    <CRow xs={12}>
                                      <CBadge color="primary" shape="rounded-pill" style={{ margin: "10px", }}>
                                        Process Section
                                      </CBadge>
                                      <CRow className="justify-content-end">
                                        <CCol md="auto" className="text-right">
                                          <button type="button" className="btn btn-success" style={{ background: '#d3dc40', border: '#d3dc40' }}
                                            onClick={handleAddServiceProcess}
                                          >
                                            Add more
                                          </button>
                                        </CCol>
                                      </CRow>
                                      {/* Process Service */}
                                      {serviceProcessSections.map((section, index) => (
                                <div key={index}>
                                  <CRow>
                                    <CCol md={6}>
                                      <CFormLabel>Title (EN)</CFormLabel>
                                      <span className="text-danger" style={{ marginLeft: '5px' }}>
                                        *
                                      </span>
                                      <DefaultInput
                                        type="text"
                                        placeholder={'Service Process Title EN'}
                                        maxLength="50"
                                        value={section.serviceProcessTitle_EN || ""}
                                        onChange={(e) => handleServiceProcessInputChange(index, 'serviceProcessTitle_EN', e.target.value)}
                                        onBlur={() => handleServiceProcessInputChange(index, 'serviceProcessTitle_EN', section.serviceProcessTitle_EN)}
                                      />
                                      {validationErrors[index].serviceProcessTitle_EN && (
                                        <div className="text-danger">{validationErrors[index].serviceProcessTitle_EN}</div>
                                      )}
                                    </CCol>

                                    <CCol md={6}>
                                      <CFormLabel>Title (TH)</CFormLabel>
                                      <span className="text-danger" style={{ marginLeft: '5px' }}>
                                        *
                                      </span>
                                      <DefaultInput
                                        type="text"
                                        placeholder={'Service Process Title TH'}
                                        value={section.serviceProcessTitle_TH || ""}
                                        onChange={(e) => handleServiceProcessInputChange(index, 'serviceProcessTitle_TH', e.target.value)}
                                        onBlur={() => handleServiceProcessInputChange(index, 'serviceProcessTitle_TH', section.serviceProcessTitle_EN)}
                                      />
                                      {validationErrors[index].serviceProcessTitle_TH && (
                                        <div className="text-danger">{validationErrors[index].serviceProcessTitle_TH}</div>
                                      )}
                                    </CCol>
                                  </CRow>

                                  <CRow>
                                    <CCol md={6}>
                                      <CFormLabel>Description (EN)</CFormLabel>
                                      <span className="text-danger" style={{ marginLeft: '5px' }}>
                                        *
                                      </span>
                                      <Ckeditor
                                        value={section.serviceProcessDescription_EN || ""}
                                        onChange={(val) => handleServiceProcessInputChange(index, 'serviceProcessDescription_EN', val)}
                                        onBlur={() => handleServiceProcessInputChange(index, 'serviceProcessDescription_EN', section.serviceProcessDescription_EN)}
                                      />
                                      {validationErrors[index].serviceProcessDescription_EN && (
                                        <div className="text-danger">{validationErrors[index].serviceProcessDescription_EN}</div>
                                      )}
                                    </CCol>
                                    <CCol md={6}>
                                      <CFormLabel>Description (TH)</CFormLabel>
                                      <span className="text-danger" style={{ marginLeft: '5px' }}>
                                        *
                                      </span>
                                      <Ckeditor
                                        value={section.serviceProcessDescription_TH || ""}
                                        onChange={(val) => handleServiceProcessInputChange(index, 'serviceProcessDescription_TH', val)}
                                        onBlur={() => handleServiceProcessInputChange(index, 'serviceProcessDescription_TH', section.serviceProcessDescription_EN)}
                                      />
                                      {validationErrors[index].serviceProcessDescription_TH && (
                                        <div className="text-danger">{validationErrors[index].serviceProcessDescription_TH}</div>
                                      )}
                                    </CCol>
                                  </CRow>

                                  <CRow className="align-items-center">
                                    <CCol md={6}>
                                      <CFormLabel>Image</CFormLabel>
                                      <span className="text-danger" style={{ marginLeft: '5px' }}>
                                        *
                                      </span>
                                      <Info message="110x120" />
                                      <DefaultInput
                                        type="file"
                                        // value={imageFile?.name || ""}
                                        onChange={(e) => handleServiceProcessImageChange(index, e)}
                                      />
                                      {section.serviceProcessImage && (
                                        <CImage
                                          rounded
                                          thumbnail
                                          className="mt-2"
                                          src={serviceProcessPreview}
                                          style={{ maxHeight: '80px', maxWidth: '80px', alignItems: 'center' }}
                                        />
                                      )}
                                       {validationErrors[index].serviceProcessImage && (
                                        <div className="text-danger">{validationErrors[index].serviceProcessImage}</div>
                                      )}
                                    </CCol>

                                    <CCol md={6} className="text-right">
                                      {serviceProcessSections.length > 1 && ( // Show remove button only if it's not the first form
                                        <button
                                          type="button"
                                          className="btn btn-danger mt-4 text-white"
                                          style={{ background: '#dc3545', border: '#dc3545' }}
                                          onClick={() => handleRemoveServiceProcess(index)}
                                        >
                                          Remove
                                        </button>
                                      )}
                                    </CCol>
                                  </CRow>

                                </div>
                              ))}
                                    </CRow>
                                  </CTabPane>
                                  <CTabPane
                                    className="p-3 preview"
                                    role="tabpanel"
                                    aria-labelledby="home-tab"
                                    visible={activeKey === 1}
                                  >
                                    <CRow xs={12}>
                                      <CCol md={6}>
                                        <input
                                          type="hidden"
                                          name="subcribe_type"
                                          value={subscriptionType}
                                        />

                                        <CFormLabel>Title (EN)</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <DefaultInput
                                          type={'text'}
                                          placeholder={'Title (EN)'}
                                          value={props?.values?.small_pack_title_EN}
                                          onChange={props?.handleChange('small_pack_title_EN')}
                                          onBlur={() =>
                                            props?.setFieldTouched('small_pack_title_EN', true)
                                          }
                                          error={
                                            props?.touched?.small_pack_title_EN &&
                                            props?.errors?.small_pack_title_EN
                                          }
                                        />
                                        {console.log(props)}
                                        <FormLabel>Small Pack Description (EN)</FormLabel>
                                        {/* <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span> */}
                                        <Ckeditor
                                          setSmall_pack_description_EN={
                                            setSmall_pack_description_EN
                                          }
                                          small_pack_description_EN={small_pack_description_EN}
                                          value={props?.values?.small_pack_description_EN}
                                          onChange={(val) => {
                                            setSmall_pack_description_EN(val)
                                            props?.setFieldValue('small_pack_description_EN', val)
                                          }}
                                          onBlur={() =>
                                            props?.setFieldTouched(
                                              'small_pack_description_EN',
                                              true,
                                            )
                                          }
                                          error={
                                            props?.touched?.small_pack_description_EN &&
                                            props?.errors?.small_pack_description_EN
                                          }
                                        />
                                        <CFormLabel>Small Package Price</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <DefaultInput
                                          type={'text'}
                                          placeholder={'Small Package Price'}
                                          value={props?.values?.S_price}
                                          onChange={props?.handleChange('S_price')}
                                          onBlur={() => props?.setFieldTouched('S_price', true)}
                                          error={props?.touched?.S_price && props?.errors?.S_price}
                                        />
                                        <CFormLabel>Quantity</CFormLabel>
                                        {/* <span className='text-danger' style={{ marginLeft: '5px' }}>*</span> */}
                                        <DefaultInput
                                          type={'text'}
                                          placeholder={'Small Package Quantity'}
                                          value={props?.values?.S_quantity}
                                          onChange={props?.handleChange('S_quantity')}
                                          onBlur={() => props?.setFieldTouched('S_quantity', true)}
                                          error={
                                            props?.touched?.S_quantity && props?.errors?.S_quantity
                                          }
                                        />
                                        <CFormLabel>Image</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                <Info message="110x120"/>

                                        <DefaultInput
                                          type={'file'}
                                          placeholder={'Image'}
                                          accept="image/*"
                                          refr={fileRefSmall}
                                          onChange={(e) => {
                                            const imageFile = e?.target?.files[0]
                                            if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                              setSmallInvalidImage('Please select valid image')
                                            } else {
                                              setSmallInvalidImage('')
                                            }
                                            props?.setFieldValue('small_pack_image', e?.target?.files[0])
                                          }}
                                          onBlur={() => props?.setFieldTouched('small_pack_image', true)}
                                          error={
                                            (props?.touched?.small_pack_image && props?.errors?.small_pack_image) ||
                                            smallInvalidImage
                                          }
                                        />

                                      </CCol>
                                      <CCol md="6">
                                        <CFormLabel>Title (TH)</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <DefaultInput
                                          type={'text'}
                                          placeholder={'Title (TH)'}
                                          value={props?.values?.small_pack_title_TH}
                                          onChange={props?.handleChange('small_pack_title_TH')}
                                          onBlur={() =>
                                            props?.setFieldTouched('small_pack_title_TH', true)
                                          }
                                          error={
                                            props?.touched?.small_pack_title_TH &&
                                            props?.errors?.small_pack_title_TH
                                          }
                                        />

                                        <FormLabel>Small Pack Description (TH)</FormLabel>
                                        {/* <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span> */}
                                        <Ckeditor
                                          setSmall_pack_description_TH={
                                            setSmall_pack_description_TH
                                          }
                                          small_pack_description_TH={small_pack_description_TH}
                                          value={props?.values?.small_pack_description_TH}
                                          onChange={(val) => {
                                            setSmall_pack_description_TH(val)
                                            props?.setFieldValue('small_pack_description_TH', val)
                                          }}
                                          onBlur={() =>
                                            props?.setFieldTouched(
                                              'small_pack_description_TH',
                                              true,
                                            )
                                          }
                                          error={
                                            props?.touched?.small_pack_description_TH &&
                                            props?.errors?.small_pack_description_TH
                                          }
                                        />
                                        <CFormLabel>Free Pickup And Delivery</CFormLabel>
                                        {/* <span className='text-danger' style={{ marginLeft: '5px' }}>*</span> */}
                                        <DefaultInput
                                          type={'text'}
                                          placeholder={'Small Package Delivery'}
                                          value={props?.values?.S_delivery}
                                          onChange={props?.handleChange('S_delivery')}
                                          onBlur={() => props?.setFieldTouched('S_delivery', true)}
                                          error={
                                            props?.touched?.S_delivery && props?.errors?.S_delivery
                                          }
                                        />
                                        <br />
                                        <CFormLabel>Status</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <Select
                                          // isMulti={true}
                                          onChange={(val) => {
                                            setSelectStatus(val)
                                            console.log('vsl', selectStatus)
                                            props?.setFieldValue('status', val)
                                          }}
                                          options={[
                                            { label: 'Active', value: 'active' },
                                            { label: 'Inactive', value: 'inactive' },
                                          ]}
                                          value={selectStatus}
                                          placeholder={'Select status'}
                                          name="Status"
                                        />
                                        {/* service */}
                                        <CFormLabel>Services (small)</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <Select
                                          isMulti={true}
                                          // defaultValue={selectService}
                                          value={selectedValue}
                                          onChange={(e) => {
                                            handleChangeValue(e)
                                          }}
                                          options={serviceData?.map((item) => {
                                            return {
                                              value: item?._id,
                                              label: item?.serviceName_EN,
                                              quota: item?.serviceQuota,
                                            }
                                          })}
                                          placeholder={'Select services'}
                                          name="Services"
                                        />
                                        {/* {selectService.length == 0 ? ( */}
                                        {selectedValue.length == 0 ? (
                                          <span className="text-danger">Service is required</span>
                                        ) : (
                                          ''
                                        )}
                                        {remaningServiceQuota?.length > 0
                                          ? Object.values(remaningServiceQuota).map(
                                            (item, index) => {
                                              return (
                                                <CCol style={{ marginBottom: '5px' }}>
                                                  <CFormLabel>
                                                    {item?.label} (Remain Quota)
                                                  </CFormLabel>
                                                  <span
                                                    className="text-danger"
                                                    style={{ marginLeft: '5px' }}
                                                  >
                                                    *
                                                  </span>
                                                  <DefaultInput
                                                    type="number"
                                                    // disabled={true}
                                                    placeholder={'Remain Service Quota'}
                                                    value={remaningServiceQuota[index].quota}
                                                    // onChange={(e) => {
                                                    //   const itemIndex =
                                                    //     remaningServiceQuota.findIndex(
                                                    //       (data) => data.quota === item.id,
                                                    //     )
                                                    //   if (itemIndex === -1) {
                                                    //     console.error('Item not found')
                                                    //     return
                                                    //   }
                                                    //   // Create a new array with the updated item
                                                    //   const updatedItems = [
                                                    //     ...remaningServiceQuota.slice(
                                                    //       0,
                                                    //       itemIndex,
                                                    //     ), // all items before the updated item

                                                    //     {
                                                    //       ...remaningServiceQuota[itemIndex],
                                                    //       quota: e.target.value,
                                                    //     }, // updated item
                                                    //     ...remaningServiceQuota.slice(
                                                    //       itemIndex + 1,
                                                    //     ), // all items after the updated item
                                                    //   ]
                                                    //   // Update the state with the new array
                                                    //   setRemainingServiceQuota(updatedItems)
                                                    // }}

                                                    onChange={(e) => handleSelectChange(e, item)}
                                                  // onChange={(e)=>setRemainingServiceQuota(remaningServiceQuota[index].quota=e.target.value)}
                                                  // onChange={props?.handleChange(
                                                  //   'servicesQuota',
                                                  // )}
                                                  />
                                                </CCol>
                                              )
                                            },
                                          )
                                          : ''}
                                        {/* <CFormLabel>Apply Offers</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <Select
                                          isMulti={true}
                                          defaultValue={selectOffer}
                                          onChange={(val) => {
                                            setSelectOffer(val)
                                            props?.setFieldValue('ApplyOffers', val)
                                          }}
                                          options={Offeroptions}
                                          value={selectOffer}
                                          placeholder={'Select offers'}
                                          name="Offers"
                                        />
                                        {selectOffer == undefined ? (<span className="text-danger">{props?.errors?.ApplyOffers}</span>) : ('')} */}
                                        {/* <CFormLabel>Unit</CFormLabel>
                                        <span className='text-danger' style={{marginLeft:'5px'}}>*</span>
                                        <Select
                                        defaultValue={selectedUnitOption}
                                        onChange={(val)=>{setSelectedUnitOption(val)
                                            props?.setFieldValue("S_unit",val.value)}}
                                        options={unitOption}
                                        placeholder={"Select unit"}
                                        value= {selectedUnitOption}
                                        />
                                        {selectedUnitOption == undefined ? <span className='text-danger'>{props?.errors?.S_unit}</span> : '' }<br/>  */}
                                      </CCol>
                                    </CRow>
                                  </CTabPane>
                                  <CTabPane
                                    className="p-3 preview"
                                    role="tabpanel"
                                    aria-labelledby="profile-tab"
                                    visible={activeKey === 2}
                                  >
                                    <CRow xs={12}>
                                      <CCol md={6}>
                                        <input
                                          type="hidden"
                                          name="M_package_size"
                                          value={mediumPackageSize}
                                        />
                                        <CFormLabel>Title (EN)</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <DefaultInput
                                          type={'text'}
                                          placeholder={'Title (EN)'}
                                          value={props?.values?.medium_pack_title_EN}
                                          onChange={props?.handleChange('medium_pack_title_EN')}
                                          onBlur={() =>
                                            props?.setFieldTouched('medium_pack_title_EN', true)
                                          }
                                          error={
                                            props?.touched?.medium_pack_title_EN &&
                                            props?.errors?.medium_pack_title_EN
                                          }
                                        />

                                        <FormLabel>Medium Pack Description (EN)</FormLabel>
                                        {/* <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span> */}



                                        <Ckeditor
                                          setMedium_pack_description_EN={
                                            setMedium_pack_description_EN
                                          }
                                          medium_pack_description_EN={medium_pack_description_EN}
                                          value={props?.values?.medium_pack_description_EN}
                                          onChange={(val) => {
                                            setMedium_pack_description_EN(val)
                                            props?.setFieldValue('medium_pack_description_EN', val)
                                          }}
                                          onBlur={() =>
                                            props?.setFieldTouched(
                                              'medium_pack_description_EN',
                                              true,
                                            )
                                          }
                                          error={
                                            props?.touched?.medium_pack_description_EN &&
                                            props?.errors?.medium_pack_description_EN
                                          }
                                        />
                                        <CFormLabel>Package Price</CFormLabel>
                                        {/* <span className='text-danger' style={{ marginLeft: '5px' }}>*</span> */}
                                        <DefaultInput
                                          type={'text'}
                                          placeholder={'Medium Package Price'}
                                          value={props?.values?.M_price}
                                          onChange={props?.handleChange('M_price')}
                                          onBlur={() => props?.setFieldTouched('M_price', true)}
                                          error={props?.touched?.M_price && props?.errors?.M_price}
                                        />
                                        <CFormLabel>Quantity</CFormLabel>
                                        {/* <span className='text-danger' style={{ marginLeft: '5px' }}>*</span> */}
                                        <DefaultInput
                                          type={'text'}
                                          placeholder={'Medium Package Quantity'}
                                          value={props?.values?.M_quantity}
                                          onChange={props?.handleChange('M_quantity')}
                                          onBlur={() => props?.setFieldTouched('M_quantity', true)}
                                          error={
                                            props?.touched?.M_quantity && props?.errors?.M_quantity
                                          }
                                        />

                                        <CFormLabel>Image</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                <Info message="110x120"/>

                                        <DefaultInput
                                          type={'file'}
                                          placeholder={'Image'}
                                          accept="image/*"
                                          refr={fileRefMedium}
                                          onChange={(e) => {
                                            const imageFile = e?.target?.files[0]
                                            if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                              setMediumInvalidImage('Please select valid image')
                                            } else {
                                              setMediumInvalidImage('')
                                            }
                                            props?.setFieldValue('medium_pack_image', e?.target?.files[0])
                                          }}
                                          onBlur={() => props?.setFieldTouched('medium_pack_image', true)}
                                          error={
                                            (props?.touched?.medium_pack_image && props?.errors?.medium_pack_image) ||
                                            mediumInvalidImage
                                          }
                                        />


                                        {/* <CFormLabel>Unit</CFormLabel>
                                        <span className='text-danger' style={{marginLeft:'5px'}}>*</span>
                                        <Select
                                        defaultValue={selectedMUnitOption}
                                        onChange={(val)=>{setSelectedMUnitOption(val)
                                            props?.setFieldValue("M_unit",val.value)}}
                                        options={MunitOption}
                                        placeholder={"Select unit"}
                                        value= {selectedMUnitOption}
                                        />
                                        {selectedMUnitOption == undefined ? <span className='text-danger'>{props?.errors?.M_unit}</span> : '' }<br/>  */}
                                      </CCol>
                                      <CCol md="6">
                                        <CFormLabel>Title (TH)</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <DefaultInput
                                          type={'text'}
                                          placeholder={'Title (TH)'}
                                          value={props?.values?.medium_pack_title_TH}
                                          onChange={props?.handleChange('medium_pack_title_TH')}
                                          onBlur={() =>
                                            props?.setFieldTouched('medium_pack_title_TH', true)
                                          }
                                          error={
                                            props?.touched?.medium_pack_title_TH &&
                                            props?.errors?.medium_pack_title_TH
                                          }
                                        />

                                        <FormLabel>Medium Pack Description (TH)</FormLabel>
                                        {/* <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span> */}


                                        <Ckeditor
                                          setMedium_pack_description_TH={
                                            setMedium_pack_description_TH
                                          }
                                          medium_pack_description_TH={medium_pack_description_TH}
                                          value={props?.values?.medium_pack_description_TH}
                                          onChange={(val) => {
                                            setMedium_pack_description_TH(val)
                                            props?.setFieldValue('medium_pack_description_TH', val)
                                          }}
                                          onBlur={() =>
                                            props?.setFieldTouched(
                                              'medium_pack_description_TH',
                                              true,
                                            )
                                          }
                                          error={
                                            props?.touched?.medium_pack_description_TH &&
                                            props?.errors?.medium_pack_description_TH
                                          }
                                        />
                                        <CFormLabel>Free Pickup And Delivery</CFormLabel>
                                        {/* <span className='text-danger' style={{ marginLeft: '5px' }}>*</span> */}
                                        <DefaultInput
                                          type={'text'}
                                          placeholder={'Medium Package Delivery'}
                                          value={props?.values?.M_delivery}
                                          onChange={props?.handleChange('M_delivery')}
                                          onBlur={() => props?.setFieldTouched('M_delivery', true)}
                                          error={
                                            props?.touched?.M_delivery && props?.errors?.M_delivery
                                          }
                                        />
                                        <CFormLabel>Status</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <Select
                                          onChange={(val) => {
                                            setSelectStatus2(val)
                                            props?.setFieldValue('status', val)
                                          }}
                                          options={[
                                            { label: 'Active', value: 'active' },
                                            { label: 'Inactive', value: 'inactive' },
                                          ]}
                                          value={selectStatus2}
                                          placeholder={'Select status'}
                                          name="Status"
                                        />

                                        <CFormLabel>Services (Medium)</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>

                                        <Select
                                          isMulti={true}
                                          // defaultValue={selectService}
                                          value={selectedValueM}
                                          onChange={(e) => {
                                            handleChangeValueM(e)
                                          }}
                                          options={serviceData?.map((item) => {
                                            return {
                                              value: item?._id,
                                              label: item?.serviceName_EN,
                                              quota: item?.serviceQuota,
                                            }
                                          })}
                                          placeholder={'Select services'}
                                          name="Services"
                                        />
                                        {selectedValueM.length == 0 ? (
                                          <span className="text-danger">Service is required</span>
                                        ) : (
                                          ''
                                        )}
                                        {remaningServiceQuotaM?.length > 0
                                          ? Object.values(remaningServiceQuotaM).map(
                                            (item, index) => {
                                              return (
                                                <CCol style={{ marginBottom: '5px' }}>
                                                  <CFormLabel>
                                                    {item?.label} (Remain Quota)
                                                  </CFormLabel>
                                                  <span
                                                    className="text-danger"
                                                    style={{ marginLeft: '5px' }}
                                                  >
                                                    *
                                                  </span>
                                                  <DefaultInput
                                                    type="number"
                                                    // disabled={true}
                                                    placeholder={'Remain Service Quota'}
                                                    value={remaningServiceQuotaM[index].quota}
                                                    // onChange={(e) => {
                                                    //   const itemIndex =
                                                    //     remaningServiceQuotaM.findIndex(
                                                    //       (data) => data.quota === item.quota,
                                                    //     )
                                                    //   if (itemIndex === -1) {
                                                    //     console.error('Item not found')
                                                    //     return
                                                    //   }
                                                    //   // Create a new array with the updated item
                                                    //   const updatedItems = [
                                                    //     ...remaningServiceQuotaM.slice(
                                                    //       0,
                                                    //       itemIndex,
                                                    //     ), // all items before the updated item
                                                    //     {
                                                    //       ...remaningServiceQuotaM[itemIndex],
                                                    //       quota: e.target.value,
                                                    //     }, // updated item
                                                    //     ...remaningServiceQuotaM.slice(
                                                    //       itemIndex + 1,
                                                    //     ), // all items after the updated item
                                                    //   ]
                                                    //   console.log(updatedItems, 'updatedItems')
                                                    //   // Update the state with the new array
                                                    //   setRemainingServiceQuotaM(updatedItems)
                                                    // }}

                                                    onChange={(e) => handleSelectChangeM(e, item)}
                                                  // onChange={(e)=>setRemainingServiceQuota(remaningServiceQuota[index].quota=e.target.value)}
                                                  // onChange={props?.handleChange(
                                                  //   'servicesQuota',
                                                  // )}
                                                  />
                                                </CCol>
                                              )
                                            },
                                          )
                                          : ''}
                                      </CCol>
                                    </CRow>
                                  </CTabPane>
                                  <CTabPane
                                    className="p-3 preview"
                                    role="tabpanel"
                                    aria-labelledby="contact-tab"
                                    visible={activeKey === 3}
                                  >
                                    <CRow xs={12}>
                                      <CCol md={6}>
                                        <input
                                          type="hidden"
                                          name="L_package_size"
                                          value={largePackageSize}
                                        />

                                        <CFormLabel>Title (EN)</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <DefaultInput
                                          type={'text'}
                                          placeholder={'Title (EN)'}
                                          value={props?.values?.large_pack_title_EN}
                                          onChange={props?.handleChange('large_pack_title_EN')}
                                          onBlur={() =>
                                            props?.setFieldTouched('large_pack_title_EN', true)
                                          }
                                          error={
                                            props?.touched?.large_pack_title_EN &&
                                            props?.errors?.large_pack_title_EN
                                          }
                                        />

                                        <FormLabel>Large Pack Description (EN)</FormLabel>
                                        {/* <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span> */}

                                        <Ckeditor
                                          setLarge_pack_description_EN={
                                            setLarge_pack_description_EN
                                          }
                                          large_pack_description_EN={large_pack_description_EN}
                                          value={props?.values?.large_pack_description_EN}
                                          onChange={(val) => {
                                            setLarge_pack_description_EN(val)
                                            props?.setFieldValue('large_pack_description_EN', val)
                                          }}
                                          onBlur={() =>
                                            props?.setFieldTouched(
                                              'large_pack_description_EN',
                                              true,
                                            )
                                          }
                                          error={
                                            props?.touched?.large_pack_description_EN &&
                                            props?.errors?.large_pack_description_EN
                                          }
                                        />
                                        <CFormLabel>Package Price</CFormLabel>
                                        {/* <span className='text-danger' style={{ marginLeft: '5px' }}>*</span> */}
                                        <DefaultInput
                                          type={'text'}
                                          placeholder={'Large Package Price'}
                                          value={props?.values?.L_price}
                                          onChange={props?.handleChange('L_price')}
                                          onBlur={() => props?.setFieldTouched('L_price', true)}
                                          error={props?.touched?.L_price && props?.errors?.L_price}
                                        />
                                        <CFormLabel>Quantity</CFormLabel>
                                        {/* <span className='text-danger' style={{ marginLeft: '5px' }}>*</span> */}
                                        <DefaultInput
                                          type={'text'}
                                          placeholder={'Large Package Quantity'}
                                          value={props?.values?.L_quantity}
                                          onChange={props?.handleChange('L_quantity')}
                                          onBlur={() => props?.setFieldTouched('L_quantity', true)}
                                          error={
                                            props?.touched?.L_quantity && props?.errors?.L_quantity
                                          }
                                        />

                                        <CFormLabel>Image</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                <Info message="110x120"/>

                                        <DefaultInput
                                          type={'file'}
                                          placeholder={'Image'}
                                          accept="image/*"
                                          refr={fileRefLarge}
                                          onChange={(e) => {
                                            const imageFile = e?.target?.files[0]
                                            if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                              setLargeInvalidImage('Please select valid image')
                                            } else {
                                              setLargeInvalidImage('')
                                            }
                                            props?.setFieldValue('large_pack_image', e?.target?.files[0])
                                          }}
                                          onBlur={() => props?.setFieldTouched('large_pack_image', true)}
                                          error={
                                            (props?.touched?.large_pack_image && props?.errors?.large_pack_image) ||
                                            largeInvalidImage
                                          }
                                        />


                                        {/* <CFormLabel>Unit</CFormLabel>
                                        <span className='text-danger' style={{marginLeft:'5px'}}>*</span>
                                        <Select
                                        defaultValue={selectedLUnitOption}
                                        onChange={(val)=>{setSelectedLUnitOption(val)
                                            props?.setFieldValue("L_unit",val.value)}}
                                        options={LunitOption}
                                        placeholder={"Select unit"}
                                        value= {selectedLUnitOption}
                                        />
                                        {selectedLUnitOption == undefined ? <span className='text-danger'>{props?.errors?.L_unit}</span> : '' }<br/>  */}
                                      </CCol>
                                      <CCol md="6">
                                        <CFormLabel>Title (TH)</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <DefaultInput
                                          type={'text'}
                                          placeholder={'Title (TH)'}
                                          value={props?.values?.large_pack_title_TH}
                                          onChange={props?.handleChange('large_pack_title_TH')}
                                          onBlur={() =>
                                            props?.setFieldTouched('large_pack_title_TH', true)
                                          }
                                          error={
                                            props?.touched?.large_pack_title_TH &&
                                            props?.errors?.large_pack_title_TH
                                          }
                                        />

                                        <FormLabel>Large Pack Description (TH)</FormLabel>
                                        {/* <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span> */}

                                        <Ckeditor
                                          setLarge_pack_description_TH={
                                            setLarge_pack_description_TH
                                          }
                                          large_pack_description_TH={large_pack_description_TH}
                                          value={props?.values?.large_pack_description_TH}
                                          onChange={(val) => {
                                            setLarge_pack_description_TH(val)
                                            props?.setFieldValue('large_pack_description_TH', val)
                                          }}
                                          onBlur={() =>
                                            props?.setFieldTouched(
                                              'large_pack_description_TH',
                                              true,
                                            )
                                          }
                                          error={
                                            props?.touched?.large_pack_description_TH &&
                                            props?.errors?.large_pack_description_TH
                                          }
                                        />
                                        <CFormLabel>Free Pickup And Delivery</CFormLabel>
                                        {/* <span className='text-danger' style={{ marginLeft: '5px' }}>*</span> */}
                                        <DefaultInput
                                          type={'text'}
                                          placeholder={'Large Package Delivery'}
                                          value={props?.values?.L_delivery}
                                          onChange={props?.handleChange('L_delivery')}
                                          onBlur={() => props?.setFieldTouched('L_delivery', true)}
                                          error={
                                            props?.touched?.L_delivery && props?.errors?.L_delivery
                                          }
                                        />
                                        <CFormLabel>Status</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>
                                        <Select
                                          onChange={(val) => {
                                            setSelectStatus3(val)
                                            props?.setFieldValue('status', val)
                                          }}
                                          options={[
                                            { label: 'Active', value: 'active' },
                                            { label: 'Inactive', value: 'inactive' },
                                          ]}
                                          value={selectStatus3}
                                          placeholder={'Select status'}
                                          name="Status"
                                        />

                                        <CFormLabel>Services (Large)</CFormLabel>
                                        <span className="text-danger" style={{ marginLeft: '5px' }}>
                                          *
                                        </span>

                                        <Select
                                          isMulti={true}
                                          // defaultValue={selectService}
                                          value={selectedValueL}
                                          onChange={(e) => {
                                            handleChangeValueL(e)
                                          }}
                                          options={serviceData?.map((item) => {
                                            return {
                                              value: item?._id,
                                              label: item?.serviceName_EN,
                                              // quota: item?.quota,
                                              quota: item?.serviceQuota,
                                            }
                                          })}
                                          placeholder={'Select services'}
                                          name="Services"
                                        />

                                        {selectedValueL.length == 0 ? (
                                          <span className="text-danger">Service is required</span>
                                        ) : (
                                          ''
                                        )}
                                        {remaningServiceQuotaL?.length > 0
                                          ? Object.values(remaningServiceQuotaL).map(
                                            (item, index) => {
                                              return (
                                                <CCol style={{ marginBottom: '5px' }}>
                                                  <CFormLabel>
                                                    {item?.label} (Remain Quota)
                                                  </CFormLabel>
                                                  <span
                                                    className="text-danger"
                                                    style={{ marginLeft: '5px' }}
                                                  >
                                                    *
                                                  </span>
                                                  <DefaultInput
                                                    type="number"
                                                    // disabled={true}
                                                    placeholder={'Remain Service Quota'}
                                                    value={remaningServiceQuotaL[index].quota}
                                                    // onChange={(e) => {
                                                    //   const itemIndex =
                                                    //     remaningServiceQuotaL.findIndex(
                                                    //       (data) => data.quota === item.quota,
                                                    //     )
                                                    //   if (itemIndex === -1) {
                                                    //     console.error('Item not found')
                                                    //     return
                                                    //   }
                                                    //   // Create a new array with the updated item
                                                    //   const updatedItems = [
                                                    //     ...remaningServiceQuotaL.slice(
                                                    //       0,
                                                    //       itemIndex,
                                                    //     ), // all items before the updated item
                                                    //     {
                                                    //       ...remaningServiceQuotaL[itemIndex],
                                                    //       quota: e.target.value,
                                                    //     }, // updated item
                                                    //     ...remaningServiceQuotaL.slice(
                                                    //       itemIndex + 1,
                                                    //     ), // all items after the updated item
                                                    //   ]
                                                    //   // Update the state with the new array
                                                    //   setRemainingServiceQuotaL(updatedItems)
                                                    // }}
                                                    onChange={(e) => handleSelectChangeL(e, item)}

                                                  // onChange={(e)=>setRemainingServiceQuota(remaningServiceQuota[index].quota=e.target.value)}
                                                  // onChange={props?.handleChange(
                                                  //   'servicesQuota',
                                                  // )}
                                                  />
                                                </CCol>
                                              )
                                            },
                                          )
                                          : ''}
                                      </CCol>
                                    </CRow>
                                  </CTabPane>
                                </CTabContent>
                              </CCol>
                              {/* Process Service */}

                            </CRow>

                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"
                                  className={'btn btn-success mt-2'}
                                // disabled={props?.isSubmitting}
                                >
                                  Submit
                                </button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={() => onClickReset(props?.isSubmitting)}
                                >
                                  Reset
                                </button>
                              </CCol>
                              <CCol md="3"></CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer >
    </>
  )
}

export default add
