import { CCard, CCardBody, CCardGroup, CCardHeader, CCol, CContainer, CHeader, CRow } from '@coreui/react'
import { Divider } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate, useParams, } from 'react-router-dom'
import { get, patch } from 'src/utils/apiManager'
import Barcode from 'src/assets/images/barcode-scan.png'
import { ToastContainer, toast } from 'react-toastify'
import { ImageUrl } from 'src/config/config'
import ImagePreview from './previewImage'
import ItemImagePreview from './itemImagePreview'

// import 'react-toastify/dist/ReactToastify.css';

const BagDetails = () => {
  const { bagId, orderId } = useParams()
  let { search } = useLocation()

  const [activeItems, setActiveItems] = useState([])
  const [items, setItems] = useState([])
  const [amount, setAmount] = useState()
  const [disableInput, setdisableInput] = useState(false)
  console.log("🚀 ~ BagDetails ~ amount:", amount)
  // const [selectedItem, setSelectedItem] = useState([])
  const [barcodeValue, setBarcodeValue] = useState('')
  const [itemDetail, setItemDetail] = useState()
  const ref = useRef(null)
  const inputRef = useRef()
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false)
  const [visiblePreview, setVisiblePreview] = useState(false)
  const [unit, setUnit] = useState(false)
  const [unitValue, setUnitValue] = useState(false)
  const [itemLength, setItemLength] = useState(false)

  useEffect(() => {
    if (barcodeValue) {
      console.log({ barcodeValue })
    }
  }, [barcodeValue, setBarcodeValue])

  useEffect(() => {
    if (bagId && amount) {
      get(`/admin/offline-order/get-bag/${bagId}`).then((data) => {
        setItems(data.data)
        console.log("cvcvx", data.data.length, amount)
        if (data && (data.data.length > Number(amount))) {

          setdisableInput(true)
          toast.error('Please enter amount lesser than items')
        } else {
          setdisableInput(false)
        }
      })
    }
  }, [bagId, amount])

  // useEffect(() => {
  //   document.addEventListener('click', handleClickOutside, true)
  //   return () => {
  //     document.removeEventListener('click', handleClickOutside, true)
  //   }
  // }, [])

  // const handleClickOutside = (event) => {
  //   // if (ref.current && !ref.current.contains(event.target)) {
  //   inputRef.current.focus()
  //   // }
  // }

  const handlePreviewClick = (index) => {
    setVisiblePreview(visiblePreview === index ? null : index);
  };


  useEffect(() => {
    if (search != "") {
      setAmount(search?.split("?amount=")?.[1])
    }
  }, [])

  // const handleOrder = () => {
  //   put('/admin/offline-order/bag-list/${orderId}')
  // }



  const handleKeyPress = async (event) => {
    // if(event.charCode )
    if (event.charCode === 13) {
      const { data } = await get(`admin/offline-order/get-items/${bagId}/${barcodeValue}`)
      if (data == 'not redirect') {
        toast.error('This barcode already exists')
        return
      }

      if (event.charCode === 13 && barcodeValue.length > 0) {
        navigate(`/admin/offline-order/bag/add-item/${bagId}/${barcodeValue}`)
      }
    }
  }

  useEffect(() => {
    get(`/admin/offline-order/get-order-bag/${bagId}`).then((data) => {
      if (data.status) {

        get(`/admin/unit/get-unit/${data.data[0]?.service?.unit}`).then((res) => {
          setUnit(res?.data?.title_EN)
        })
        setUnitValue(data?.data[0]?.unitValue)
        setItemLength(data.data[0].item.length)
      }
    })
  }, [bagId])


  return (
    <CContainer>
      <div
        style={{
          display: 'flex',
        }}
      >
        <div style={{ flex: 0.3, justifyContent: 'center', alignItems: 'center' }}>
          <CCard>
            <CCardGroup>
              <CCardBody>
                <ul style={{ listStyle: 'none', padding: '0px', height: '68vh', overflow: 'auto' }}>
                  {items &&
                    items.length > 0 &&
                    items.map((_item, index) => (
                      <li
                        style={{
                          // backgroundColor: 'grey',
                          textTransform: 'uppercase',
                          padding: '10px 30px',
                          borderRadius: '10px',
                          margin: '5px 0',
                          cursor: "pointer"
                        }}
                        onClick={() => setItemDetail(_item)}
                      >
                        item{index + 1}
                      </li>
                    ))}
                  {items && items.length === 0 && <h2>No item exists </h2>}
                </ul>
              </CCardBody>
            </CCardGroup>
          </CCard>
        </div>
        {!(itemDetail && Object.keys(itemDetail)?.length > 0) ? <div style={{ flex: 0.7 }}>
          <CCard>
            <CCardGroup>
              <CCardBody>
                <Link
                  className={'btn btn-success'}
                  style={{ background: '#d3dc40', border: '#d3dc40', float: 'right' }}
                  to={
                    items?.[0]?.item?.length
                      ? {
                        pathname: `/admin/offline-order/bag/add-item-service/${bagId}/${orderId}`,
                      }
                      : { pathname: `/admin/offline-order/bags/${orderId}` }
                  }
                >
                  Back
                </Link>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '70vh',
                    // overflowY: 'scroll',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img src={Barcode} alt="barcode" style={{ maxWidth: '200px' }} />
                  {
                    unit === 'pcs' && Number(itemLength) >= Number(unitValue) ? null : (
                      <>
                        <h4 className="mt-3">Scan OR code for item</h4>
                        <input
                          autoFocus={true}
                          disabled={disableInput}
                          // hidden
                          // style={{ border: 'none', outline: 'none', widht: '0', height: '0' }}
                          ref={inputRef}
                          name="barcodeValue"
                          id="barcodeValue"
                          value={barcodeValue}
                          onChange={(event) => {
                            if (event.target.value.trim() !== "" && !/^[a-zA-Z0-9\s]+$/.test(event.target.value)) {
                              console.log(event.keyCode);
                              toast.error("Invalid bar code Value");
                              return;
                            }
                            setBarcodeValue(event.target.value)
                          }}
                          onKeyPress={handleKeyPress}
                          
                        />
                      </>
                    )
                  }

                </div>
              </CCardBody>
            </CCardGroup>
          </CCard>
        </div>
          :
          <CCard style={{ flex: '0.6' }}>
            <CCardGroup>
              <CCardBody>

                <>
                  <CHeader className="mb-3">
                    {/* <CCol md="1">
                      <button
                        className="btn btn-success"
                        style={{
                          width: '100%',
                          background: '#d3dc40',
                          border: '#d3dc40',
                        }}
                        onClick={() => setItemDetail()}
                      >
                        Back
                      </button>
                    </CCol> */}
                    <h4 className="mb-3">Item summary page</h4>
                    <CRow>

                      <CCol md="12">
                        {/* <button
                          className="btn btn-success"
                          style={{
                            width: '100%',
                            background: '#d3dc40',
                            border: '#d3dc40',
                          }}
                        // onClick={viewOrder}
                        >
                          View Order Detail
                        </button> */}
                        <button
                          className="btn btn-success"
                          style={{
                            width: '100%',
                            background: '#d3dc40',
                            border: '#d3dc40',
                          }}
                          onClick={() => setItemDetail()}
                        >
                          Back
                        </button>
                      </CCol>
                    </CRow>
                  </CHeader>
                  <CRow className="justify-content-center mb-2 progress-list">
                    <CCol
                      xs={2}
                      className="align-items-center"
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <div style={{ position: 'relative' }}>
                        <div className="icon-box"
                        // onClick={() => handleEdit('itemType')}
                        >
                          <div>
                            <i class="fa-solid fa-shirt"></i>
                          </div>
                          <div>{itemDetail.items && <i class="fa-solid fa-check"></i>}</div>
                        </div>
                        <p style={{ textAlign: 'center', fontWeight: '600' }}>Type</p>
                      </div>
                      <div>
                        {/* <img src={`${ImageUrl2}/${itemDetail.items.image}`} /> */}
                        {itemDetail?.items && itemDetail?.items?.itemName_EN}
                      </div>
                    </CCol>
                    <CCol
                      xs={2}
                      className="align-items-center"
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <div style={{ position: 'relative' }}>
                        <div className="icon-box"
                        // onClick={() => handleEdit('brand')}
                        >
                          <div>
                            <i class="fa-solid fa-shirt"></i>
                          </div>
                          <div>{itemDetail?.item?.brand && <i class="fa-solid fa-check"></i>}</div>
                        </div>
                        <p style={{ textAlign: 'center', fontWeight: '600' }}>Brand</p>
                      </div>
                      <div>{itemDetail?.item?.brand && itemDetail?.item?.brand}</div>
                    </CCol>
                    <CCol
                      xs={2}
                      className="align-items-center"
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <div style={{ position: 'relative' }}>
                        <div className="icon-box"
                        // onClick={() => handleEdit('color')}
                        >
                          <div>
                            <i class="fa-solid fa-shirt"></i>
                          </div>
                          <div>
                            {itemDetail?.colors?.length > 0 && <i class="fa-solid fa-check"></i>}
                          </div>
                        </div>
                        <p style={{ textAlign: 'center', fontWeight: '600' }}>Colors</p>
                      </div>
                      <CCol cs={6}>
                        {itemDetail?.colors?.length > 0 &&
                          itemDetail?.colors?.map((_color) => {
                            return (
                              <div
                                style={{
                                  height: '50px',
                                  width: '50px',
                                  borderRadius: '50%',
                                  // border: '1px solid black',
                                  backgroundColor: _color?.colorCode,
                                  cursor: 'pointer',
                                  boxShadow: '0 1px 3px rgb(0 0 0 / 36%)',
                                  margin: '4px',
                                }}
                              ></div>
                            )
                          })}
                      </CCol>
                    </CCol>
                    <CCol
                      xs={2}
                      className="align-items-center"
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <div style={{ position: 'relative' }}>
                        <div className="icon-box"
                        // onClick={() => handleEdit('process')}
                        >
                          <div>
                            <i class="fa-solid fa-shirt"></i>
                          </div>
                          <div>
                            <i class="fa-solid fa-check"></i>
                          </div>
                        </div>
                        <p style={{ textAlign: 'center', fontWeight: '600' }}>Washing Programs</p>
                      </div>
                      <div>{itemDetail?.processes?.processName_EN}</div>
                    </CCol>
                    <CCol
                      xs={2}
                      className="align-items-center"
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <div style={{ position: 'relative' }}>
                        <div className="icon-box"
                        // onClick={() => handleEdit('itemImage')}
                        >
                          <div>
                            <i class="fa-solid fa-shirt"></i>
                          </div>
                          <div>
                            {/* {itemDetail?.item?.image[0]?.length > 0 && (
                            <i class="fa-solid fa-check"></i>
                          )} */}
                            {itemDetail?.item?.picture && (
                              <i class="fa-solid fa-check"></i>
                            )}
                          </div>
                        </div>
                        <p style={{ textAlign: 'center', fontWeight: '600' }}>Image</p>
                      </div>
                      {itemDetail?.item?.picture && (
                        <>
                          <img
                            src={ImageUrl + itemDetail.item.picture}
                            style={{ cursor: 'pointer', width: '50%', height: '100px' }}
                            onClick={() => setVisible(!visible)}
                          // data-coreui-toggle="modal"
                          // data-coreui-target="#staticBackdrop"
                          />
                          <ImagePreview
                            visible={visible}
                            setVisible={setVisible}
                            image={ImageUrl + itemDetail.item.picture}
                          />
                        </>
                      )}
                    </CCol>
                    <CCol
                      xs={2}
                      className="align-items-center"
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <div>
                        {Array.isArray(itemDetail.item.stain) && itemDetail.item.stain.length > 0 && <div style={{ position: 'relative' }}>
                          <div className="icon-box"
                          // onClick={() => handleEdit('stain')}
                          >
                            <div>
                              <i class="fa-solid fa-shirt"></i>
                            </div>
                            <div> <i class="fa-solid fa-check"></i> </div>
                          </div>
                          <p style={{ textAlign: 'center', fontWeight: '600' }}>Stain</p>
                        </div>}
                        <div>
                          {Array.isArray(itemDetail.item.stain) && itemDetail.item.stain.map((data, i) => {
                            return (
                              <div key={i}>
                                <div>
                                  {data?.stainName}
                                  <p onClick={() => handlePreviewClick(i)}>
                                    {data.images && data.images.length > 0 ? (
                                      <i className="fa-solid fa-images"></i>
                                    ) : (
                                      ''
                                    )}
                                  </p>
                                </div>
                                {visiblePreview === i && (
                                  <ItemImagePreview
                                    visiblePreview={visiblePreview === i}
                                    setVisiblePreview={() => handlePreviewClick(i)}
                                    image={data.images}
                                    safeMessage={data?.safeMessage}
                                  />
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {/* <div>{itemDetail.item.stain}</div> */}

                    </CCol>
                  </CRow>
                  {itemDetail?.item?.description && <CRow>
                    <h5>Instructions:</h5>
                    <div>{itemDetail?.item?.description}</div>
                  </CRow>}
                  {/* <CRow className="justify-content-center mt-2">
                {itemDetail.item.image &&
                  itemDetail.item.image.length > 0 &&
                  itemDetail.item.image.map((_img, index) => (
                    <CCol xs={2}>
                      <div
                        style={{
                          border: '1px solid black',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          boxShadow: '1px 1px 3px black',
                          border: 'none',
                        }}
                      >
                        <img src={ImageUrl2 + _img} style={{ width: '100%', height: '150px' }} />
                      </div>
                    </CCol>
                  ))}
              </CRow> */}
                </>

                {/* {itemDetail &&
              Object.keys(itemDetail)?.length > 0 &&
              // itemDetail.item.status === 'inactive' && (
              ( <CRow className="justify-content-between p-3">
                  <CCol xs={3} className="justify-content-end">
                 { itemDetail?.item?.draftItem ? <button
                      style={{
                        marginTop: '20px',
                        width: '100%',
                        background: '#d3dc40',
                        border: '#d3dc40',
                        padding: '10px 0',
                      }}
                      onClick={() => handleItem('remove')}
                    >
                      Remove from draft
                    </button> : <button
                      style={{
                        marginTop: '20px',
                        width: '100%',
                        background: '#d3dc40',
                        border: '#d3dc40',
                        padding: '10px 0',
                      }}
                      onClick={() => handleItem('remove')}
                    >
                      Draft Item
                    </button> }
                  </CCol>
                  <CCol xs={2} className="justify-content-end">
                    <button
                      style={{
                        marginTop: '20px',
                        width: '100%',
                        background: '#d3dc40',
                        border: '#d3dc40',
                        padding: '10px 0',
                      }}
                      onClick={() =>{ handleItem('add'), changeStatusButton()}}
                    >
                       Confirm
                    </button>
                  </CCol>
                </CRow>
              )} */}
              </CCardBody>
            </CCardGroup>
          </CCard>

        }
      </div>
      {/* <ToastContainer /> */}

    </CContainer>
  )
}

export default BagDetails
