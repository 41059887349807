import React, { useState, useRef, useEffect } from 'react'
import { Formik } from 'formik'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { put } from 'src/utils/apiManager'
import { ImageUrl } from 'src/config/config'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormLabel,
  CRow,
  CImage,
} from '@coreui/react'
import { DefaultInput, DefaultTextarea } from 'src/components/common/input'
import { validateBlogEdit } from 'src/utils/validator'
import { Ckeditor } from '../Template/Ckeditor'
import Info from '../info/info'

function EditBlog() {
  const navigate = useNavigate()
  const { state } = useLocation()
  const formRef = useRef(null)
  const fileRef = useRef(null)
  const [description_EN, setDescription_EN] = useState([])
  const [description_TH, setDescription_TH] = useState([])
  const [metaDescription_EN, setMetaDescription_EN] = useState([])
  const [metaDescription_TH, setMetaDescription_TH] = useState([])
  const [isDisable, setIsDisable] = useState(false);
  const [invalidImage, setInvalidImage] = useState('')
  const [imageUrl, setimageUrl] = useState()
  const [blogImagePreview, setBlogImagePreview] = useState();
  const submit = async (values) => {
    if (invalidImage?.length === 0) {
      let blogData = {
        id: state?._id,
        title_EN: values?.title_EN,
        title_TH: values?.title_TH,
        image: values?.image,
        metaTitle_EN: values?.metaTitle_EN,
        metaTitle_TH: values?.metaTitle_TH,
        metaKey_EN: values?.metaKey_EN,
        metaKey_TH: values?.metaKey_TH,
        description_EN: values?.description_EN,
        description_TH: values?.description_TH,
        metaDescription_EN: values?.metaDescription_EN,
        metaDescription_TH: values?.metaDescription_TH,
        imgtext_EN: values?.imgtext_EN,
        imgtext_TH: values?.imgtext_TH,
        blogWriter_EN: values?.blogWriter_EN,
        blogWriter_TH: values?.blogWriter_TH,
      }
      put(`admin/Blogs/edit-blog`, blogData, 1).then((data) => {
        handleResponse(data)
      })
    } else {
      return
    }
  }
  const handleResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setTimeout(() => {
        navigate('/admin/blog')
      }, 800)
    }
    formRef.current.resetForm()
    fileRef.current.value = null
  }
  useEffect(() => {
    if (state != null) {
      formRef.current.setFieldValue('title_EN', state?.title_EN)
      formRef.current.setFieldValue('title_TH', state?.title_TH)
      formRef.current.setFieldValue('metaTitle_EN', state?.metaTitle_EN)
      formRef.current.setFieldValue('metaTitle_TH', state?.metaTitle_TH)
      formRef.current.setFieldValue('metaKey_EN', state?.metaKey_EN)
      formRef.current.setFieldValue('metaKey_TH', state?.metaKey_TH)
      formRef.current.setFieldValue('metaDescription_EN', state?.metaDescription_EN)
      formRef.current.setFieldValue('metaDescription_TH', state?.metaDescription_TH)
      formRef.current.setFieldValue('description_EN', state?.description_EN)
      formRef.current.setFieldValue('description_TH', state?.description_TH)
      formRef.current.setFieldValue('imgtext_EN', state?.imgtext_EN)
      formRef.current.setFieldValue('imgtext_TH', state?.imgtext_TH)
      formRef.current.setFieldValue('blogWriter_EN', state?.blogWriter_EN)
      formRef.current.setFieldValue('blogWriter_TH', state?.blogWriter_TH)
      if (state?.image) setimageUrl(`${ImageUrl}${state?.image}`)
    }
  }, [state])
  return (
    <>
      <CContainer >
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CCol xs={12}>
                  <CCardGroup>
                    <CCardBody>
                      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
                      <CRow>
                        <CCol md="11">
                          <h1>Edit Blog</h1>
                        </CCol>
                        <CCol md="1">
                          <Link className={'btn btn-success mt-2'} style={{ background: '#d3dc40', border: '#d3dc40' }} to={{ pathname: `/admin/blog` }}>
                            Back
                          </Link>
                        </CCol>
                      </CRow>
                      <Formik
                        initialValues={{
                          title_EN: '',
                          title_TH: '',
                          image: '',
                          description_EN: '',
                          description_TH: '',
                          metaTitle_EN: '',
                          metaTitle_TH: '',
                          metaKey_EN: '',
                          metaKey_TH: '',
                          metaDescription_EN: '',
                          metaDescription_TH: '',
                          imgtext_EN: '',
                          imgtext_TH: '',
                          blogWriter_EN: '',
                          blogWriter_TH: '',
                        }}
                        validationSchema={validateBlogEdit}
                        onSubmit={(values, { setSubmitting }) => {
                          submit(values)
                          setSubmitting(false)
                        }}
                        innerRef={formRef}
                      >
                        {(props) => (
                          <form onSubmit={props?.handleSubmit}>
                            <CRow xs={12}>
                              <CCol md={6}>

                              <CFormLabel>Title (EN)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Title EN'}
                                  id={'title_EN'}
                                  value={props?.values?.title_EN}
                                  onChange={props?.handleChange('title_EN')}
                                  onBlur={() => props?.setFieldTouched('title_EN', true)}
                                  error={props?.touched?.title_EN && props?.errors?.title_EN}
                                  onKeyUp={() => {
                                    if (state?.title_EN == props?.values?.title_EN) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                                <CFormLabel>Meta Title (EN)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Meta Title EN'}
                                  id={'metaTitle_EN'}
                                  value={props?.values?.metaTitle_EN}
                                  onChange={props?.handleChange('metaTitle_EN')}
                                  onBlur={() => props?.setFieldTouched('metaTitle_EN', true)}
                                  error={props?.touched?.metaTitle_EN && props?.errors?.metaTitle_EN}
                                  onKeyUp={() => {
                                    if (state?.metaTitle_EN == props?.values?.metaTitle_EN) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                                <CFormLabel>Meta Key (EN)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Meta Key EN'}
                                  id={'metaKey_EN'}
                                  value={props?.values?.metaKey_EN}
                                  onChange={props?.handleChange('metaKey_EN')}
                                  onBlur={() => props?.setFieldTouched('metaKey_EN', true)}
                                  error={props?.touched?.metaKey_EN && props?.errors?.metaKey_EN}
                                  onKeyUp={() => {
                                    if (state?.metaKey_EN == props?.values?.metaKey_EN) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                                <DefaultTextarea
                                  placeholder={"Some Text EN"}
                                  name={"Image Text (EN)"}
                                  id={"imgtext_EN"}
                                  maxLength={50}
                                  value={props?.values?.imgtext_EN}
                                  onChange={props?.handleChange("imgtext_EN")}
                                  onBlur={() =>
                                    props?.setFieldTouched("imgtext_EN", true)
                                  }
                                  error={props?.touched?.imgtext_EN && props?.errors?.imgtext_EN}
                                  onKeyUp={() => {
                                    if (state?.imgtext_EN == props?.values?.imgtext_EN) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                                <CFormLabel>Blog writer name (EN)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Blog writer name EN'}
                                  id={'blogWriter_EN'}
                                  value={props?.values?.blogWriter_EN}
                                  onChange={props?.handleChange('blogWriter_EN')}
                                  onBlur={() => props?.setFieldTouched('blogWriter_EN', true)}
                                  error={props?.touched?.blogWriter_EN && props?.errors?.blogWriter_EN}
                                  onKeyUp={() => {
                                    if (state?.blogWriter_EN == props?.values?.blogWriter_EN) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                                <CFormLabel> Description (EN)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <Ckeditor
                                  // setDescription_EN={setDescription_EN}
                                  // description_EN={description_EN}
                                  value={props?.values?.description_EN}
                                  onChange={(val) => {
                                    setDescription_EN(val)
                                    if (description_EN.length <= 0) setIsDisable(false)
                                    else setIsDisable(true)
                                    props?.setFieldValue('description_EN', val)
                                  }}
                                  onBlur={() => props?.setFieldTouched('description_EN', true)}
                                  error={props?.touched?.description_EN && props?.errors?.description_EN}
                                />
                                <CFormLabel>Meta Description (EN)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <Ckeditor
                                  // setMetaDescription_EN={setMetaDescription_EN}
                                  // metaDescription_EN={metaDescription_EN}
                                  value={props?.values?.metaDescription_EN}
                                  onChange={(val) => {
                                    setMetaDescription_EN(val)
                                    if (metaDescription_EN.length <= 0) setIsDisable(false)
                                    else setIsDisable(true)
                                    props?.setFieldValue('metaDescription_EN', val)
                                  }}
                                  onBlur={() => props?.setFieldTouched('metaDescription_EN', true)}
                                  error={props?.touched?.metaDescription_EN && props?.errors?.metaDescription_EN}
                                />
                                <CFormLabel>Image</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <Info message="100x120"/>

                                <DefaultInput
                                  type={'file'}
                                  placeholder={'Image'}
                                  accept="image/*"
                                  refr={fileRef}
                                  onChange={(e) => {
                                    const imageFile = e?.target?.files[0];
                                    // console.log(imageFile)
                                    if (imageUrl == imageFile?.name) setIsDisable(false)
                                    else setIsDisable(true)
                                    if (!imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
                                      setInvalidImage("Please select valid image")
                                    }
                                    else {
                                      setInvalidImage('')
                                    }
                                    //for image preview
                                    const reader = new FileReader();
                                    reader?.addEventListener("load", () => setBlogImagePreview(reader?.result));
                                    reader?.readAsDataURL(e?.target?.files[0]);
                                    //for image preview
                                    props?.setFieldValue('image', e?.target?.files[0])
                                  }}
                                  onBlur={() => props?.setFieldTouched('image', true)}
                                  error={(props?.touched?.image && props?.errors?.image) || invalidImage}
                                />
                                {/* for image preview */}
                                {blogImagePreview?.length > 0 ?
                                  <>
                                    <CCol>
                                      <CImage
                                        rounded
                                        thumbnail
                                        className="mt-2"
                                        src={`${blogImagePreview}`}
                                        style={{
                                          maxHeight: '80px',
                                          maxWidth: '80px',
                                          alignItems: 'center',
                                        }}
                                      />
                                    </CCol>
                                  </>
                                  : ''}
                                {/* for image preview */}
                              </CCol>
                              <CCol md={6}>
                              <CFormLabel>Title (TH)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Title TH'}
                                  id={'title_TH'}
                                  value={props?.values?.title_TH}
                                  onChange={props?.handleChange('title_TH')}
                                  onBlur={() => props?.setFieldTouched('title_TH', true)}
                                  error={props?.touched?.title_TH && props?.errors?.title_TH}
                                  onKeyUp={() => {
                                    if (state?.title_TH == props?.values?.title_TH) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                                <CFormLabel>Meta Title (TH)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Meta Title TH'}
                                  id={'metaTitle_TH'}
                                  value={props?.values?.metaTitle_TH}
                                  onChange={props?.handleChange('metaTitle_TH')}
                                  onBlur={() => props?.setFieldTouched('metaTitle_TH', true)}
                                  error={props?.touched?.metaTitle_TH && props?.errors?.metaTitle_TH}
                                  onKeyUp={() => {
                                    if (state?.metaTitle_TH == props?.values?.metaTitle_TH) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                                <CFormLabel>Meta Key (TH)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Meta Key TH'}
                                  id={'metaKey_TH'}
                                  value={props?.values?.metaKey_TH}
                                  onChange={props?.handleChange('metaKey_TH')}
                                  onBlur={() => props?.setFieldTouched('metaKey_TH', true)}
                                  error={props?.touched?.metaKey_TH && props?.errors?.metaKey_TH}
                                  onKeyUp={() => {
                                    if (state?.metaKey_TH == props?.values?.metaKey_TH) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                                <DefaultTextarea
                                  placeholder={"Some Text TH"}
                                  name={"Image Text (TH)"}
                                  id={"imgtext_TH"}
                                  maxLength={50}
                                  value={props?.values?.imgtext_TH}
                                  onChange={props?.handleChange("imgtext_TH")}
                                  onBlur={() =>
                                    props?.setFieldTouched("imgtext_TH", true)
                                  }
                                  error={props?.touched?.imgtext_TH && props?.errors?.imgtext_TH}
                                  onKeyUp={() => {
                                    if (state?.imgtext_TH == props?.values?.imgtext_TH) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                                <CFormLabel>Blog writer name (TH)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <DefaultInput
                                  type={'text'}
                                  placeholder={'Blog writer name TH'}
                                  id={'blogWriter_TH'}
                                  value={props?.values?.blogWriter_TH}
                                  onChange={props?.handleChange('blogWriter_TH')}
                                  onBlur={() => props?.setFieldTouched('blogWriter_TH', true)}
                                  error={props?.touched?.blogWriter_TH && props?.errors?.blogWriter_TH}
                                  onKeyUp={() => {
                                    if (state?.blogWriter_TH == props?.values?.blogWriter_TH) setIsDisable(false)
                                    else setIsDisable(true)
                                  }}
                                />
                                <CFormLabel> Description (TH)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <Ckeditor
                                  // setDescription_TH={setDescription_TH}
                                  // description_TH={description_TH}
                                  value={props?.values?.description_TH}
                                  onChange={(val) => {
                                    setDescription_TH(val)
                                    if (description_TH.length <= 0) setIsDisable(false)
                                    else setIsDisable(true)
                                    props?.setFieldValue('description_TH', val)
                                  }}
                                  onBlur={() => props?.setFieldTouched('description_TH', true)}
                                  error={props?.touched?.description_TH && props?.errors?.description_TH}
                                />
                                <CFormLabel>Meta Description (TH)</CFormLabel>
                                <span className='text-danger' style={{ marginLeft: '5px' }}>*</span>
                                <Ckeditor
                                  // setMetaDescription_TH={setMetaDescription_TH}
                                  // metaDescription_TH={metaDescription_TH}
                                  value={props?.values?.metaDescription_TH}
                                  onChange={(val) => {
                                    setMetaDescription_TH(val)
                                    if (metaDescription_TH.length <= 0) setIsDisable(false)
                                    else setIsDisable(true)
                                    props?.setFieldValue('metaDescription_TH', val)
                                  }}
                                  onBlur={() => props?.setFieldTouched('metaDescription_TH', true)}
                                  error={props?.touched?.metaDescription_TH && props?.errors?.metaDescription_TH}
                                />
                                {imageUrl && (
                                  <CImage
                                    rounded
                                    thumbnail
                                    className='mt-2'
                                    src={`${imageUrl}`}
                                    style={{
                                      maxHeight: "80px",
                                      maxWidth: "80px",
                                      alignItems: "center",
                                    }}
                                  />
                                )}
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol md="2">
                                <button
                                  type="submit"
                                  className={'btn btn-success mt-2'}
                                  disabled={!isDisable || props?.isSubmitting}
                                >
                                  Submit
                                </button>
                              </CCol>
                              <CCol md="2">
                                <button
                                  type="button"
                                  className={'btn btn-warning mt-2'}
                                  onClick={() => navigate('/admin/blog')}
                                >
                                  Cancel
                                </button>
                              </CCol>
                              <CCol md="3"></CCol>
                            </CRow>
                          </form>
                        )}
                      </Formik>
                    </CCardBody>
                  </CCardGroup>
                </CCol>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>


    </>
  )
}

export default EditBlog
